import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Title extends Component {
  getMinsAgo = timestamp => {
    let secsAgo = Math.floor((new Date().getTime() - Date.parse(timestamp)) / 1000);
    if (secsAgo < 0) {
      secsAgo = 0;
    }
    let minsAgo = Math.floor(secsAgo/60);
    const hrsAgo  = Math.floor(minsAgo/60);
    minsAgo = minsAgo % 60;
    return (hrsAgo>0 ? hrsAgo + " hr " : "") + (minsAgo + " min ago");
  };

  render() {
    const {lastCrawlTS, lastPackageTS, lastDownloadTS, woMemfis} = this.props;
    const advanced = document.location.href.indexOf("advanced=1") > 0;
    return (
      <StyledTitle>
        Asset Manager ({woMemfis ? `WO#: ${woMemfis}` :
        `LAST crawl: ${advanced ? (lastCrawlTS || 'null') : this.getMinsAgo(lastCrawlTS)},
        package: ${advanced ? (lastPackageTS || 'null') : this.getMinsAgo(lastPackageTS)},
        download: ${advanced ? (lastDownloadTS || 'null') : this.getMinsAgo(lastDownloadTS)}`})
      </StyledTitle>
    );
  }
}

const StyledTitle = styled.h2`
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  text-align: center;
`;

Title.propTypes = {
  lastCrawlTS: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastPackageTS: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastDownloadTS: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  woMemfis: PropTypes.string
};

export default Title;
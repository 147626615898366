import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {StatusColor} from '../../../../StorageManager/components/fs-actions/StatusCell';
import {Link} from 'react-router-dom';
import addToWoWhSymlinkImg from '../../../../../../assets/images/add_to_wo_wh_symlink.png';
import {FS_ACTION_TYPE__ADD_TO_MEMFIS_WO} from '../../../../StorageManager/constants';

const MAIN_TYPE__ADD_TO_MEMFIS_WO_WITHOUT_SYMLINK = 'ADD_TO_MEMFIS_WO_WITHOUT_SYMLINK';
const MAIN_TYPE__CREATE_WO_SYMLINKS = 'CREATE_WO_SYMLINKS';
const MAIN_TYPE__ADD_TO_MEMFIS_WO_REPLACEMENT = 'ADD_TO_MEMFIS_WO_REPLACEMENT';

const AddToWoIcon = styled.i`
  margin-top: 3px;
  margin-right: 5px;
  width: 14px;
  text-align: center;
  pointer-events: none;
`;

const CreateWoSymlinkIcon = styled(AddToWoIcon)`
  transform: rotate(-90deg);
`;

const AddToWoWhSymlinkIcon = styled.img`
  margin-left: 1px;
  margin-right: 6px;
  width: 12px;
  text-align: center;
`;

export const getActionTypeIcon = (mainType, actionType) => {
  const result = {
    mainType,
    actionType,
    iconPriority: 999,
    iconComponent: null
  };
  if (mainType === MAIN_TYPE__CREATE_WO_SYMLINKS) {
    result.iconComponent = <CreateWoSymlinkIcon className="am--ActionTypeIcon far fa-link-horizontal"/>;
    result.iconPriority = 2;
  } else if (mainType === MAIN_TYPE__ADD_TO_MEMFIS_WO_WITHOUT_SYMLINK) {
    result.iconComponent = <AddToWoWhSymlinkIcon className="am--ActionTypeIcon am--icon--img" src={addToWoWhSymlinkImg}/>;
    result.iconPriority = 3;
  } else if (mainType === MAIN_TYPE__ADD_TO_MEMFIS_WO_REPLACEMENT) {
    result.iconComponent = <AddToWoIcon className="am--ActionTypeIcon fa-regular fa-bandage"/>;
    result.iconPriority = 1;
  } else if (actionType === FS_ACTION_TYPE__ADD_TO_MEMFIS_WO) {
    result.iconComponent = <AddToWoIcon className="am--ActionTypeIcon far fa-plus"/>;
    result.iconPriority = 4;
  }
  return result;
};

class ActionsTree extends Component {
  state = {
    expandedIsInitialized: false,
    expanded: {}
  };

  findIndexOfAction = (actions, actionID, parentActionIDs) => {
    for (let i = 0; i < actions.length; i++) {
      if (actions[i].actionID === actionID) {
        return i;
      }
      if (actions[i].childrenList) {
        const result = this.findIndexOfAction(actions[i].childrenList, actionID, parentActionIDs);
        if (result >= 0) {
          parentActionIDs.push(actions[i].actionID);
          return i;
        }
      }
    }
    return -1;
  };

  componentDidUpdate(prevProps) {
    const {linkActions, actionIdFromStatus} = this.props;
    if (actionIdFromStatus && Array.isArray(linkActions) &&
        (JSON.stringify(linkActions) !== JSON.stringify(prevProps.linkActions) || !this.state.expandedIsInitialized)) {
      const parentActionIDs = [];
      this.findIndexOfAction(linkActions, actionIdFromStatus, parentActionIDs);
      if (parentActionIDs.length > 0) {
        const expanded = {};
        parentActionIDs.forEach(id => {
          expanded[id] = true;
        });
        this.setState({expanded, expandedIsInitialized: true});
      } else {
        this.setState({expandedIsInitialized: true});
      }
    }
  }

  isExpanded = action => {
    return this.state.expanded[action.actionID] === true;
  };

  expandAction = (action, isExpand = true) => {
    this.setState({expanded: {...this.state.expanded, [action.actionID]: isExpand}});
  };

  handleClickExpand = action => {
    this.expandAction(action, !this.isExpanded(action));
  };

  handleClickRestart = action => {
    const {onRestartAction} = this.props;
    onRestartAction(action.actionID);
  };

  renderRestartBtn = (action, waitingRestart) => {
    if (waitingRestart) {
      const text = 'Waiting restart.  FS root(s) locked.';
      return <span className="btn-restart-waiting" title={text}>{text}</span>;
    }
    return (
      <span
        className="btn-restart"
        onClick={() => this.handleClickRestart(action)}
        title="Click to restart action"
      >
        Restart
      </span>
    );
  };

  renderAction = (action, depth) => {
    const {actionIdFromStatus, linkActions, notLockedRoots} = this.props;
    const {actionID, childrenList, actionStatus, actionType, fsRootID, fsRootID2, mainType} = action;
    const hasChildren = childrenList && childrenList.length;
    const isCurrent = actionID === actionIdFromStatus;
    const isExpanded = this.isExpanded(action);
    const actionLink =
      <Link
        to={`/storage?action_id=${actionID}`}
        target="_blank"
      >
        {actionID}
      </Link>;
    const allowRestart = actionStatus === 'FAILED' &&
      (isCurrent || (linkActions.length === 1 && !(linkActions[0].childrenList || []).length));
    const waitingRestart = allowRestart &&
      typeof fsRootID === 'number' && typeof fsRootID2 === 'number' &&
      Array.isArray(notLockedRoots) && !(notLockedRoots.includes(fsRootID) && notLockedRoots.includes(fsRootID2));
    return (
      <div
        key={actionID}
        className={`fs-action${isCurrent ? ' selected' : ''}${waitingRestart ? ' waiting-restart' : ''}`}
        style={{paddingLeft: depth > 0 ? `${depth * 16 + 8}px` : undefined}}
      >
        <i
          className={`fas fa${!hasChildren ? '' : `-${isExpanded ? 'minus' : 'plus'}`}-square`}
          title={isExpanded ? 'Collapse' : 'Expand'}
          onClick={() => this.handleClickExpand(action)}
        />
        {getActionTypeIcon(mainType, actionType).iconComponent}
        <span
          className={`status ${(actionStatus || '').toLowerCase()}`}
          style={StatusColor[actionStatus] ? {backgroundColor: StatusColor[actionStatus]} : undefined}
        >
          {actionType} <strong>{actionStatus}</strong>: {actionLink}
          {allowRestart && this.renderRestartBtn(action, waitingRestart)}
        </span>
      </div>
    );
  };

  renderActions = (actions, depth = 0) => {
    if (!Array.isArray(actions) || !actions.length) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <Fragment key={`action-${action.actionID}`}>
            {this.renderAction(action, depth)}
            {this.isExpanded(action) ? this.renderActions(action.childrenList, depth + 1) : null}
          </Fragment>
        ))}
      </>
    );
  };

  render() {
    const {linkActions} = this.props;
    return (
      <StyledActionsTree>
        {
          (!Array.isArray(linkActions) || !linkActions.length) ?
            <span className="no-actions">None</span> :
              this.renderActions(linkActions)
        }
      </StyledActionsTree>
    );
  }
}

const StyledActionsTree = styled.div`
  .no-actions {
    opacity: 0.65;
  }

  .fs-action {
    display: flex;
    align-items: center;
    margin-top: 3px;
    &.waiting-restart {
      margin-top: 14px;
    }
    &.selected {
      font-weight: bold;
      i {
        color: #0f15e2;
        &:hover {
          color: #0c10b3;
        }
      }
    }
  }

  i {
    margin-right: 5px;
    &:hover {
      color: #282828;
      cursor: pointer;
    }
    &.fa-square {
      pointer-events: none;
      opacity: 0.65;
    }
  }

  .status {
    position: relative;
    background-color: #343a40;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #fff;
    padding: 0 4px;
    a {
      color: #ccc;
      &:hover {
        color: #fff;
      }
    }
    &.new {
      a {
        color: #fff;
      }
    }
    .btn-restart {
      display: none;
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background-color: #fff;
      border: 1px solid #797979;
      border-radius: 4px;
      color: #282828;
      opacity: 0.8;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
    }
    .btn-restart-waiting {
      position: absolute;
      top: -14px;
      left: 0;
      width: 100%;
      padding: 1px 4px;
      background-color: rgb(255 255 255 / 0.9);
      border-radius: 4px;
      color: red;
      font-weight: normal;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      word-break: break-word;
      height: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      .btn-restart {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-restart-waiting {
        height: unset;
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
      }
    }
  }
`;

ActionsTree.propType = {
  linkActions: PropTypes.array,
  actionIdFromStatus: PropTypes.number,
  onRestartAction: PropTypes.func.isRequired
};

export default ActionsTree;

import $ from 'jquery';
import config from '../../../config';
import qs from 'qs';
import _ from 'lodash';
import {
  DISPLAY_CATEGORY_ACTION_CONTENTS,
  DISPLAY_CATEGORY_FOLDER,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION,
  DISPLAY_CATEGORY_ROOTS,
  DISPLAY_CATEGORY_SEARCH_RESULTS,
  FILENAME_AS_WELL_FILTER_NAME,
  DELETED_AS_WELL_FILTER_NAME,
  FS_ACTION_TYPE__CONNECT_DRIVE,
  FS_ACTION_TYPE__DECRYPT_CONTENT,
  FS_ACTION_TYPE__DELETE_WITH_APPROVAL,
  FS_ACTION_TYPE__DISCONNECT_DRIVE,
  FS_ACTION_TYPE__ENCRYPT_CONTENT,
  FS_ACTION_TYPE__GET_APLUS_SETTINGS,
  FS_ACTION_TYPE__INSTANT_SCAN,
  FS_ACTION_TYPE__MK_DIRECTORY,
  FS_ACTION_TYPE__MOVE,
  FS_ACTION_TYPE__MULTIPLE_MOVE,
  FS_ACTION_TYPE__PROXY_COPY,
  FS_ACTION_TYPE__GENERAL_PROXY_COPY,
  FS_ACTION_TYPE__READ_FILE,
  FS_ACTION_TYPE__SET_APLUS_SETTINGS,
  FS_ACTION_TYPE__SET_CONTENT_GROUP_PORTAL,
  FS_ACTION_TYPE__SET_CONTENT_PERMISSIONS_PORTAL,
  FS_ACTION_TYPE__SIMPLE_COPY,
  FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER,
  FS_ACTION_TYPE__START_OFFICE_SERVICE_RUNNERS,
  LAST_MODIFIED_DATE_FILTER_NAME,
  LAST_SEGMENT_ONLY_FILTER_NAME,
  NAME_FILTER_NAME,
  RESULT_TYPE_WITHOUT_PREDICTIVE,
  SEARCH_IN_FILTER_NAME,
  SIZE_FILTER_NAME,
  USE_MAX_MODIFIED_ON_FILTER_NAME,
  USE_PREDICTIVE_FILTER_NAME,
  VIEW_ADVANCED_SIMPLE,
  WO_MEMFIS_FILTER_NAME,
  WITH_FILE_SYMLINKS_FILTER_NAME,
  filterNamesMap,
  SYMLINKS_TOO_FILTER_NAME,
  ALL_BOOLEAN_FILTER_NAMES,
  DEFAULT_BOOLEAN_FILTER_NAMES,
  DefaultBooleanFilters,
  getDefaultBooleanFiltersFromUsedFilters,
  FS_ACTION_TYPE__LINK_TO_MEMFIS_WO,
  FS_ACTION_TYPE__MIGRATE_PORTAL,
  FS_ACTION_TYPE__MD5_CHECKSUM,
  FS_ACTION_TYPE__SHA1_CHECKSUM,
  FS_ACTION_TYPE__DEMUX,
  FS_ACTION_TYPE__PSE_ANALYSIS_PORTAL,
  FS_ACTION_TYPE__UPLOAD_TO_ASPERA,
  FS_ACTION_TYPE__CREATE_SYMLINK_PORTAL,
  FS_ACTION_TYPE__CHECKSUM_REPORT,
  FS_ACTION_TYPE__DELETE_SYMLINK_PORTAL,
  SYMLINK_RELATIVE_STATUS__SOURCE_DELETED,
  SYMLINK_RELATIVE_STATUS__LIVE,
  FS_ACTION_TYPE__RECONCILE_SYMLINK_PORTAL,
  FS_ACTION_TYPE__ADD_TO_MEMFIS_WO,
  FS_ACTION_TYPE__RENAME_SPEC_SYMBOLS_PORTAL,
  FS_ACTION_TYPE__INTEROFFICE_COPY_PORTAL,
  FS_ACTION_TYPE__ATTACH_FILE_QOD,
  PREDICTIVE_STATE_DISAPPEARED, FS_ACTION_TYPE__CAPTIONS, FS_ACTION_TYPE__METAFIER_DOLBY_CREATE_XML, FS_ACTION_TYPE__EDGE_PASS_PORTAL, FS_ACTION_TYPE__SCREEN_TO_QOD_PORTAL
} from './constants';
import {
  ACTIONS_FILTERS, ACTION_FILTER_ACTION_ID, ACTION_FILTER_CONTENT_ID,
  ACTION_FILTER_STATUS, ACTION_FILTER_TYPE_ID, ACTION_FILTER_FS_ROOT_ID, ACTION_FILTER_REQUESTOR_ID,
  ACTION_FILTER_CREATED_ON,
  ACTION_FILTER_RUNNER_ID,
  ACTION_URL_FILTER_POPUP_FLAG,
  ACTION_URL_FILTER_OWN_ACTIONS,
  ACTION_URL_FILTER_RUNNER_ID,
  ACTION_URL_FILTER_TYPE_ID,
  ACTION_URL_FILTER_DAYS_BACK,
  //ACTION_STATUS_ACTIVE,
  ACTION__SOURCE_OF_REQUEST__URL,
  ACTION__SOURCE_OF_REQUEST__ACTIONSBAR,
  ACTION_DEFAULT_PAGE_SIZE,
  ACTION_DEFAULT_SORT,
} from './components/fs-actions/constants';
import {
  showSuccess,
  showError,
  showInfo,
  showWarning,
  showSuccessPopup
} from '../../layouts/actions';
import {
  getErrorMessage, convertSizeToBytes, formatDate, SIZE_UNITS_FILTER
} from '../../utils';
import {
  compileSummaryOfDeletion, buildSuccessMessageDeleteWithApproval, buildSuccessMessageDeleteWithApprovalFromResponse,
  parseName, convertSymbolicToNumeric, parseFileExtension, preparePathForMacOS, convertPathToWindows,
  getCopyToQodInfoList, preparePathForOsWithoutRoot
} from './utils';
import JSZip from 'jszip';
import Ajv from 'ajv';
import { folderManagerGetSubfolderAndExpand } from '../../components/Explorer/actions';
import moment from 'moment';
import {clearAllDeliveryMng} from '../DeliveryManager/actions';

const ajv = Ajv({
  allErrors:true,
});

/*#region Response validation schemas */
const fileSystemResponseSchema = {
  "type": "object",
  "properties": {
    "data": {
      "type": "object",
      "properties": {
        "children": {
          "type": "array"
        },
        "content": {
          "type": "object"
        }
      }
    },
    "description": {
      "type": "array",
      "items": {
        "type": "object"
      }
    }
  }
};

const refreshContentResponseSchema = {
  "type": "object",
  "properties": {
    "data": {
      "type": "object",
      "properties": {
        "content": {
          "type": "object"
        }
      }
    }
  }
};

const advancedSearchValidationSchema = {
  "type": "object",
  "properties": {
    "data": {
      "type": "object",
      "properties": {
        "total": {
          "type": "integer"
        },
        "data": {
          "type": "array"
        }
      }
    },
    "symbolinks": {
      "type": "object",
      "properties": {
        "total": {
          "type": "integer"
        },
        "data": {
          "type": "array"
        }
      }
    }
  }
};
/*#endregion */

export const STORAGE_MNG_IS_LOADING = 'STORAGE_MNG_IS_LOADING';
export const STORAGE_MNG_FETCH_ROOTS = 'STORAGE_MNG_FETCH_ROOTS';
export const STORAGE_MNG_FETCH_FOLDER = 'STORAGE_MNG_FETCH_FOLDER';
export const STORAGE_MNG_SET_CURRENT_FOLDER_FOR_NESTED_TABLE = 'STORAGE_MNG_SET_CURRENT_FOLDER_FOR_NESTED_TABLE';
export const STORAGE_MNG_SET_ACTION_TYPES = 'STORAGE_MNG_SET_ACTION_TYPES';

export const STORAGE_MNG_TOOLBAR_CHANGE_INPUT_NAV = 'STORAGE_MNG_TOOLBAR_CHANGE_INPUT_NAV';
export const STORAGE_MNG_TOOLBAR_IS_REFRESHING_INPUT_NAV = 'STORAGE_MNG_TOOLBAR_IS_REFRESHING_INPUT_NAV';

export const STORAGE_MNG_SEARCH_CHANGE_INPUT = 'STORAGE_MNG_SEARCH_CHANGE_INPUT';
export const STORAGE_MNG_IS_SEARCHING = 'STORAGE_MNG_IS_SEARCHING';
export const STORAGE_MNG_DONE_SEARCH = 'STORAGE_MNG_DONE_SEARCH';
export const STORAGE_MNG_SHOW_ADV_SEARCH_MODAL = 'STORAGE_MNG_SHOW_ADV_SEARCH_MODAL';

export const STORAGE_MNG_SET_AVAILABLE_ACTIONS_BY_SOURCES = 'STORAGE_MNG_SET_AVAILABLE_ACTIONS_BY_SOURCES';

export const STORAGE_MNG_CHANGE_CHECKED_ITEMS = 'STORAGE_MNG_CHANGE_CHECKED_ITEMS';
export const STORAGE_MNG_CHANGE_OPENED_FOLDERS = 'STORAGE_MNG_CHANGE_OPENED_FOLDERS';

export const STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY = 'STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY';
export const STORAGE_MNG_CHANGE_NAVIGATION_HISTORY = 'STORAGE_MNG_CHANGE_NAVIGATION_HISTORY';
export const STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY_FORWARD = 'STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY_FORWARD';
export const STORAGE_MNG_CHANGE_NAVIGATION_HISTORY_FORWARD = 'STORAGE_MNG_CHANGE_NAVIGATION_HISTORY_FORWARD';
export const STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD = 'STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD';

export const STORAGE_MNG_TOGGLE_SHOW_RENAME_AMAZON_FILE_MODAL = 'STORAGE_MNG_TOGGLE_SHOW_RENAME_AMAZON_FILE_MODAL';

export const STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING = 'STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING';
export const STORAGE_MNG_LINK_TO_MEMFIS_WO_SHOW_MODAL = 'STORAGE_MNG_LINK_TO_MEMFIS_WO_SHOW_MODAL';
export const STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING = 'STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING';
export const STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED = 'STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED';
export const STORAGE_MNG_LINK_IN_BURBANK_SHOW_MODAL = 'STORAGE_MNG_LINK_IN_BURBANK_SHOW_MODAL';
export const STORAGE_MNG_LINK_TO_WO_LOAD_CLIENTS_BY_FNAME = 'STORAGE_MNG_LINK_TO_WO_LOAD_CLIENTS_BY_FNAME';
export const STORAGE_MNG_LINK_TO_WO_NEW_WO_BY_FNAME = 'STORAGE_MNG_LINK_TO_WO_NEW_WO_BY_FNAME';
export const STORAGE_MNG_ADD_TO_WO_SAVING = 'STORAGE_MNG_ADD_TO_WO_SAVING';

export const STORAGE_MNG_ADD_TO_WO_INGEST_FILES = 'STORAGE_MNG_ADD_TO_WO_INGEST_FILES';

export const STORAGE_MNG_FINAL_DESTINATION_IS_LOADING = 'STORAGE_MNG_FINAL_DESTINATION_IS_LOADING';
export const STORAGE_MNG_FINAL_DESTINATION = 'STORAGE_MNG_FINAL_DESTINATION';
export const STORAGE_MNG_FINAL_DESTINATION_RESERVED = 'STORAGE_MNG_FINAL_DESTINATION_RESERVED';
export const USE_STORAGE_MNG_FINAL_DESTINATION_RESERVED = 'USE_STORAGE_MNG_FINAL_DESTINATION_RESERVED';

export const STORAGE_MNG_WO_STEPS = 'STORAGE_MNG_WO_STEPS';
export const STORAGE_MNG_WO_STEPS_SHOW_MODAL = 'STORAGE_MNG_WO_STEPS_SHOW_MODAL';
export const STORAGE_MNG_WO_STEPS_ADD_TO_WO_SHOW_MODAL = 'STORAGE_MNG_WO_STEPS_ADD_TO_WO_SHOW_MODAL';
export const STORAGE_MNG_CLEAR_WO_STEPS = 'STORAGE_MNG_CLEAR_WO_STEPS';
export const STORAGE_MNG_ACTIVATE_WO_STEP = 'STORAGE_MNG_ACTIVATE_WO_STEP';
export const STORAGE_MNG_WO_STEPS_EMPTY = 'STORAGE_MNG_WO_STEPS_EMPTY';
export const STORAGE_MNG_WO_STEPS_IS_SAVING = 'STORAGE_MNG_WO_STEPS_IS_SAVING';
export const STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_NEXT = 'STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_NEXT';
export const STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_BACK = 'STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_BACK';

export const STORAGE_MNG_ALERT_SHOW_MODAL = 'STORAGE_MNG_ALERT_SHOW_MODAL';
export const STORAGE_MNG_ALERT_MODAL_DATA = 'STORAGE_MNG_ALERT_MODAL_DATA';

export const STORAGE_MNG_METAFIER_SHOW_MODAL = 'STORAGE_MNG_METAFIER_SHOW_MODAL';

export const STORAGE_MNG_FRAME_RATE_SHOW_MODAL = 'STORAGE_MNG_FRAME_RATE_SHOW_MODAL';
export const STORAGE_MNG_SET_FRAME_RATE = 'STORAGE_MNG_SET_FRAME_RATE';
export const STORAGE_MNG_CLEAR_FRAME_RATE = 'STORAGE_MNG_CLEAR_FRAME_RATE';
export const STORAGE_MNG_FRAME_RATE_UNCHECK_LKFS = 'STORAGE_MNG_FRAME_RATE_UNCHECK_LKFS';
export const STORAGE_MNG_SET_ERROR_NUMBER_FRAME_RATE = 'STORAGE_MNG_SET_ERROR_NUMBER_FRAME_RATE';

export const STORAGE_MNG_PSE_SHOW_MODAL = 'STORAGE_MNG_PSE_SHOW_MODAL';
export const STORAGE_MNG_PSE_IS_LOADING = 'STORAGE_MNG_PSE_IS_LOADING';
export const STORAGE_MNG_PSE_HDR_FILE_TYPE = 'STORAGE_MNG_PSE_HDR_FILE_TYPE';

export const STORAGE_MNG_EDGE_PASS_SHOW_MODAL = 'STORAGE_MNG_EDGE_PASS_SHOW_MODAL';
export const STORAGE_MNG_EDGE_PASS_IS_LOADING = 'STORAGE_MNG_EDGE_PASS_IS_LOADING';
export const STORAGE_MNG_EDGE_PASS_HDR_FILE_TYPE = 'STORAGE_MNG_EDGE_PASS_HDR_FILE_TYPE';

export const STORAGE_MNG_SCREEN_TO_QOD_SHOW_MODAL = 'STORAGE_MNG_SCREEN_TO_QOD_SHOW_MODAL';
export const STORAGE_MNG_SCREEN_TO_QOD_IS_LOADING = 'STORAGE_MNG_SCREEN_TO_QOD_IS_LOADING';
export const STORAGE_MNG_SCREEN_TO_QOD_HDR_FILE_TYPE = 'STORAGE_MNG_SCREEN_TO_QOD_HDR_FILE_TYPE';

export const STORAGE_MNG_CHECKSUM_GEN = 'STORAGE_MNG_CHECKSUM_GEN';
export const STORAGE_MNG_CHECKSUM_SHOW_MODAL = 'STORAGE_MNG_CHECKSUM_SHOW_MODAL';
export const STORAGE_MNG_CHECKSUM_MD5_SHOW_MODAL = 'STORAGE_MNG_CHECKSUM_MD5_SHOW_MODAL';
export const STORAGE_MNG_CHECKSUM_SHA1_SHOW_MODAL = 'STORAGE_MNG_CHECKSUM_SHA1_SHOW_MODAL';

export const STORAGE_MNG_INSTANT_SCAN_START = 'STORAGE_MNG_INSTANT_SCAN_START';
export const STORAGE_MNG_INSTANT_SCAN_STOP = 'STORAGE_MNG_INSTANT_SCAN_STOP';
export const STORAGE_MNG_INSTANT_SCAN_CLOSE = 'STORAGE_MNG_INSTANT_SCAN_CLOSE';

export const STORAGE_MNG_ACTIONS_IS_LOADING = 'STORAGE_MNG_ACTIONS_IS_LOADING';
export const STORAGE_MNG_ACTIONS_SHOW = 'STORAGE_MNG_ACTIONS_SHOW';
export const STORAGE_MNG_ACTIONS_CLOSE = 'STORAGE_MNG_ACTIONS_CLOSE';
export const STORAGE_MNG_ACTIONS_CONTENTS = 'STORAGE_MNG_ACTIONS_CONTENTS';
export const STORAGE_MNG_ACTIONS_CONTENTS_IS_LOADING = 'STORAGE_MNG_ACTIONS_CONTENTS_IS_LOADING';
export const STORAGE_MNG_ACTIONS_CHILDREN = 'STORAGE_MNG_ACTIONS_CHILDREN';
export const STORAGE_MNG_ACTIONS_PARENT = 'STORAGE_MNG_ACTIONS_PARENT';

export const STORAGE_MNG_ACTIONS_REQUESTORS_SHOW = 'STORAGE_MNG_ACTIONS_REQUESTORS_SHOW';
export const STORAGE_MNG_ACTIONS_SUBSCRIBERS_SHOW = 'STORAGE_MNG_ACTIONS_SUBSCRIBERS_SHOW';

export const STORAGE_MNG_MY_ACTIONS_SHOW = 'STORAGE_MNG_MY_ACTIONS_SHOW';
export const STORAGE_MNG_MY_ACTIONS_CLOSE = 'STORAGE_MNG_MY_ACTIONS_CLOSE';
export const STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING = 'STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING';
export const STORAGE_MNG_MY_ACTIONS_ACTIVE_CONTENTS = 'STORAGE_MNG_MY_ACTIONS_ACTIVE_CONTENTS';

export const STORAGE_MNG_LOAD_ACTION_PERMISSIONS = 'STORAGE_MNG_LOAD_ACTION_PERMISSIONS';

export const STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING = 'STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING';
export const STORAGE_MNG_ACTIONS_USER_PERMISSIONS = 'STORAGE_MNG_ACTIONS_USER_PERMISSIONS';
export const STORAGE_MNG_ACTIONS_USER_PERMISSIONS_WITHOUT_ROOTS = 'STORAGE_MNG_ACTIONS_USER_PERMISSIONS_WITHOUT_ROOTS';

export const STORAGE_MNG_NAV_INTO_IS_LOADING = 'STORAGE_MNG_NAV_INTO_IS_LOADING';
export const STORAGE_MNG_NAV_INTO_FETCH_FOLDER = 'STORAGE_MNG_NAV_INTO_FETCH_FOLDER';

export const STORAGE_MNG_PREDICTIVE_STATES_IS_LOADING = 'STORAGE_MNG_PREDICTIVE_STATES_IS_LOADING';
export const STORAGE_MNG_PREDICTIVE_STATES = 'STORAGE_MNG_PREDICTIVE_STATES';

export const STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING = 'STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING';
export const STORAGE_MNG_LOCK_AND_SERVICES_STATUSES = 'STORAGE_MNG_LOCK_AND_SERVICES_STATUSES';

export const STORAGE_MNG_ACTIVE_FS_ROOTS_IS_LOADING = 'STORAGE_MNG_ACTIVE_FS_ROOTS_IS_LOADING';
export const STORAGE_MNG_ACTIVE_FS_ROOTS = 'STORAGE_MNG_ACTIVE_FS_ROOTS';

export const STORAGE_MNG_SET_CURRENT_VIEW = 'STORAGE_MNG_SET_CURRENT_VIEW';
export const STORAGE_MNG_SET_HIDE_TEMP_FILES = 'STORAGE_MNG_SET_HIDE_TEMP_FILES';

export const STORAGE_MNG_INITIAL_CONFIRMATION_DELETION = 'STORAGE_MNG_INITIAL_CONFIRMATION_DELETION';
export const STORAGE_MNG_INGEST_SHOW_MODAL = 'STORAGE_MNG_INGEST_SHOW_MODAL';
export const STORAGE_MNG_INGEST_OPERATION_TYPES_IS_LOADING = 'STORAGE_MNG_INGEST_OPERATION_TYPES_IS_LOADING';
export const STORAGE_MNG_MODAL_IS_LOADING = 'STORAGE_MNG_MODAL_IS_LOADING';
export const STORAGE_MNG_FOLDER_CHILDREN_RECEIVE = 'STORAGE_MNG_FOLDER_CHILDREN_RECEIVE';
export const STORAGE_MNG_CHANGE_OPENED_FOLDERS_IN_MODAL = 'STORAGE_MNG_CHANGE_OPENED_FOLDERS_IN_MODAL';
export const STORAGE_MNG_SET_MODAL_STEP = 'STORAGE_MNG_SET_MODAL_STEP';
export const STORAGE_MNG_MOVE_SHOW_MODAL = 'STORAGE_MNG_MOVE_SHOW_MODAL';
export const STORAGE_MNG_RENAME_SHOW_MODAL = 'STORAGE_MNG_RENAME_SHOW_MODAL';
export const STORAGE_MNG_CONTENT_PERMISSIONS_SHOW_MODAL = 'STORAGE_MNG_CONTENT_PERMISSIONS_SHOW_MODAL';
export const STORAGE_MNG_NEW_DIRECTORY_SHOW_MODAL = 'STORAGE_MNG_NEW_DIRECTORY_SHOW_MODAL';
export const STORAGE_MNG_SCHEDULED_PAUSE_SHOW_MODAL = 'STORAGE_MNG_SCHEDULED_PAUSE_SHOW_MODAL';

export const STORAGE_MNG_LKFS_SWITCH_MODAL = 'STORAGE_MNG_LKFS_SWITCH_MODAL';
export const STORAGE_MNG_LKFS_AUTO_UPDATE_AMB = 'STORAGE_MNG_LKFS_AUTO_UPDATE_AMB';
export const STORAGE_MNG_LKFS_UPDATE_AMB = 'STORAGE_MNG_LKFS_UPDATE_AMB';
export const STORAGE_MNG_LKFS_GOTO_SCREEN = 'STORAGE_MNG_LKFS_GOTO_SCREEN';
export const STORAGE_MNG_LKFS_RESET = 'STORAGE_MNG_LKFS_RESET';
export const STORAGE_MNG_LKFS_UPDATE_WO_MEMFIS = 'STORAGE_MNG_LKFS_UPDATE_WO_MEMFIS';
export const STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION = 'STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION';
export const STORAGE_MNG_LKFS_CHOOSE_DESTINATION = 'STORAGE_MNG_LKFS_CHOOSE_DESTINATION';
export const STORAGE_MNG_LKFS_SET_AUTO_WO_STATUS = 'STORAGE_MNG_LKFS_SET_AUTO_WO_STATUS';
export const STORAGE_MNG_LKFS_SEND_DATA = 'STORAGE_MNG_LKFS_SEND_DATA';
export const STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE = 'STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE';

export const STORAGE_MNG_CAPTIONS_SWITCH_MODAL = 'STORAGE_MNG_CAPTIONS_SWITCH_MODAL';
export const STORAGE_MNG_CAPTIONS_SHOW_MODAL = 'STORAGE_MNG_CAPTIONS_SHOW_MODAL';

export const STORAGE_MNG_SET_FOLDERS_IN_MODAL = 'STORAGE_MNG_SET_FOLDERS_IN_MODAL';
export const STORAGE_MNG_CLEAR_ALL = 'STORAGE_MNG_CLEAR_ALL';
export const STORAGE_MNG_CLEAR_ALL_FOR_DOWNLOAD_MNG = 'STORAGE_MNG_CLEAR_ALL_FOR_DOWNLOAD_MNG';

export const STORAGE_MNG_READ_FILE_START = 'STORAGE_MNG_READ_FILE_START';
export const STORAGE_MNG_READ_FILE_STOP = 'STORAGE_MNG_READ_FILE_STOP';
export const STORAGE_MNG_SET_LOADING = 'STORAGE_MNG_SET_LOADING';
export const STORAGE_MNG_FILE_SETTINGS_ALLOW_READING = 'STORAGE_MNG_FILE_SETTINGS_ALLOW_READING';

export const STORAGE_MNG_SHOW_APLUS_SETTINGS = 'STORAGE_MNG_SHOW_APLUS_SETTINGS';

export const STORAGE_MNG_SHOW_MANUALLY_RECONCILE_MODAL = 'STORAGE_MNG_SHOW_MANUALLY_RECONCILE_MODAL';
export const STORAGE_MNG_SHOW_SELECT_FOLDER_MODAL = 'STORAGE_MNG_SHOW_SELECT_FOLDER_MODAL';

export const STORAGE_MNG_SWITCH_SETTINGS_MODAL = 'STORAGE_MNG_SWITCH_SETTINGS_MODAL';
export const STORAGE_MNG_LOAD_SETTINGS = 'STORAGE_MNG_LOAD_SETTINGS';
export const STORAGE_MNG_SAVE_SETTINGS = 'STORAGE_MNG_SAVE_SETTINGS';

export const STORAGE_MNG_SWITCH_MY_ACTIONS_MODAL = 'STORAGE_MNG_SWITCH_MY_ACTIONS_MODAL';
export const STORAGE_MNG_LOAD_MY_ACTIONS = 'STORAGE_MNG_LOAD_MY_ACTIONS';

export const STORAGE_MNG_SHOW_AVAILABLE_DRIVES_MODAL = 'STORAGE_MNG_SHOW_AVAILABLE_DRIVES_MODAL';
export const STORAGE_MNG_CONNECT_DRIVE = 'STORAGE_MNG_CONNECT_DRIVE';
export const STORAGE_MNG_DISCONNECT_DRIVE = 'STORAGE_MNG_DISCONNECT_DRIVE';

export const STORAGE_MNG_SHOW_ENCRYPT_MODAL = 'STORAGE_MNG_SHOW_ENCRYPT_MODAL';
export const STORAGE_MNG_SINGLE_ENCRYPT_OR_DECRYPT = 'STORAGE_MNG_SINGLE_ENCRYPT_OR_DECRYPT';

export const STORAGE_MNG_TEMP_DIRS_ADDED = 'STORAGE_MNG_TEMP_DIRS_ADDED';
export const STORAGE_MNG_TEMP_DIRS_DELETED = 'STORAGE_MNG_TEMP_DIRS_DELETED';
export const STORAGE_MNG_REMOVE_FROM_TEMP_DIRS = 'STORAGE_MNG_REMOVE_FROM_TEMP_DIRS';

export const STORAGE_MNG_CLIENT_NAME_LOADING = 'STORAGE_MNG_CLIENT_NAME_LOADING';
export const STORAGE_MNG_CLIENT_NAME_SET = 'STORAGE_MNG_CLIENT_NAME_SET';
export const STORAGE_MNG_CLIENT_LIST_SET = 'STORAGE_MNG_CLIENT_LIST_SET';

export const STORAGE_MNG_REFRESH_SEL_CONTENT_IN_CUR_FOLDER = 'STORAGE_MNG_REFRESH_SEL_CONTENT_IN_CUR_FOLDER';
export const STORAGE_MNG_REFRESH_SEL_ROOT = 'STORAGE_MNG_REFRESH_SEL_ROOT';

export const STORAGE_MNG_COPY_SHOW_MODAL = 'STORAGE_MNG_COPY_SHOW_MODAL';
export const STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL = 'STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL';
export const STORAGE_MNG_SET_QOD_INFO = 'STORAGE_MNG_SET_QOD_INFO';
export const STORAGE_MNG_SET_QOD_AVAILABLE_WOMEMFISES = 'STORAGE_MNG_SET_QOD_AVAILABLE_WOMEMFISES';
export const STORAGE_MNG_SET_QOD_PROXIES = 'STORAGE_MNG_SET_QOD_PROXIES';

export const STORAGE_MNG_SCRIPT_RUNNER_ACTION_STATUS_BIT = 'STORAGE_MNG_SCRIPT_RUNNER_ACTION_STATUS_BIT';
export const STORAGE_MNG_FS_RUNNER_ACTION_STATUS_BIT = 'STORAGE_MNG_FS_RUNNER_ACTION_STATUS_BIT';
export const STORAGE_MNG_MACHINE_RUNNER_ACTION_STATUS_BIT = 'STORAGE_MNG_MACHINE_RUNNER_ACTION_STATUS_BIT';
export const STORAGE_MNG_OFFICE_ACTION_STATUS_BIT = 'STORAGE_MNG_OFFICE_ACTION_STATUS_BIT';
export const STORAGE_MNG_ALL_OFFICES_ACTION_STATUS_BIT = 'STORAGE_MNG_ALL_OFFICES_ACTION_STATUS_BIT';

export const STORAGE_MNG_SET_CONTENT_PERMISSIONS_SUCCESS = 'STORAGE_MNG_SET_CONTENT_PERMISSIONS_SUCCESS';

export const STORAGE_MNG_ADD_FILE_TO_WO_SWITCH_MODAL = 'STORAGE_MNG_ADD_FILE_TO_WO_SWITCH_MODAL';
export const STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN = 'STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN';
export const STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_STATUS = 'STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_STATUS';
export const STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_WO_MEMFIS = 'STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_WO_MEMFIS';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_ASSIGN_NEW_WO = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_ASSIGN_NEW_WO';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_NEW_WO = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_NEW_WO';
export const STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_DESTINATION = 'STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_DESTINATION';
export const STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_COPY_DESTINATION = 'STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_COPY_DESTINATION';
export const STORAGE_MNG_ADD_FILE_TO_WO_AUTO_CHOOSE_DESTINATION = 'STORAGE_MNG_ADD_FILE_TO_WO_AUTO_CHOOSE_DESTINATION';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_AUTO_CHOOSE_DESTINATION = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_AUTO_CHOOSE_DESTINATION';
export const STORAGE_MNG_ADD_FILE_TO_WO_NEW_FOLDER_CHECKBOX_CHANGE = 'STORAGE_MNG_ADD_FILE_TO_WO_NEW_FOLDER_CHECKBOX_CHANGE';
export const STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_DESTINATION_AVAILABLE = 'STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_DESTINATION_AVAILABLE';
export const STORAGE_MNG_ADD_FILE_TO_WO_RESET_DESTINATION = 'STORAGE_MNG_ADD_FILE_TO_WO_RESET_DESTINATION';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_SELECTED_ROOT = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_SELECTED_ROOT';
export const STORAGE_MNG_ADD_FILE_TO_WO_CLEAR ='STORAGE_MNG_ADD_FILE_TO_WO_CLEAR';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX_FILE = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX_FILE';
export const STORAGE_MNG_ADD_FILE_TO_WO_AUTO_SAVE_REPLACEMENT = 'STORAGE_MNG_ADD_FILE_TO_WO_AUTO_SAVE_REPLACEMENT';
export const STORAGE_MNG_ADD_FILE_TO_WO_RESET_AMB = 'STORAGE_MNG_ADD_FILE_TO_WO_RESET_AMB';
export const STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_AMB = 'STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_AMB';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS';
export const STORAGE_MNG_ADD_FILE_TO_WO_CREATE_QOD_PROXY_CHECKBOX_CHANGE = 'STORAGE_MNG_ADD_FILE_TO_WO_CREATE_QOD_PROXY_CHECKBOX_CHANGE';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_DELIVERY_RENAME_AVAILABLE = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_DELIVERY_RENAME_AVAILABLE';
export const STORAGE_MNG_ADD_FILE_TO_WO_SEND_CAPTIONS_TO_QOD_CHECKBOX_CHANGE = 'STORAGE_MNG_ADD_FILE_TO_WO_SEND_CAPTIONS_TO_QOD_CHECKBOX_CHANGE';

export const STORAGE_MNG_MEDIA_INFO_SHOW_MODAL = 'STORAGE_MNG_MEDIA_INFO_SHOW_MODAL';
export const STORAGE_MNG_SET_MEDIA_INFO = 'STORAGE_MNG_SET_MEDIA_INFO';

export const STORAGE_MNG_CREATE_SYMLINK_SHOW_MODAL = 'STORAGE_MNG_CREATE_SYMLINK_SHOW_MODAL';

export const STORAGE_MNG_UPLOAD_TO_ASPERA_SHOW_MODAL = 'STORAGE_MNG_UPLOAD_TO_ASPERA_SHOW_MODAL';
export const STORAGE_MNG_LOAD_FASPEX_ACCOUNTS = 'STORAGE_MNG_LOAD_FASPEX_ACCOUNTS';

export const STORAGE_MNG_UNLINK_SHOW_MODAL = 'STORAGE_MNG_UNLINK_SHOW_MODAL';

export const STORAGE_MNG_CHECKSUM_REPORT_TOGGLE_MODAL = 'STORAGE_MNG_CHECKSUM_REPORT_TOGGLE_MODAL';

export const STORAGE_MNG_CHANGE_SYMLINK_RELATIVE_STATUS = 'STORAGE_MNG_CHANGE_SYMLINK_RELATIVE_STATUS';

export const STORAGE_MNG_SET_IS_CREATE_SYMLINK_CHECKED = 'STORAGE_MNG_SET_IS_CREATE_SYMLINK_CHECKED';
export const STORAGE_MNG_SET_REPLACEMENT_TYPE_VALUE = 'STORAGE_MNG_SET_REPLACEMENT_TYPE_VALUE';

export const STORAGE_MNG_SET_ATTACHED_VOLUMES = 'STORAGE_MNG_SET_ATTACHED_VOLUMES';

export const STORAGE_MNG_TOGGLE_ACTION_PROGRESS = 'STORAGE_MNG_TOGGLE_ACTION_PROGRESS';
export const STORAGE_MNG_LOADING_ACTION_PROGRESS = 'STORAGE_MNG_LOADING_ACTION_PROGRESS';
export const STORAGE_MNG_SET_ACTION_PROGRESS = 'STORAGE_MNG_SET_ACTION_PROGRESS';

export const STORAGE_MNG_TOGGLE_ACTION_SUBSCRIBE_POPUP = 'STORAGE_MNG_TOGGLE_ACTION_SUBSCRIBE_POPUP';


export const DIRECTORY_TREE_CHANGE_SEARCH_INPUT = 'DIRECTORY_TREE_CHANGE_SEARCH_INPUT';
export const DIRECTORY_TREE_IS_LOADING = 'DIRECTORY_TREE_IS_LOADING';
export const DIRECTORY_TREE_FETCH_FOLDER = 'DIRECTORY_TREE_FETCH_FOLDER';

export const STORAGE_MNG_TOGGLE_DELIVERY_RENAME_POPUP = 'STORAGE_MNG_TOGGLE_DELIVERY_RENAME_POPUP';

export const STORAGE_MNG_RESET_COPY_MODAL_DATA = 'STORAGE_MNG_RESET_COPY_MODAL_DATA';
export const STORAGE_MNG_RESET_COPY_TO_QOD_MODAL_DATA = 'STORAGE_MNG_RESET_COPY_TO_QOD_MODAL_DATA';

export const STORAGE_MNG_SET_ENABLE_ACTION_TO_WO = 'STORAGE_MNG_SET_ENABLE_ACTION_TO_WO';

export const STORAGE_MNG_TOGGLE_ATTACH_TO_WO_MODAL = 'STORAGE_MNG_TOGGLE_ATTACH_TO_WO_MODAL';
export const STORAGE_MNG_TOGGLE_ISSUE_SCREENSHOT_QOD_SHOW_MODAL = 'STORAGE_MNG_TOGGLE_ISSUE_SCREENSHOT_QOD_SHOW_MODAL';

export const STORAGE_MNG_ADD_FILE_TO_WO_SET_DISNEY_INFO = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_DISNEY_INFO';
export const STORAGE_MNG_ADD_FILE_TO_WO_SET_EMPTY_DISNEY_INFO = 'STORAGE_MNG_ADD_FILE_TO_WO_SET_EMPTY_DISNEY_INFO';

export const clearAllStorageMng = () => ({type: STORAGE_MNG_CLEAR_ALL});

export const clearAllStorageMngForDownloadMng = () => ({type: STORAGE_MNG_CLEAR_ALL_FOR_DOWNLOAD_MNG});

const getValidFilter = (allFilters, filter, value)  => {
  const isExist = allFilters.map(item => item.name).includes(filter);

  if (isExist) {
    const existFilter = allFilters.find(item => item.name === filter) || {};

    if (existFilter.type === 'select') {
      const multi = typeof existFilter.multi !== 'boolean' || existFilter.multi;
      const valueKey = existFilter.valueKey || 'value';
      const existFilterValues = existFilter.values ? existFilter.values.map(f => f[valueKey]) : [];
      return multi && Array.isArray(value) ?
        value.filter(item => existFilterValues.includes(+item)) :
        !multi && !isNaN(parseInt(value, 10)) ? parseInt(value, 10) :
        null;
    } else if (existFilter.type === 'date') {
      const res = {...value};
      const isObj = typeof res === 'object' && Object.keys(res).length ? Object.keys(res) : [];
      isObj.forEach(key => {
        const date = new Date(res[key]);
        if (!date.getTime()) {
          delete res[key];
        }
      });
      return Object.keys(res).length ? res : null;
    } else if (existFilter.type === 'size') {
      const res = {...value};
      const isObj = typeof res === 'object' && Object.keys(res).length ? Object.keys(res) : [];
      isObj.forEach(key => {
        const size = parseInt(res[key], 10);
        if (isNaN(size) && !key.endsWith('unit')) {
          delete res[key];
          delete res[`${key}_unit`];
        }
      });
      return Object.keys(res).length ? {to_unit: SIZE_UNITS_FILTER[0], from_unit: SIZE_UNITS_FILTER[0], ...res} : null;
    } else if (existFilter.type === 'string') {
      return value;
    } else if (existFilter.type === 'bool') {
      return String(value) === 'true' ? true :
        String(value) === 'false'/* && existFilter.name === USE_MAX_MODIFIED_ON_FILTER_NAME*/ ? false : null;
    } else if (existFilter.type === 'radio' && existFilter.name === SEARCH_IN_FILTER_NAME) {
      return Array.isArray(value) && value.length ? value : null;
    } else if (existFilter.type === 'custom') {
      const res = {...value};
      const isObj = typeof res === 'object' && Object.keys(res).length ? Object.keys(res) : [];
      isObj.forEach(key => {
        const fieldType = existFilter.fieldTypes[key];
        if (fieldType === 'date') {
          const date = new Date(res[key]);
          if (!date.getTime()) {
            delete res[key];
          }
        } else if (fieldType === 'number') {
          const number = parseInt(res[key], 10);
          if (isNaN(number)) {
            delete res[key];
          }
        } else {
          delete res[key];
        }
      });
      return Object.keys(res).length ? res : null;
    }
  }
  return null;
};

export const getValidFilters = (allFilters, urlFilters) => {
  const res = {};

  urlFilters && Object.keys(urlFilters).forEach(key => {

    if (false) {
      res[key] = urlFilters[key];
    } else if (key === WITH_FILE_SYMLINKS_FILTER_NAME) {
      if (String(urlFilters[key]) === 'true' && getValidFilter(allFilters, WO_MEMFIS_FILTER_NAME, urlFilters[WO_MEMFIS_FILTER_NAME])) {
        res[key] = true;
      }
    } else {
      const validFilter = getValidFilter(allFilters, key, urlFilters[key]);
      if (validFilter || validFilter === false) {
        res[key] = validFilter;
      }
    }
  });

  return res;
};

export const getSearchFilters = (filters, allFilters) => {
  const resFilters = {};
  filters && Object.keys(filters).forEach(filterKey => {
    if (Array.isArray(filters[filterKey])) {
      resFilters[filterKey] = filters[filterKey].map(item => item.value || item);
    } else if (allFilters && allFilters.length) {

      const existFilter = allFilters.find(item => item.name === filterKey) || {};
      resFilters[filterKey] = existFilter && existFilter.type === 'select' ?
        [filters[filterKey]] :
        filters[filterKey];

    } else {
      resFilters[filterKey] = filters[filterKey];
    }
  });
  return resFilters || {};
};

export const getFiltersQuery = filters => {
  const resFilters = {...filters};
  Object.keys(resFilters).forEach(key => {
    const filter = resFilters[key];
    const isEmptyObj = typeof filter === 'object' && !Object.keys(filter).length;
    const isEmpty = (!filter && (filter !== false || !ALL_BOOLEAN_FILTER_NAMES.includes(key))) ||
      (Array.isArray(filter) && !filter.length) || isEmptyObj;
    if (isEmpty) {
      delete resFilters[key];
    }
  });
  return resFilters;
};

const prepareFsRootLabelWithOffice = fsRoot => {
  const {macRootPath, officeFlag, name} = (fsRoot || {});
  let officeLabel;
  if (officeFlag && (officeFlag & config.officeFlag.NEW_YORK) > 0) {
    officeLabel = 'NY';
  } else if (officeFlag && (officeFlag & config.officeFlag.BURBANK) > 0) {
    officeLabel = 'B';
  } else {
    officeLabel = '';
  }
  const res = {};
  if (macRootPath) {
    res.unixName = macRootPath.replace(/^[/](macos_path|Volumes|mnt)[/]/, '');
    res.unixNameWithOfficeLabel = (officeLabel + ' ' + res.unixName).trim();
  }
  res.nameWithOfficeLabel = (officeLabel + ' ' + name).trim();
  return res;
};

const parseFilename = (filename, fsRootName, isDirectory, fsRoot) => {
    const macOsFor = {};
    let fsRootUnix = {};

    let name = parseName(filename);
    if (!name) {
      name = filename;
    }

    let fullPath = filename.substr(0, filename.lastIndexOf(name) - 1);
    if (!fullPath) {
      fullPath = filename;
    }
    if (fullPath.endsWith(':') && filename === fullPath) {
      if (filename === fsRootName) {
        fullPath = '\\';
        const {macRootPath} = (fsRoot || {});
        if (macRootPath) {
          macOsFor.name = macRootPath;
          fsRootUnix = prepareFsRootLabelWithOffice(fsRoot);
        }
      } else {
        fullPath += '\\';
      }
    }
    macOsFor.fullPath = preparePathForMacOS(fullPath, {...fsRoot, fsRootName});

    macOsFor.key = preparePathForMacOS(filename, {...fsRoot, fsRootName});

    let fileExtensionP;
    if (isDirectory === false) {
      fileExtensionP = parseFileExtension(name || '');
    }

    return {name, fullPath, fileExtensionP, macOsFor, ...fsRootUnix};
};

const prepareMemfisWoIdAndBarCode = file => {
  if (file.memfisWoID && String(file.memfisWoID).match(/^[0-9]+$/)) {
    file.memfisWoID = `WO${file.memfisWoID}`;
  }
  if (file.barCode && String(file.barCode).match(/^[0-9]+$/)) {
    file.barCode = `MEM${file.barCode}`;
  }
  return file;
};

export const prepareFile = (file, roots) => {
  const root = (roots || []).find(({fsRootID, id, value}) => file.fsRootID === (fsRootID || id || value));
  return {
    ...file,
    ...prepareMemfisWoIdAndBarCode(file),
    ...parseFilename(file.key || '', file.fsRootName, file.isDirectory, root)
  };
};

const prepareRoot = root => {
  const isDirectory = true;
  root.updated = root.updated && Math.abs((new Date(root.updated)).getTime() / 3600000)<24 ? null : root.updated;
  root.scanned = root.scanned && Math.abs((new Date(root.updated)).getTime() / 3600000)<24 ? null : root.scanned;
  return {
    ...root,
    ...prepareMemfisWoIdAndBarCode(root),
    id: Number(root.id),
    fsRootID: Number(root.id),
    contentID: root.contentID || (-Number(root.id)),
    fsRootUpdated: root.updated,
    fsRootScanned: root.scanned,
    fsRootName: root.name,
    key: root.name,
    ...parseFilename(root.name || root.key || '', root.name, isDirectory, root),
    isDirectory,
    isRoot: true
  };
};

const prepareFsRoots = roots => {
  return (roots || []).map(root => ({value: Number(root.id), label: root.name, macLabel: root.macRootPath, ...prepareFsRootLabelWithOffice(root)}));
};

const addCurrentNavigationToHistory = intoBackHistory => (dispatch, getState) => {
  const {
    displayCategory, usedFilters, currentFolder, currentAction, navigationHistoryForward, displayedItems
  } = getState().storageManager;
  if (!displayCategory) {
    return;
  }
  const navItem = {displayCategory};
  if (displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS) {
    navItem.data = {...usedFilters, isHistory: true};
  } else if (displayCategory === DISPLAY_CATEGORY_FOLDER) {
    navItem.data = currentFolder;
  } else if (displayCategory === DISPLAY_CATEGORY_ACTION_CONTENTS) {
    navItem.data = currentAction;
  } else if (displayCategory === DISPLAY_CATEGORY_INIT_CONFIRM_DELETION) {
    navItem.data = {items: displayedItems};
  }
  if (intoBackHistory) {
    dispatch({type: STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY, item: navItem});
  } else {
    const historyLength = navigationHistoryForward.length;
    if (!historyLength || (JSON.stringify(navigationHistoryForward[historyLength - 1]) !== JSON.stringify(navItem))) {
      dispatch({type: STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY_FORWARD, item: navItem});
    }
  }
};

export const getActiveFsRoots = () => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIVE_FS_ROOTS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/roots`
  })
  .done(res => {
    const roots = (res || []).map(prepareRoot);
    dispatch({type: STORAGE_MNG_ACTIVE_FS_ROOTS, data: prepareFsRoots(res), roots});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_ACTIVE_FS_ROOTS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get active FS roots.  ${getErrorMessage(error)}`));
  });
};

const isUsePredictive = currentView => {
  return currentView !== VIEW_ADVANCED_SIMPLE;
};

const getRoots = (history, addCurrentNavToHistoryBack, validUrlFilters, checkRootWithId) => (dispatch, getState) => {
  const {usedFilters} = getState().storageManager;
  dispatch({type: STORAGE_MNG_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/roots`
  })
  .done(res => {
    if (typeof addCurrentNavToHistoryBack === 'boolean') {
      dispatch(addCurrentNavigationToHistory(addCurrentNavToHistoryBack));
    }

    const roots = (res || []).map(prepareRoot);
    dispatch({type: STORAGE_MNG_ACTIVE_FS_ROOTS, data: prepareFsRoots(res), roots});

    const filters = validUrlFilters || getDefaultBooleanFiltersFromUsedFilters(usedFilters);
    dispatch({type: STORAGE_MNG_FETCH_ROOTS, navString: 'All Volumes', roots, filters, checkRootWithId});
    if (history) {
      const search = JSON.stringify(filters) === JSON.stringify(DefaultBooleanFilters) ? '' :
        `${qs.stringify(filters)}`;
      history.replace({search});
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
    dispatch(showError(`Could not get roots.  ${getErrorMessage(error)}`));
  });
};

export const changeSearchInput = value => dispatch => {
  dispatch({type: STORAGE_MNG_SEARCH_CHANGE_INPUT, value});
};

export const showAdvSearchModal = visible => dispatch => {
  dispatch({type: STORAGE_MNG_SHOW_ADV_SEARCH_MODAL, visible});
};

const showResultWithoutPredictiveMessage = description => (dispatch, getState) => {
  const {availableFsRoots} = getState().storageManager;
  if (availableFsRoots.length && description) {
    description.forEach(d => {
      if (d.ResultType === RESULT_TYPE_WITHOUT_PREDICTIVE) {
        const root = availableFsRoots.find(root => d.FsRootID === root.value)
        dispatch(showSuccess(d.ResultTypeDescription, `FS root '${root.label}'`, 2000));
      }
    });
  }
};

const convertFiltersToString = filters => {
  const searchString = [];
  for (let key in filters) {
    const value = filters[key];
    switch (key) {
      case NAME_FILTER_NAME:
        if (value) {
          searchString.push(`${filterNamesMap[key]}: ${value}`);
        }
        break;
      case SIZE_FILTER_NAME:
        if (value) {
          if (value.from >= 0) {
            const sizeUnit = (value.from_unit || SIZE_UNITS_FILTER[0]).toLowerCase();
            searchString.push(`${filterNamesMap[key]}min: ${value.from} ${sizeUnit}`);
          }
          if (value.to >= 0) {
            const sizeUnit = (value.to_unit || SIZE_UNITS_FILTER[0]).toLowerCase();
            searchString.push(`${filterNamesMap[key]}max: ${value.to} ${sizeUnit}`);
          }
        }
        break;
      case LAST_MODIFIED_DATE_FILTER_NAME:
        if (value) {
          const dateMin = formatDate(value.start);
          const dateMax = formatDate(value.end);
          if (dateMin) {
            searchString.push(`${filterNamesMap[key]}from: ${dateMin}`);
          }
          if (dateMax) {
            searchString.push(`${filterNamesMap[key]}to: ${dateMax}`);
          }
        }
        break;
      case USE_MAX_MODIFIED_ON_FILTER_NAME:
      case FILENAME_AS_WELL_FILTER_NAME:
      case DELETED_AS_WELL_FILTER_NAME:
      case LAST_SEGMENT_ONLY_FILTER_NAME:
      case SYMLINKS_TOO_FILTER_NAME:
        if (typeof value === 'boolean') {
          searchString.push(`${key}: ${value}`);
        }
        break;
      case WO_MEMFIS_FILTER_NAME:
        if (value) {
          searchString.push(`${key}: ${value}`);
        }
        break;
      default:
        break;
    }
  }
  return searchString.join(' ');
};

const applySearchResults = (res, validUrlFilters, searchString, addCurrentNavToHistoryBack) => (dispatch, getState) => {
    if (typeof addCurrentNavToHistoryBack === 'boolean') {
      dispatch(addCurrentNavigationToHistory(addCurrentNavToHistoryBack));
    }

    let navString = 'Searching in: ';
    let navStringForMacOS = navString;
    const fsRoots = [];
    if (!(validUrlFilters[SEARCH_IN_FILTER_NAME] || []).length) {
      navString += 'All Volumes';
      navStringForMacOS = navString;
    } else {
      validUrlFilters[SEARCH_IN_FILTER_NAME].forEach(item => {
        const fsRoot = getState().storageManager.fsRoots.find(r => r.fsRootID === Number(item.fs_root_id));
        navStringForMacOS += `  ${preparePathForMacOS(item.file_name, fsRoot) || item.file_name}`;
        navString += `  ${item.file_name}`;
        fsRoots.push(Number(item.fs_root_id));
      });
    }

    const searchResults = res.data || {};
    dispatch({
      type: STORAGE_MNG_DONE_SEARCH,
      roots: searchResults.data || [],
      total: searchResults.total || 0,
      validUrlFilters,
      inputSearchString: searchString,
      navString,
      navStringForMacOS,
    });
    dispatch(showAdvSearchModal(false));
    if (res.dataDescription) {
      const msg = !fsRoots.length ? res.dataDescription : res.dataDescription.filter(d => fsRoots.includes(d.FsRootID));
      dispatch(showResultWithoutPredictiveMessage(msg));
    }
};

export const applyAdvancedSearch = (history, filters, inputSearchString = '', addCurrentNavToHistoryBack) => (dispatch, getState) => {
  const {allFilters, currentView} = getState().storageManager;
  const searchFilters = getSearchFilters(filters, allFilters);
  const resFilters = {...searchFilters};
  delete resFilters.sort;
  const queryFilters = {...getFiltersQuery(searchFilters)};
  const validUrlFilters = getValidFilters(allFilters, queryFilters);

  let searchString = inputSearchString;
  let filtersKeys = Object.keys(validUrlFilters);
  let strigifiedFilters = `${qs.stringify(validUrlFilters)}`;
  const strigifiedDefaultBooleanFilters = `${qs.stringify(DefaultBooleanFilters)}`;
  if (((filtersKeys.length === DEFAULT_BOOLEAN_FILTER_NAMES.length && strigifiedFilters === strigifiedDefaultBooleanFilters) || (filtersKeys.length === (DEFAULT_BOOLEAN_FILTER_NAMES.length + 1) && filtersKeys.includes(SEARCH_IN_FILTER_NAME))) &&
    inputSearchString && !inputSearchString.match(new RegExp('^' + USE_MAX_MODIFIED_ON_FILTER_NAME + '[:] (true|false)$'))) {
    validUrlFilters[NAME_FILTER_NAME] = `*${inputSearchString}*`.replace(/^[*]{2}/, '*').replace(/[*]{2}$/, '*');
    strigifiedFilters = `${qs.stringify(validUrlFilters)}`;
  } else {
    searchString = convertFiltersToString(validUrlFilters);
  }

  const search = JSON.stringify(validUrlFilters) === JSON.stringify(DefaultBooleanFilters) ? '' :
    strigifiedFilters;
  history.replace({search});

  if (filters.isHistory !== true) {
    dispatch({type: STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD});
  }

  filtersKeys = Object.keys(validUrlFilters);
  if (!filtersKeys.length || (strigifiedFilters === strigifiedDefaultBooleanFilters && filtersKeys.length === DEFAULT_BOOLEAN_FILTER_NAMES.length && DEFAULT_BOOLEAN_FILTER_NAMES.every(k => filtersKeys.includes(k)))) {
    dispatch(showAdvSearchModal(false));
    return dispatch(getRoots(history, addCurrentNavToHistoryBack, !filtersKeys.length ? null : validUrlFilters));
  } else if (typeof addCurrentNavToHistoryBack !== 'boolean') {
    dispatch(getActiveFsRoots());
  }

  dispatch({type: STORAGE_MNG_IS_SEARCHING, searching: true});

  const size = validUrlFilters[SIZE_FILTER_NAME] ? {...validUrlFilters[SIZE_FILTER_NAME]} : undefined;
  if (size) {
    size.from = convertSizeToBytes(size.from, size.from_unit, 'from');
    size.to = convertSizeToBytes(size.to, size.to_unit, 'to');
    delete size.from_unit;
    delete  size.to_unit;
  }
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/search`,
    data: {
      filters: JSON.stringify({...validUrlFilters, [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView), size})
    }
  })
  .done(res => {
    const isValid = ajv.validate(advancedSearchValidationSchema, res);
    if (isValid) {
      const data = res.data.data.map((obj) => {
        obj.isSymlink = false;
        return obj;
      });
      const symbolinks = res.symbolinks.data.map((obj) => {
        obj.isSymlink = true;
        return obj;
      });
      res.data.data = data.concat(symbolinks);
      res.data.total += res.symbolinks.total;
      dispatch(applySearchResults(
        res,
        validUrlFilters,
        searchString,
        addCurrentNavToHistoryBack));
    } else {
      dispatch({type: STORAGE_MNG_IS_SEARCHING, searching: false});
      dispatch(showError(`Returned data is not valid.`));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_IS_SEARCHING, searching: false});
    dispatch(showError(`Could not find content.  ${getErrorMessage(error)}`));
  });
};

const removeFromTempDirectories = (fsRootID, contentID, children) => (dispatch, getState) => {
  const {tempDirectories} = getState().storageManager;
  const tempDirectoriesToRemoved = {added: [], deleted: []};
  Object.keys(tempDirectories.added).forEach(k => {
    if (k.startsWith(`r${fsRootID}-p${contentID}-`) && children.some(row => row.isDirectory && row.key === tempDirectories.added[k].key)) {
      tempDirectoriesToRemoved.added.push(k);
    }
  });
  Object.keys(tempDirectories.deleted).forEach(k => {
    if (k.startsWith(`r${fsRootID}-p${contentID}-`) && !children.some(row => row.isDirectory && row.key === tempDirectories.deleted[k].key)) {
      tempDirectoriesToRemoved.deleted.push(k);
    }
  });
  dispatch({type: STORAGE_MNG_REMOVE_FROM_TEMP_DIRS, tempDirectoriesToRemoved});
};

const changeCurrentFolder = (history, folder, addCurrentNavToHistoryBack, checkItemsWithIdOrName, successCallback) => (dispatch, getState) => {
  var preparedContent = null;
  if (!folder.isDirectory && folder.fsRootID && folder.contentID && folder.parentID && (!checkItemsWithIdOrName || !checkItemsWithIdOrName.length)) {
    dispatch(changeCurrentFolder(history, {fsRootID:folder.fsRootID, contentID: folder.parentID}, null, [folder.contentID]));
    return;
  }
  const {usedFilters, currentView, fsRoots} = getState().storageManager;
  dispatch({type: STORAGE_MNG_IS_LOADING, loading: true});
  const filters = getDefaultBooleanFiltersFromUsedFilters(usedFilters);
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/root/${folder.fsRootID}/content/${folder.contentID > 0 ? folder.contentID : 0}`,
    data: {
      with_children: true,
      [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView),
      ...filters
    }
  })
  .done(res => {
    const isValid = ajv.validate(fileSystemResponseSchema, res);

    if (isValid) {
      const {content, children, symbolinks} = res.data;
      if (typeof addCurrentNavToHistoryBack === 'boolean') {
        dispatch(addCurrentNavigationToHistory(addCurrentNavToHistoryBack));
      }
      preparedContent = content.contentID > 0 ? prepareFile(content, fsRoots) : prepareRoot(content);
      if (history && history.push && preparedContent && preparedContent.fsRootID>0 && preparedContent.contentID>0) {
        var fsRootID = preparedContent.fsRootID;
        var contentID = preparedContent.contentID;
        var childID;
        if (checkItemsWithIdOrName && checkItemsWithIdOrName[0] && (childID=parseInt(checkItemsWithIdOrName[0], 10)) && window.location.search.indexOf('child_id=') >= 0) {
          history.push(`/storage?root_id=${fsRootID}&content_id=${contentID}&child_id=${childID}`);
        } else {
          history.push(`/storage?root_id=${fsRootID}&content_id=${contentID}`);
        }
      }
      if (preparedContent && !preparedContent.isDirectory && preparedContent.fsRootID && preparedContent.contentID && preparedContent.parentID) {
        let fileForSpecRename;
        if (folder.isSpecRenameAction && folder.fsRootID === preparedContent.fsRootID && folder.contentID === preparedContent.contentID) {
          fileForSpecRename = {
            ...folder
          };
        }
        checkItemsWithIdOrName = [preparedContent.contentID];
        preparedContent.contentID = preparedContent.parentID;
        preparedContent.key = preparedContent.key.substring(0, preparedContent.key.lastIndexOf('\\'));
        delete preparedContent.parentID;
        const parentDirectory = {
          fsRootID: preparedContent.fsRootID,
          contentID: preparedContent.contentID,
          fileForSpecRename
        };
        dispatch(changeCurrentFolder(history, parentDirectory, null, checkItemsWithIdOrName));
        return;
      }
      dispatch({
        type: STORAGE_MNG_FETCH_FOLDER,
        navString: preparedContent.key,
        navStringForMacOS: preparePathForMacOS(preparedContent.key, fsRoots.find(r => r.fsRootID === preparedContent.fsRootID)),
        filters,
        content: preparedContent,
        children: [
          ...children.map((i) => {i.isSymlink = false; return i}),
          ...(symbolinks || []).map((i) => {i.isSymlink = true; return i})
        ].map((i) => prepareFile(i, fsRoots)),
        checkItemsWithIdOrName,
        skipDisappearedItemsToCheck: !!successCallback,
        fileForSpecRename: folder.fileForSpecRename,
      });
      dispatch(showResultWithoutPredictiveMessage(res.description));
      dispatch(removeFromTempDirectories(content.fsRootID, content.contentID, children));

      if (children !== undefined && children.length > 0 && checkItemsWithIdOrName !== undefined){
        const filteredMap = [
          ...children.map((i) => {i.isSymlink = false; return i}),
          ...(symbolinks || []).map((i) => {i.isSymlink = true; return i})
        ].map((i) => prepareFile(i, fsRoots));
        const skipDisappearedItemsToCheck = !!successCallback;
        const checkedItemsArray = [
          filteredMap.filter((child) => (checkItemsWithIdOrName.includes(child.contentID) ||
          checkItemsWithIdOrName.includes(child.name)) &&
                                               (!skipDisappearedItemsToCheck || child.predictiveStateID !== PREDICTIVE_STATE_DISAPPEARED)
          )
        ];

        if (checkedItemsArray !== undefined && checkedItemsArray.length>0 && checkedItemsArray[0].length > 0) {
          dispatch(getCopyToQodInfo(checkedItemsArray[0][0]));
        }
      }

    } else {
      dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
      dispatch(showError('We had trouble getting content data: Invalid response'));
    }
  })
  .then(() => getState().storageManager.checkedItems)
  .then(refreshedCheckedItems => {
    if (successCallback && checkItemsWithIdOrName && checkItemsWithIdOrName.length && refreshedCheckedItems.length) {
      dispatch(getAvailableActionsBySources(refreshedCheckedItems));
      successCallback();
    }
  })
  .then(() => getState().storageManager.fileItemForSpecRename)
  .then(fileItemForSpecRename => {
    if (fileItemForSpecRename) {
      dispatch(openDeliveryRenamePopup(FS_ACTION_TYPE__MOVE, [fileItemForSpecRename], fileItemForSpecRename.memfisWoID));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
    if (getErrorMessage(error).trim().startsWith("Bad root!")) {
      dispatch(showError(`There is NO active drive ${preparedContent && preparedContent.fsRootID ? preparedContent.fsRootID : folder.fsRootID}. Redirecting to storage roots in 10 seconds. Error: ${getErrorMessage(error)}`));
      setTimeout(function() {
        window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
        //dispatch(refreshCurrentNavigation(history));
      }, 10000);
    } else {
      dispatch(showError(`Could not get content data.  ${getErrorMessage(error)}`));
    }
  });
};

export const setCurrentFolderForNestedTable = folder => (dispatch, getState) => {
  const {usedFilters, currentView, fsRoots, history} = getState().storageManager;
  dispatch({type: STORAGE_MNG_IS_LOADING, loading: true});
  const filters = getDefaultBooleanFiltersFromUsedFilters(usedFilters);
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/root/${folder.fsRootID}/content/${folder.contentID > 0 ? folder.contentID : 0}`,
    data: {
      with_children: true,
      [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView),
      ...filters
    }
  })
  .done(res => {
    const isValid = ajv.validate(fileSystemResponseSchema, res);

    if (isValid) {
      const {content} = res.data;
      const preparedContent = content.contentID > 0 ? prepareFile(content, fsRoots) : prepareRoot(content);
      dispatch({type: STORAGE_MNG_SET_CURRENT_FOLDER_FOR_NESTED_TABLE, folder: preparedContent});
      dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
    } else {
      dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
      dispatch(showError('We had trouble getting content data: Invalid response'));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
    if (getErrorMessage(error).trim().startsWith("Bad root!")) {
      dispatch(showError(`There is NO active drive ${folder.fsRootID}. Redirecting to storage roots in 10 seconds. Error: ${getErrorMessage(error)}`));
      setTimeout(function() {
        window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
        //dispatch(refreshCurrentNavigation(history));
      }, 10000);
    } else {
      dispatch(showError(`Could not get content data.  ${getErrorMessage(error)}`));
    }
  });
};

export const closeInitialConfirmationOfDeletion = history => dispatch => {
  dispatch(changeNavigationBack(history, true));
};

const showInitialConfirmationOfDeletion = (data, addCurrentNavToHistoryBack) => dispatch => {
  if (typeof addCurrentNavToHistoryBack === 'boolean') {
    dispatch(addCurrentNavigationToHistory(addCurrentNavToHistoryBack));
  }
  const {items} = data;
  dispatch({type: STORAGE_MNG_INITIAL_CONFIRMATION_DELETION, items});
};

export const deleteWithApproval = (history, isDirHaveBarcodedContent) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const contentIDs = _.uniq(checkedItems.map(item => item.contentID).filter(item => item > 0));
  const rootID = checkedItems[0].fsRootID;
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'deleteWithApproval', loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__DELETE_WITH_APPROVAL,
      fsRootID: rootID,
      parameters: {
        ContentIDs: (contentIDs.length ? contentIDs : undefined)
      }
    })
  })
  .done(res => {
    let message;
    if (typeof res === 'string' && res.trim() && res.indexOf('Asset Deletion') >= 0) {
      try {
        const temp = /Summary:(.+)$/.exec(res);
        if (temp && temp.length > 1) {
          const tempSummary = JSON.parse(temp[1].trim());
          message = buildSuccessMessageDeleteWithApprovalFromResponse(tempSummary);
        }
      } catch(ignored) {
        if (!isDirHaveBarcodedContent) {
          message = res.replace('Asset Deletion', '').trim();
        }
      }
    }
    if (isDirHaveBarcodedContent) {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: 'deleteWithApproval', loading: false});
      if (!message) {
        const summary = compileSummaryOfDeletion(checkedItems);
        message = buildSuccessMessageDeleteWithApproval(summary);
      }
      dispatch(showSuccess(message, 'Asset Deletion'));
      dispatch(closeInitialConfirmationOfDeletion(history));
    } else {
      dispatch({type: STORAGE_MNG_TEMP_DIRS_DELETED, fsRootID: rootID, items: checkedItems});
      setTimeout(() => {
        dispatch({type: STORAGE_MNG_SET_LOADING, name: 'deleteWithApproval', loading: false});
        if (!message) {
          dispatch(showSuccess('Deleted successfully!'));
        } else {
          dispatch(showSuccess(message, 'Asset Deletion'));
        }
        dispatch(refreshCurrentNavigation(history));
      }, 5000);
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'deleteWithApproval', loading: false});
    dispatch(showError(`Could not delete with Approval.  ${getErrorMessage(error)}`));
  });
};

export const deleteAssets = (history, showConfirmAlert) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const summary = compileSummaryOfDeletion(checkedItems);
  const {barcodedDirectories, barcodedFiles} = summary;
  if (barcodedDirectories.total || barcodedFiles.total) {
    const items = checkedItems.map(item => {
      const newItem = {...item};
      delete newItem.parents;
      delete newItem.parentsPredictiveStates;
      delete newItem.allParents;
      return newItem;
    });
    dispatch(showInitialConfirmationOfDeletion({items}, true));
  } else {
    showConfirmAlert(() => {
      dispatch(deleteWithApproval(history, false));
    }, checkedItems[0].name);
  }
};

export const changeNavigationInput = value => dispatch => {
  dispatch({type: STORAGE_MNG_TOOLBAR_CHANGE_INPUT_NAV, value});
};

export const applyNavigation = (history, value, callback, checkItemWithID) => (dispatch, getState) => {
  const {usedFilters, displayCategory, appliedNavigationString, currentView, fsRoots} = getState().storageManager;

  if (value.toUpperCase().startsWith('SEARCHING IN: ') || value.toUpperCase().startsWith('LIST OF CONTENT IDS ')) {
    return;
  } else if (value.toUpperCase() === 'ALL VOLUMES') {
    if (displayCategory !== DISPLAY_CATEGORY_ROOTS) {
      dispatch(addCurrentNavigationToHistory(true));
    }
    dispatch(getRoots(history)).always(() => {
      if (callback) {
        callback();
      }
    });
    return;
  }

  dispatch({type: STORAGE_MNG_IS_LOADING, loading: true});
  const filters = getDefaultBooleanFiltersFromUsedFilters(usedFilters);
  value = convertPathToWindows(value, fsRoots);
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/nav`,
    data: {
      path: value,
      [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView),
      ...filters
    }
  })
  .done(res => {
    const isValid = ajv.validate(fileSystemResponseSchema, res);
    if (isValid) {
      const {content, children, symbolinks} = res.data;
      const preparedContent = content.contentID > 0 ? prepareFile(content, fsRoots) : prepareRoot(content);
      if (history && history.push && preparedContent && preparedContent.fsRootID>0 && preparedContent.contentID>0) {
        var fsRootID = preparedContent.fsRootID;
        var contentID = preparedContent.contentID;
        history.push(`/storage?root_id=${fsRootID}&content_id=${contentID}`);
      }
      if (displayCategory !== DISPLAY_CATEGORY_FOLDER || appliedNavigationString !== preparedContent.key) {
        dispatch(addCurrentNavigationToHistory(true));
      }

      dispatch({
        type: STORAGE_MNG_FETCH_FOLDER,
        navString: preparedContent.key,
        navStringForMacOS: preparePathForMacOS(preparedContent.key, fsRoots.find(r => r.fsRootID === preparedContent.fsRootID)),
        filters,
        content: preparedContent,
        children: [
          ...children.map((i) => {i.isSymlink = false; return i}),
          ...(symbolinks || []).map((i) => {i.isSymlink = true; return i})
        ].map((i) => prepareFile(i, fsRoots)),
        checkItemsWithIdOrName: (checkItemWithID ? [checkItemWithID] : undefined),
      });

      dispatch(showResultWithoutPredictiveMessage(res.description));

      if (callback) {
        callback();
      }
    } else {
      dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
      dispatch(showError('We had trouble getting content data: Invalid response'));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_IS_LOADING, loading: false});
    dispatch(showError(`The item "${value}" could not be found.  ${getErrorMessage(error)}`));
  });
};

export const getAvailableActionsToWo = (items) => dispatch => {
  if (!items || items.length === 0) {
    dispatch({type: STORAGE_MNG_SET_ENABLE_ACTION_TO_WO});
  }
}

export const getAvailableActionsBySources = (items, fsActionType, callback) => dispatch => {
  if ((!fsActionType && (!items || items.length <= 1 || items.some(item => item.fsRootID !== items[0].fsRootID))) ||
      (fsActionType && !(items || []).length)) {
    dispatch({
      type: STORAGE_MNG_SET_AVAILABLE_ACTIONS_BY_SOURCES,
      data: {}
    });
    return;
  }

  const loadingName = 'getAvailableActionsBySources'
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name: loadingName,
    loading: true
  });
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/available_actions_by_source`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      fsRootID: items[0].fsRootID,
      ContentIDs: items.map(item => item.contentID),
      fsActionType
    })
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name: loadingName,
      loading: false
    });
    dispatch({
      type: STORAGE_MNG_SET_AVAILABLE_ACTIONS_BY_SOURCES,
      data: {
        ...res,
        ...(fsActionType ? {_forItem: {fsRootID: items[0].fsRootID, contentID: items[0].contentID}} : {})
      }
    });
    callback && callback(res);
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name: loadingName,
      loading: false
    });
    dispatch(showError(`Could not get available actions by sources.  ${getErrorMessage(error)}`));
  });
};

export const changeCheckedItems = (items, isForAutoDownloads) => dispatch => {
  dispatch({type: STORAGE_MNG_CHANGE_CHECKED_ITEMS, items});
  dispatch(getAvailableActionsBySources(items));
  dispatch(getAvailableActionsToWo(items));
  if (!isForAutoDownloads) {
    dispatch({type: STORAGE_MNG_RESET_COPY_MODAL_DATA});
    dispatch({type: STORAGE_MNG_RESET_COPY_TO_QOD_MODAL_DATA});
    if (items.length === 1 && !items[0].isSymlink) {
      dispatch(getCopyToQodInfo(items[0]));
      if (!items[0].isDirectory) {
        dispatch(getCopyData(items[0], null, true));
      }
    }
  }
};

export const changeOpenedFolders = folders => dispatch => {
  dispatch({type: STORAGE_MNG_CHANGE_OPENED_FOLDERS, folders});
};

export const changeOpenedFoldersInModal = folders => dispatch => {
  dispatch({type: STORAGE_MNG_CHANGE_OPENED_FOLDERS_IN_MODAL, folders});
};

export const setFoldersInModal = folders => ({type: STORAGE_MNG_SET_FOLDERS_IN_MODAL, folders});

export const getFsEntityListFromAction = (history, action, addCurrentNavToHistoryBack) => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIONS_CONTENTS_IS_LOADING, loading: true});
  const {fsRootID, actionID} = action;
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/${actionID}/contents`,
    data: {
      fsRootID
    }
  })
  .done(res => {
    if (typeof addCurrentNavToHistoryBack === 'boolean') {
      dispatch(addCurrentNavigationToHistory(addCurrentNavToHistoryBack));
    }
    dispatch({
      type: STORAGE_MNG_ACTIONS_CONTENTS,
      action,
      contents: res,
      navString: `List of Content IDs in action # ${actionID}`
    });
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_ACTIONS_CONTENTS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get FS entities for action.  ${getErrorMessage(error)}`));
  });
};

const applyNavigationItem = (history, navItem, addCurrentNavToHistoryBack) => (dispatch, getState) => {
  if (navItem.displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS) {
    return dispatch(applyAdvancedSearch(history, navItem.data, '', addCurrentNavToHistoryBack));
  } else if (navItem.displayCategory === DISPLAY_CATEGORY_FOLDER) {
    return dispatch(changeCurrentFolder(history, navItem.data, addCurrentNavToHistoryBack));
  } else if (navItem.displayCategory === DISPLAY_CATEGORY_ACTION_CONTENTS) {
    return dispatch(getFsEntityListFromAction(history, navItem.data, addCurrentNavToHistoryBack));
  } else if (navItem.displayCategory === DISPLAY_CATEGORY_INIT_CONFIRM_DELETION) {
    return dispatch(showInitialConfirmationOfDeletion(navItem.data, addCurrentNavToHistoryBack));
  } else {
    return dispatch(getRoots(history, addCurrentNavToHistoryBack));
  }
};

export const changeNavigationBack = (history, dontAddCurrentNavToAnyHistory) => (dispatch, getState) => {
  const {navigationHistory} = getState().storageManager;
  const lastNavItem = navigationHistory.pop();
  if (lastNavItem) {
    dispatch({type: STORAGE_MNG_CHANGE_NAVIGATION_HISTORY, navigationHistory});
    dispatch(applyNavigationItem(history, lastNavItem, dontAddCurrentNavToAnyHistory ? undefined : false));
  }
};

export const changeNavigationForward = history => (dispatch, getState) => {
  const {navigationHistoryForward} = getState().storageManager;
  const lastNavItem = navigationHistoryForward.pop();
  if (lastNavItem) {
    dispatch({type: STORAGE_MNG_CHANGE_NAVIGATION_HISTORY_FORWARD, navigationHistoryForward});
    dispatch(applyNavigationItem(history, lastNavItem, true));
  }
};

export const changeNavigationUp = (history, folder) => (dispatch, getState) => {
  const {currentFolder} = getState().storageManager;
  dispatch({type: STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD});
  const {parentID, fsRootID, contentID} = (folder || currentFolder);
  if (!parentID) {
    dispatch(getRoots(history, true, null, fsRootID));
  } else {
    dispatch(changeCurrentFolder(history, {fsRootID, contentID: parentID}, true, [contentID]));
  }
};

export const changeNavigationIntoCurrent = (history, folder) => dispatch => {
  dispatch(changeCurrentFolder(history, folder, true)).then(() => {
    dispatch({type: STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD});
  });
};

export const changeNavigationInto = (history, folder, isCheckedFolder) => (dispatch, getState) => {
  const {usedFilters, currentView, fsRoots} = getState().storageManager;
  dispatch({type: STORAGE_MNG_NAV_INTO_IS_LOADING, loading: true});
  const filters = getDefaultBooleanFiltersFromUsedFilters(usedFilters);
  const {fsRootID, contentID, predictiveStateID} = folder;
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/root/${fsRootID}/content/${contentID > 0 ? contentID : 0}`,
    data: {
      with_children: true,
      [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView),
      ...filters
    }
  })
  .done(res => {
    const {children, symbolinks} = (res.data || {});
    const parents = [...folder.parents || [], contentID];
    const parentsPredictiveStates = [...folder.parentsPredictiveStates || [], predictiveStateID || 0];
    const allParents = [...folder.allParents || [], {contentID, predictiveStateID}];
    dispatch({
      type: STORAGE_MNG_NAV_INTO_FETCH_FOLDER,
      folder,
      isCheckedFolder,
      children: [
        ...(children || []).map((i) => {i.isSymlink = false; return i;}),
        ...(symbolinks || []).map((i) => {i.isSymlink = true; return i;})
      ].map((i) => prepareFile(i, fsRoots)).map(item => ({...item, parents, parentsPredictiveStates, allParents})),
      allParents
    });

    dispatch(showResultWithoutPredictiveMessage(res.description));
  })
  .then(() => getState().storageManager.checkedItems)
  .then(checkedItems => dispatch(getAvailableActionsBySources(checkedItems)))
  .fail(error => {
    dispatch({type: STORAGE_MNG_NAV_INTO_IS_LOADING, loading: false});
    if (getErrorMessage(error).trim().startsWith("Bad root!")) {
      dispatch(showError(`There is NO active drive ${fsRootID}. Redirecting to storage roots in 10 seconds. Error: ${getErrorMessage(error)}`));
      setTimeout(function() {
        window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
        //dispatch(refreshCurrentNavigation(history));
      }, 10000);
    } else {
      dispatch(showError(`Could not get content data.  ${getErrorMessage(error)}`));
    }
  });
};

export const getFolderChildren = (folder, segments, callback, {onSelectFolder, newChild, newFolderName, newFolderCallback, isCheckSubdirectories} = {}) => (dispatch, getState) => {
  const {usedFilters, currentView, fsRoots, history} = getState().storageManager;
  if (newFolderName) {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'afterMkDir', loading: true});
  }
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  const filters = getDefaultBooleanFiltersFromUsedFilters(usedFilters);
  const filtersToRequest = newFolderName ? {
    with_children: true,
    use_predictive: true,
    check_subdirectories: true,
  } : {
    with_children: true,
    [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView),
    check_subdirectories: isCheckSubdirectories,
    ...filters
  };
  const {fsRootID, contentID, predictiveStateID} = folder;
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/root/${fsRootID}/content/${contentID > 0 ? contentID : 0}`,
    data: filtersToRequest
  })
  .done(res => {
    const {children} = (res.data || {});
    const parents = [...folder.parents || [], contentID];
    const parentsPredictiveStates = [...folder.parentsPredictiveStates || [], predictiveStateID || 0];
    const allParents = [...folder.allParents || [], {contentID, predictiveStateID}];
    const preparedChildren = (children || []).map(item => prepareFile(item, fsRoots)).map(item => ({...item, parents, parentsPredictiveStates, allParents}));
    if (newChild) {
      preparedChildren.push({...newChild, parents, parentsPredictiveStates, allParents});
    }
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'afterMkDir', loading: false});
    dispatch({
      type: STORAGE_MNG_FOLDER_CHILDREN_RECEIVE,
      folder,
      children: preparedChildren,
      allParents
    });

    dispatch(showResultWithoutPredictiveMessage(res.description));

    dispatch(removeFromTempDirectories(fsRootID, contentID, children));

    if (callback && segments && segments.length) {
      const segment = segments.shift();
      const child = preparedChildren.find(item => item.name === segment);
      if (child) {
        callback(child, segments.length === 0);
        dispatch(getFolderChildren(child, segments, segments.length ? callback : () => {}));
      }
    } else if (callback) {
      callback();
    }

    try {
      if (onSelectFolder) {
        onSelectFolder({...folder, children: preparedChildren});
      }
    } catch {}

    try {
      if (newFolderName && newFolderCallback) {
        const createdFolder = preparedChildren.find((child) => child.name === newFolderName);
        if (createdFolder) {
          newFolderCallback(createdFolder);
        }
      }
    } catch {}
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'afterMkDir', loading: false});
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    if (getErrorMessage(error).trim().startsWith("Bad root!")) {
      dispatch(showError(`There is NO active drive ${fsRootID}. Redirecting to storage roots in 10 seconds. Error: ${getErrorMessage(error)}`));
      setTimeout(function() {
        window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
        //dispatch(refreshCurrentNavigation(history));
      }, 10000);
    } else {
      dispatch(showError(`Could not get content data. Error: ${getErrorMessage(error)}`));
    }
  });
};

export const setModalState = state => ({
  type: STORAGE_MNG_SET_MODAL_STEP,
  state
});

export const showMoveModal = (isShow, allowedActions) => ({
  type: STORAGE_MNG_MOVE_SHOW_MODAL,
  isShow,
  allowedActions
});

export const showScheduledPauseModal = (isShow, selectedAction) => ({
  type: STORAGE_MNG_SCHEDULED_PAUSE_SHOW_MODAL,
  isShow,
  selectedAction
});

export const showRenameModal = (isShow, renameModalData) => ({
  type: STORAGE_MNG_RENAME_SHOW_MODAL,
  isShow,
  renameModalData
});

export const showSetContentPermissionsModal = (isShow) => ({
  type: STORAGE_MNG_CONTENT_PERMISSIONS_SHOW_MODAL,
  isShow
});

export const getActions = (history, usedFilters, source, tablePaging, tableSort) => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIONS_IS_LOADING, loading: true});
  //if (source) {
    if (!tablePaging) {
      tablePaging = {
        start: 0,
        limit: ACTION_DEFAULT_PAGE_SIZE
      };
    }
    if (!tableSort) {
      tableSort = {
        ...ACTION_DEFAULT_SORT
      };
    }
  //}
  const preparedFilters = {...usedFilters, ...tableSort};
  if (preparedFilters[ACTION_FILTER_CONTENT_ID]) {
    preparedFilters[ACTION_FILTER_CONTENT_ID] = preparedFilters[ACTION_FILTER_CONTENT_ID].split(',')
      .map(id => parseInt(id, 10)).filter(id => !isNaN(id) && id > 0);
  } else {
    delete preparedFilters[ACTION_FILTER_CONTENT_ID];
  }
  const atFirstTime = !(source && source !== ACTION__SOURCE_OF_REQUEST__ACTIONSBAR);
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions`,
    data: {
      filters: JSON.stringify(preparedFilters),
      only_filters: atFirstTime ? true : undefined,
      ...tablePaging
    }
  })
  .done(res => {
    const {actions: actionsPagingData, filters} = res;
    const actionsFilters = [...ACTIONS_FILTERS];
    const filtersMap = {
      [ACTION_FILTER_STATUS]: 'statuses',
      [ACTION_FILTER_TYPE_ID]: 'types',
      [ACTION_FILTER_FS_ROOT_ID]: 'fs_roots',
      [ACTION_FILTER_REQUESTOR_ID]: 'requestors'
    };
    Object.keys(filtersMap).forEach(filterName => {
      actionsFilters.find(f => f.name === filterName).values = filters[filtersMap[filterName]] || [];
    });
    const actionsRequestorsMap = {};
    (filters.requestors || []).forEach(r => actionsRequestorsMap[r.value] = r.name);

    dispatch({
      type: STORAGE_MNG_ACTIONS_SHOW,
      source,
      total: source ? ((actionsPagingData || {}).total || 0) : null,
      data: source ? ((actionsPagingData || {}).data || []) : null,
      actionsFilters,
      actionsRequestorsMap,
      usedFilters
    });
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_ACTIONS_IS_LOADING, loading: false});
    if (source) {
      const actionId = usedFilters[ACTION_FILTER_ACTION_ID];
      dispatch(showError(`Could not get action${actionId ? ` #${actionId}` : 's'}.  ${getErrorMessage(error)}`));
    }
  });
};

export const getChildrenOfAction = actionId => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/${actionId}/children`
  })
  .done(children => {
    dispatch({type: STORAGE_MNG_ACTIONS_CHILDREN, children, actionId});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_ACTIONS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get children of action #${actionId}.  ${getErrorMessage(error)}`));
  });
};

export const getParentAction = (actionId, parentActionId) => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/${parentActionId}`
  })
  .done(res => {
    const {action, children} = res;
    dispatch({type: STORAGE_MNG_ACTIONS_PARENT, action, children});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_ACTIONS_IS_LOADING, loading: false});
    dispatch(showError((actionId ? `Could not get parent of action #${actionId}` : `Could not get action #${parentActionId}`) + `.  ${getErrorMessage(error)}`));
  });
};

export const initializeContent = (history, urlFilters) => (dispatch, getState) => {
  const actionId = parseInt(urlFilters[ACTION_FILTER_ACTION_ID], 10);
  const ownActions = parseInt(urlFilters[ACTION_URL_FILTER_OWN_ACTIONS], 10);
  const isValidActionId = !isNaN(actionId) && actionId > 0;
  const filterPopupFlag = parseInt(urlFilters[ACTION_URL_FILTER_POPUP_FLAG], 10);
  const isShownPopup = !isNaN(filterPopupFlag) && filterPopupFlag > 0;
  const isValidOwnActions = !isNaN(ownActions) && ownActions > 0;
  const actionRunnerId = parseInt(urlFilters[ACTION_URL_FILTER_RUNNER_ID], 10);
  const actionTypeId = urlFilters[ACTION_URL_FILTER_TYPE_ID];
  const actionDaysBack = parseInt(urlFilters[ACTION_URL_FILTER_DAYS_BACK], 10);
  const isValidActionRunnerId = !isNaN(actionRunnerId) && actionRunnerId > 0 && actionTypeId != null;
  const isValidActionDaysBack = !isNaN(actionDaysBack) && actionDaysBack > 0;

  const actionStatus = urlFilters[ACTION_FILTER_STATUS];
  const actionCreatedOn = urlFilters[ACTION_FILTER_CREATED_ON];
  const isValidAdditionalFilters = actionStatus != null || actionCreatedOn != null || actionTypeId != null;

  if (isValidActionId || isValidOwnActions || isValidActionRunnerId || isValidAdditionalFilters) {
    let actionsUrlFilters = {};
    if (isValidActionId) {
      actionsUrlFilters[ACTION_FILTER_ACTION_ID] = actionId;
      if (isShownPopup) {
        actionsUrlFilters[ACTION_URL_FILTER_POPUP_FLAG] = filterPopupFlag;
      }
    } else if (isValidActionRunnerId) {
      actionsUrlFilters[ACTION_FILTER_RUNNER_ID] = actionRunnerId;
      actionsUrlFilters[ACTION_FILTER_TYPE_ID] = actionTypeId != null && actionTypeId.split(',');
      if (isValidActionDaysBack) {
        actionsUrlFilters[ACTION_FILTER_CREATED_ON] = {
          start: moment().subtract(actionDaysBack, 'days').toDate()
        };
      }
    } else if (isValidOwnActions) {
      actionsUrlFilters[ACTION_FILTER_REQUESTOR_ID] = [ownActions];
      actionsUrlFilters[ACTION_FILTER_CREATED_ON] = {
        start: moment().subtract(2, 'days').toDate()
      };
    }

    if (actionStatus) {
      actionsUrlFilters[ACTION_FILTER_STATUS] = actionStatus.split(',');
    }
    if (actionTypeId) {
      actionsUrlFilters[ACTION_FILTER_TYPE_ID] = actionTypeId.split(',');
    }
    if (actionCreatedOn) {
      actionsUrlFilters[ACTION_FILTER_CREATED_ON] = {
        start: moment(actionCreatedOn).toDate()
      };
    }
    if (isValidActionDaysBack) {
      actionsUrlFilters[ACTION_FILTER_CREATED_ON] = {
        start: moment().subtract(actionDaysBack, 'days').toDate()
      };
    }

    //[ACTION_FILTER_STATUS]: [ACTION_STATUS_ACTIVE],

    dispatch(getActions(history, actionsUrlFilters, ACTION__SOURCE_OF_REQUEST__URL))
      .then(() => getState().storageManager.actions)
      .then(
        actions => {
          if (actions && actions.length===1 && isValidActionId && parseInt(urlFilters[ACTION_URL_FILTER_POPUP_FLAG], 10)>0) {
            //if (actionId==actions[0].actionID) {
              dispatch(showActionProgress(actions[0]));
            //}
          } else {
          /*if (actions && actions.length &&
            actions[0].parameters && actions[0].parameters.find(p => p.type === 'LIST_OF_CONTENT_IDS')) {
            dispatch(getFsEntityListFromAction(history, actions[0], true))
              .then(
                () => {},
                err => dispatch(getRoots(history))
              );
          } else {*/
            //dispatch(getRoots(null));
            if (isValidActionId && (!actions || !actions.length)) {
              dispatch(showError(`Not found action #${actionId}`));
            }
          /*}*/
          }
        },
        //err => dispatch(getRoots(history))
      );
    //return;
  } else {
    dispatch(getActions());// get only filters for actions
  }

  const {allFilters, usedFilters} = getState().storageManager;
  const navFromUrl = urlFilters['nav'];
  if (navFromUrl) {
    dispatch(getActiveFsRoots()).always(() => {
      dispatch(changeNavigationInput(navFromUrl));
      dispatch(applyNavigation(history, navFromUrl));
    });
    return;
  }
  const fsRootID = parseInt(urlFilters.root_id, 10);
  const childID = parseInt(urlFilters.child_id, 10);
  if (!isNaN(fsRootID)) {
    const contentID = parseInt(urlFilters.content_id, 10);
    const isValidContentId = !isNaN(contentID);
    dispatch(getActiveFsRoots()).always(() => {
      if (isNaN(childID) || !isValidContentId) {
        const isSpecRenameAction = isValidContentId && urlFilters.action === 'spec_rename';
        dispatch(changeCurrentFolder(history, {fsRootID, contentID: (isValidContentId ? contentID : 0), isSpecRenameAction}));
      } else {
        dispatch(changeCurrentFolder(history, {fsRootID, contentID}, null, [childID]));
      }
    })
  } else {
    const validUrlFilters = getValidFilters(allFilters, urlFilters);
    DEFAULT_BOOLEAN_FILTER_NAMES.forEach(n => {
      if (typeof validUrlFilters[n] !== 'boolean') {
        validUrlFilters[n] = usedFilters[n];
      }
    });
    dispatch(applyAdvancedSearch(history, validUrlFilters));
  }
};

export const toggleShowRenameAmazonFile = isShow => ({type: STORAGE_MNG_TOGGLE_SHOW_RENAME_AMAZON_FILE_MODAL, isShow});

export const closeLinkWOAlreadyLinkedInBurbankModal = () => ({type: STORAGE_MNG_LINK_IN_BURBANK_SHOW_MODAL});

const showLinkWOAlreadyLinkedInBurbankModal = item => ({type: STORAGE_MNG_LINK_IN_BURBANK_SHOW_MODAL, item});

export const showAvailableManualMemfisWOsModal = (isShow, ajaxParams, onCloseParams) => dispatch => {
  if (isShow) {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: true});
    return $.ajax({
      method: 'GET',
      url: `${config.apiUrl}/v1/file_system/all_available_manual_memfis_wo`,
      data: ajaxParams
    })
    .done(res => {
      if (res && res.length) {
        dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_SHOW_MODAL, isShow, data: res, singleLinking: (ajaxParams || {}).single_linking});
      } else {
        dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
        dispatch(showError('No available "manual" WO#-s'));
      }
    })
    .fail(error => {
      dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
      dispatch(showError(`Could not get available "manual" WO#-s.  ${getErrorMessage(error)}`));
    });
  } else {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_SHOW_MODAL, isShow, ...onCloseParams});
  }
};

const checkNewWOById = (woid) => (dispatch) => {
  dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/check_new_wo_by_id`,
    data: {woid:woid}
  }).done(res => {
    if (res && res.wo_memfis) {
      dispatch({type: STORAGE_MNG_LINK_TO_WO_NEW_WO_BY_FNAME, wo: res.wo_memfis});
      dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
    } else {
      dispatch(showInfo('Please wait while a new WO is created', null, 10500));
      setTimeout((dispatch, woid) => {
        dispatch(checkNewWOById(woid));
      }, 10000, dispatch, woid);
    }
  }).fail(error => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
    dispatch(showError(`Could not get available new WO.  ${getErrorMessage(error)}`));
  });
}

export const createArtworkWorkOrderByFName = (fsRootID, contentID, artworkWoCustomSeries, artworkWoConceptName) => (dispatch, getState) => {
  const {clientByFNameForWO} = getState().storageManager;

  dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: true});
  const queryParams = {
    fs_root_id: fsRootID,
    content_id: contentID,
    client_name: clientByFNameForWO,
    custom_series: artworkWoCustomSeries,
    concept_name: artworkWoConceptName
  };
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/get_new_wo_by_fname`,
    data: queryParams
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
    if (res && res.woid) {
        dispatch(checkNewWOById(res.woid));
    } else {
      dispatch(showError('New Artwork WO not available'));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
    dispatch(showError(`Failed create new Artwork work order.  ${getErrorMessage(error)}`));
  });
}

export const setNewWOByFName = wo => dispatch => {
  dispatch({type: STORAGE_MNG_LINK_TO_WO_NEW_WO_BY_FNAME, wo: wo});
}

export const checkCreateNewWOByFName = item => dispatch => {
  dispatch({type: STORAGE_MNG_LINK_TO_WO_LOAD_CLIENTS_BY_FNAME, client: null});
  dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: true});
  const ajaxParams = {fs_root_id: item.fsRootID, content_id: item.contentID}
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/get_available_clients_by_fname`,
    data: ajaxParams
  })
  .done(res => {
    if (res && res.client) {
      dispatch({type: STORAGE_MNG_LINK_TO_WO_LOAD_CLIENTS_BY_FNAME, client: res.client});
    } else {
      dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING, loading: false});
    dispatch(showError(`Could not get available clients by fname.  ${getErrorMessage(error)}`));
  });
}

/*#region Final Destination */
const getAssetFinalDestinationBase = (woMemfisId, path, preferredRootId, preferred_project_type) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/assets/final_destination`,
    data: {
      wo_memfis: woMemfisId,
      file_name: path,
      preferred_root_id: preferredRootId,
      preferred_project_type
    }
  });
};

const getAssetFinalDestination2 = (woMemfis, rootID) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/wo_root_content`,
    data: {
      root_id: rootID,
      wo_memfis: woMemfis,
    }
  });
};

const getAssetFinalDestAsWOSymlinkRootContent = (wo_memfis, source_root_id, dest_root_id, source_file_name) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/add_to_wo_data`,
    data: {
      source_root_id,
      wo_memfis,
      dest_root_id,
      source_file_name,
    }
  });
};
/*#endregion */

export const onSubmitDestination = (folder) => ({
  type: STORAGE_MNG_LKFS_CHOOSE_DESTINATION,
  folder,
  isValid: true,
});

export const sendLKFSRequest = (data) => (dispatch) => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(data),
  }).done((res) => {
    //dispatch(showSuccess(`Successfully sent LKFS request.`));
    const {ActionID} = res || {};
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: 'LKFS'
    }));
  }).fail((error) => {
    dispatch(showError(`Could not send LKFS request.  ${getErrorMessage(error)}`));
  });
};

export const switchCaptionsModal = (dispatch, isShow, checkedItems) => {
  dispatch({
    type: STORAGE_MNG_CAPTIONS_SWITCH_MODAL,
    isShow,
  });
}

export const switchLKFSModal = (dispatch, isShow, checkedItems) => {
  if (isShow) {
    const uniqueMemfisWoIDs = _.uniq(checkedItems.map(i => i.memfisWoID).filter(i => i));
    const uniquePaths = _.uniq(checkedItems.map(i => i.fullPath).filter(i => i));
    const memfisWoID = uniqueMemfisWoIDs[0];
    if (uniqueMemfisWoIDs.length === 1) {
      dispatch({
        type: STORAGE_MNG_LKFS_SWITCH_MODAL,
        isShow,
      });

      getFileFrameRate(checkedItems[0].fsRootID, checkedItems[0].contentID, memfisWoID, dispatch);

      getLKFSAutoSettingsForMemfisWO(memfisWoID, uniquePaths[0], checkedItems[0].fsRootID, (autoAMB, destination, fsRootID) => {
        dispatch({
          type: STORAGE_MNG_LKFS_SET_AUTO_WO_STATUS,
          memfisWoID,
        });
        dispatch({
          type: STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION,
          folder: {
            key: destination,
            fsRootID,
          }
        });
        dispatch({
          type: STORAGE_MNG_LKFS_AUTO_UPDATE_AMB,
          numericAMB: autoAMB,
        });
      });
    } else /* if 0 */ {
      dispatch({
        type: STORAGE_MNG_LKFS_SWITCH_MODAL,
        isShow,
      });
    }
  } else {
    dispatch({
      type: STORAGE_MNG_LKFS_SWITCH_MODAL,
      isShow,
    });
  }
};

export const getLKFSAutoSettingsForMemfisWO = (memfisWO, path, fsRootID, onSuccess) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/amb_settings?wo_memfis_id=${memfisWO}&fs_root_id=${fsRootID}`
  }).done((statusBit) => {
    getAssetFinalDestinationBase(memfisWO, path).done((pathInfo) => {
      const isValid = ajv.validate({
        "type": "object",
        "properties": {
          "final_destination": {
            "type": "string"
          },
          "root_id": {
            "type": "integer"
          }
        },
        "required": ["final_destination", "root_id"]
      }, pathInfo);
      if (isValid) {
        onSuccess(statusBit, pathInfo.final_destination, pathInfo.root_id)
      } else {
        console.error('Returned data is not valid');
      }
    });
  }).fail((error) => {
    console.log(error)
  })
};

export const showLinkToMemfisWoModal = item => dispatch => {
  const flags = item.office_flags || item.officeFlag || 0;
  const isNewYorkOffice = (flags & config.officeFlag.NEW_YORK) > 0;
  dispatch(checkCreateNewWOByFName(item));
  if (isNewYorkOffice) {
    dispatch(showLinkWOAlreadyLinkedInBurbankModal(item));
  } else {
    dispatch(showAvailableManualMemfisWOsModal(true));
  }
};

export const showLinkToMemfisModalForAutoDownloads = item => dispatch => {
  if (item) {
    dispatch(changeCheckedItems([item], true));
    dispatch(showLinkToMemfisWoModal(item));
  } else {
    dispatch(changeCheckedItems([], true));
    dispatch(closeLinkWOAlreadyLinkedInBurbankModal());
  }
};

export const showIngestModal = (operationTypes, workOrder, fromLinkToWo) => (dispatch, getState) => {
  if (operationTypes) {
    const {EnableXxHash, XxHashOn} = getState().storageManager;
    for (const officeKey in operationTypes) {
      if (operationTypes[officeKey].steps) {
        operationTypes[officeKey].steps.forEach(ingestStep => {
          // for XxHash Checksum
          if (ingestStep.id === 32768) {
            ingestStep.editable = EnableXxHash;
            ingestStep.value = XxHashOn;
          }
        });
      }
    }
  }
  dispatch({type: STORAGE_MNG_INGEST_SHOW_MODAL, operationTypes, workOrder, fromLinkToWo});
}

export const clearFrameRateData = () => ({
  type: STORAGE_MNG_CLEAR_FRAME_RATE
});

export const getAndShowIngestOperationTypes = (contentID, fsRootID) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  dispatch({type: STORAGE_MNG_INGEST_OPERATION_TYPES_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/ingest_operation_types/${checkedItems[0].memfisWoID}`,
    data: { content_id: contentID, fs_root_id: fsRootID }
  })
  .done(res => {
    const {operation_types, frame_rates, work_order} = res;
    dispatch(clearFrameRateData());
    if (frame_rates) {
      dispatch({type: STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE, data: frame_rates || {}});
    }
    dispatch(showIngestModal(operation_types || {}, {...work_order, woMemfisId: checkedItems[0].memfisWoID}, true));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_INGEST_OPERATION_TYPES_IS_LOADING, loading: false});
    dispatch(showError(`Could not get the ingest operation types.  ${getErrorMessage(error)}`));
  });
};

export const sendIngestOperationTypes = (ingestData, memfisWoID) => dispatch => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/ingest_operation_types/${memfisWoID}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(ingestData)
  })
  .done(res => {
    const {ActionID} = res || {};
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: 'Ingest'
    }));
  })
  .fail(error => {
    dispatch(showError(`We had trouble beginning ingest process.  ${getErrorMessage(error)}`));
  });
};

export const setIngestOperationTypes = ingestData => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {memfisWoID} = checkedItems[0];
  dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING, loading: true});
  dispatch(sendIngestOperationTypes(ingestData, memfisWoID))
  .done(() => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED, memfisWoID, needToIngest: false});
    dispatch(clearFrameRateData());
    dispatch(showIngestModal());
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING, loading: false});
  });
};

export const unlinkToMemfisWO = (keep_barcode, fsRootIDs) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {memfisWoID, fsRootID} = checkedItems[0];
  dispatch(showUnlinkModal(false));
  dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/unlink_memfis_wo/${memfisWoID}`,
    data: {
      keep_barcode,
      fs_root_id: fsRootID,
      fs_root_ids: fsRootIDs
    }
  })
  .done((res) => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED, memfisWoID: null, needToIngest: false});
    dispatch(showSuccessPopup({
      actionId: res.ActionID,
      actionType: 'Unlink',
      //message: `The folder "${checkedItems[0].key}" has been successfully unlink is started from ${memfisWoID}`,
      isProgress: true
    }));

  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING, loading: false});
    dispatch(showError(`Could not unlink WO#.  ${getErrorMessage(error)}`));
  });
};

export const linkToMemfisWO = params => (dispatch, getState) => {
  const {requestedPage, memfisWO, fsRootID2, destinationPath, isBeginIngest, sendCaptionsToQod, successCallback, steps} = params;
  const {checkedItems, availableManualMemfisWOs, isSingleLinking} = getState().storageManager;
  dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__LINK_TO_MEMFIS_WO,
      fsRootID: checkedItems[0].fsRootID,
      fsRootID2,
      parameters: {
        DESTINATION: destinationPath,
        SINGLE_ROOT_ACTION: isSingleLinking,
        ContentIDs: ['' + checkedItems[0].contentID],
        woMemfisId: memfisWO.woMemfisId,
        SEND_CAPTIONS_TO_QOD: sendCaptionsToQod
      },
      requested_page: requestedPage
    })
  })
  .done(res => {
    const memfisWoID = String(memfisWO.woMemfisId).match(/^[0-9]+$/) ? `WO${memfisWO.woMemfisId}` : memfisWO.woMemfisId;
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED, memfisWoID, needToIngest: true});
    successCallback && successCallback(checkedItems[0], memfisWoID, destinationPath);
    const workOrder = availableManualMemfisWOs.find(i => i.woMemfisId === memfisWoID) || {woMemfisId: memfisWoID};
    dispatch(showAvailableManualMemfisWOsModal(false));
    if (isBeginIngest) {
      if (res && res.frame_rates) {
        dispatch({type: STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE, data: res.frame_rates || {}});
      }
      dispatch(showIngestModal(res.op_types_by_office || {}, workOrder, true));

      if (res.asset_id && steps && steps.length > 0) {
        dispatch(sendWoSteps(res.asset_id, steps, memfisWO.woMemfisId, null));
      }
    } else {
      dispatch(showSuccess('Content has been successfully linked to WO#.'));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING, loading: false});
    const message = getErrorMessage(error);
    const reason = message.includes('is already linked') ? 'The item is already linked.' : message;
    dispatch(showError(`We had trouble linking this item to a work order.  ${reason}`));
  });
};

export const getAssetFinalDestination = (woMemfisId, preferredRootId, projectType) => (dispatch, getState) => {
  const {checkedItems, fsRoots} = getState().storageManager;
  const {key} = checkedItems[0];
  dispatch({type: STORAGE_MNG_FINAL_DESTINATION_IS_LOADING, loading: true});
  return getAssetFinalDestinationBase(woMemfisId, key, preferredRootId, projectType)
  .done(res => {
    if (res && res.wo_steps) {
      dispatch({type: STORAGE_MNG_WO_STEPS, data: res.wo_steps});
      //dispatch(showWoStepsModal(true));
    } else {
      dispatch(setWoStepsEmpty(true));
    }
    if (res && res.final_destination && res.root_id) {
      dispatch({type: STORAGE_MNG_FINAL_DESTINATION, data: {...res, woMemfisId, preferredRootId}});
    } else if (res && res.empty_final_destination) {
      dispatch({type: STORAGE_MNG_FINAL_DESTINATION_IS_LOADING, loading: false});

      const root = fsRoots.find(root => root.fsRootID === preferredRootId);
      dispatch(showError(`Could not get asset final destination for drive ${root.name}`));
      dispatch({type: STORAGE_MNG_FINAL_DESTINATION_RESERVED, data: {woMemfisId, preferredRootId}});
    } else {
      dispatch({type: STORAGE_MNG_FINAL_DESTINATION_IS_LOADING, loading: false});
      dispatch(showError('We had trouble getting the asset final destination: Invalid response'));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_FINAL_DESTINATION_IS_LOADING, loading: false});
    dispatch(showError(`Could not get asset final destination.  ${getErrorMessage(error)}`));
  });
};

export const getRegexPatternsToValidateAmazonFilename = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: `${config.apiUrl}/v1/file_system/amazon_regex`
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const multipleMoveFsItems = (destination, markWoAsIngestCompleted) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID} = checkedItems[0];
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__MULTIPLE_MOVE,
      fsRootID,
      parameters: {
        ContentIDs: checkedItems.map(i => String(i.contentID)),
        DESTINATION: destination,
        MARK_WORK_ORDER_AS_INGEST_COMPLETED: typeof markWoAsIngestCompleted === 'boolean' ? markWoAsIngestCompleted : undefined
      }
    })
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    const {ActionID} = res || {};
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: 'Move'
    }));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not move items to ${destination}.  ${getErrorMessage(error)}`));
  });
};

export const moveFsItem = (destination, markWoAsIngestCompleted, actionName = 'Move', encState, encPassword) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID, key} = checkedItems[0];
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__MOVE,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        SOURCE: key,
        DESTINATION: destination,
        MARK_WORK_ORDER_AS_INGEST_COMPLETED: typeof markWoAsIngestCompleted === 'boolean' ? markWoAsIngestCompleted : undefined,
        PASSWORD: encState ? encPassword : null
      }
    })
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    const {ActionID} = res || {};
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: actionName
    }));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    const msg = actionName === 'Rename' ? `${parseName(key)} to ${parseName(destination)}` : `${key} to ${destination}`;
    dispatch(showError(`Could not ${actionName.toLowerCase()} item ${msg}.  ${getErrorMessage(error)}`));
  });
};

export const migrateFsItems = (destination, destRootID, schedulerData) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID} = checkedItems[0];
  const SOURCE = checkedItems.map(({key}) => `"${key}"`).join(',');
  const ContentIDs = checkedItems.map(({contentID}) => String(contentID));
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__MIGRATE_PORTAL,
      fsRootID,
      fsRootID2: destRootID,
      parameters: {
        ContentIDs,
        SOURCE,
        DESTINATION: destination,
      },
      ...schedulerData
    })
  })
    .done((response) => {
      dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
      const {ActionID} = response || {};
      dispatch(showSuccessPopup({
        actionId: ActionID,
        actionType: 'Migrate'
      }));
    })
    .fail((error) => {
      dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
      const msg = `Error sending migrate request. ${
        (error && error.ActionID) ? `Action ID: ${error.ActionID}` : ''
      } ${
        (error && error.warnMsg) ? getErrorMessage(`WARNING: ${error.warnMsg}`) : ''
      }`;
      dispatch(showError(msg));
    });
};

export const renameOfContentItem = (newName, encState, encPassword) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fullPath} = checkedItems[0];
  const destination = `${fullPath}${fullPath.endsWith('\\') ? '' : '\\'}${newName}`;
  return dispatch(moveFsItem(destination, undefined, 'Rename', encState, encPassword));
};

export const generateMd5Checksum = () => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];
  dispatch({type: STORAGE_MNG_CHECKSUM_GEN, loading: true, sumType: "MD5"});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__MD5_CHECKSUM,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        SAVE_TO_DB: true
      }
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_CHECKSUM_GEN, loading: false, sumType: "MD5"});
    dispatch(showChecksumModal(false));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_CHECKSUM_GEN, loading: false, sumType: "MD5"});
    dispatch(showError(`Could not generate MD5 Checksum.  ${getErrorMessage(error)}`));
  });
};

export const generateSha1Checksum = () => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];
  dispatch({type: STORAGE_MNG_CHECKSUM_GEN, loading: true, sumType: "SHA1"});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__SHA1_CHECKSUM,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        SAVE_TO_DB: true
      }
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_CHECKSUM_GEN, loading: false, sumType: "SHA1"});
    dispatch(showChecksumModal(false));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_CHECKSUM_GEN, loading: false, sumType: "SHA1"});
    dispatch(showError(`Could not generate SHA1 checksum.  ${getErrorMessage(error)}`));
  });
};

export const showChecksumModal = isShow => ({
  type: STORAGE_MNG_CHECKSUM_SHOW_MODAL,
  isShow
});

export const showPseModal = isShow => ({
  type: STORAGE_MNG_PSE_SHOW_MODAL,
  isShow
});

export const showFrameRateModal = isShow => ({
  type: STORAGE_MNG_FRAME_RATE_SHOW_MODAL,
  isShow
});

export const uncheckIngestLKFS = (value) => ({
  type: STORAGE_MNG_FRAME_RATE_UNCHECK_LKFS,
  value
});

export const setNewFrameRate = value => ({
  type: STORAGE_MNG_SET_FRAME_RATE,
  value
});

export const setErrorNumberFrameRateValue = value => ({
  type: STORAGE_MNG_SET_ERROR_NUMBER_FRAME_RATE,
  value
});

export const showMetafierModal = isShow => ({
  type: STORAGE_MNG_METAFIER_SHOW_MODAL,
  isShow
});

//export const showEdgePassModal = isShow => ({
//  type: STORAGE_MNG_EDGE_PASS_SHOW_MODAL,
//  isShow
//});

//export const showScreenToQodModal = isShow => ({
//  type: STORAGE_MNG_SCREEN_TO_QOD_SHOW_MODAL,
//  isShow
//});

export const showAlertModal = isShow => ({
  type: STORAGE_MNG_ALERT_SHOW_MODAL,
  isShow
});

export const showMd5ChecksumModal = isShow => ({
  type: STORAGE_MNG_CHECKSUM_MD5_SHOW_MODAL,
  isShow
});

export const showSha1ChecksumModal = isShow => ({
  type: STORAGE_MNG_CHECKSUM_SHA1_SHOW_MODAL,
  isShow
});

export const generateMd5ChecksumOrShowModal = () => (dispatch) => {
  dispatch(showMd5ChecksumModal(true));
};

export const generateSha1ChecksumOrShowModal = () => (dispatch) => {
  dispatch(showSha1ChecksumModal(true));
};

export const closeInstantScanResult = () => ({
  type: STORAGE_MNG_INSTANT_SCAN_CLOSE
});

const checkInstantScanStatus = (actionId, history) => (dispatch, getState) => {
  const {instantScanning, currentFolder, instantScanObject, instantScanActionResult} = getState().storageManager;
  if (!instantScanning) {
    return;
  }
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/instant_scan_new/${actionId}`
  })
  .done(res => {
    if (!instantScanning) {
      return;
    }
    const {ActionStatus, ActionResponse, IsViewOnly} = res;
    if (ActionStatus === 'DONE') {
      if (IsViewOnly) {
        dispatch({
          type: STORAGE_MNG_INSTANT_SCAN_STOP,
          data: (ActionResponse || []).map(item => ({...item, Size: item.IsDirectory === 'false' ? Number(item.Size) : 0}))
        });
      } else {
        if (!(instantScanActionResult && instantScanObject) ||
            (instantScanObject.fsRootID === currentFolder.fsRootID && instantScanObject.contentID === currentFolder.contentID)) {
          dispatch({type: STORAGE_MNG_INSTANT_SCAN_STOP});
          dispatch(refreshCurrentNavigation(history));
        } else {
          const {fsRootID, contentID} = instantScanObject;
          dispatch({type: STORAGE_MNG_INSTANT_SCAN_STOP});
          dispatch(changeCurrentFolder(history, {fsRootID, contentID, isDirectory: true}, true));
        }
      }
    } else if (['NEW', 'WAITING_EXECUTOR_ACTION', 'ACTIVE'].includes(ActionStatus)) {
      setTimeout((dispatch, actionId, history) => {
        dispatch(checkInstantScanStatus(actionId, history));
      }, (config.instantScanStatusFrequencyInSeconds || 5) * 1000, dispatch, actionId, history);
    } else {
      dispatch({type: STORAGE_MNG_INSTANT_SCAN_STOP});
      dispatch(showError(`Could not get result of instant scan.  Action result: ${JSON.stringify(res)}`));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_INSTANT_SCAN_STOP});
    dispatch(showError(`Could not get result of instant scan.  ${getErrorMessage(error)}`));
  });
};

const startInstantScanAction = (history, instantScanObject) => dispatch => {
  const {fsRootID, contentID} = instantScanObject;
  dispatch({type: STORAGE_MNG_INSTANT_SCAN_START, instantScanObject});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__INSTANT_SCAN,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)]
      }
    })
  })
  .done(res => {
    dispatch(checkInstantScanStatus(res.ActionID, history));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_INSTANT_SCAN_STOP});
    dispatch(showError(`Could not start instant scan.  ${getErrorMessage(error)}`));
  });
};

export const instantScan = (history) => (dispatch, getState) => {
  const {currentFolder} = getState().storageManager;
  dispatch(startInstantScanAction(history, currentFolder));
};

export const instantScanSubFolder = (history, subFolderKey) => (dispatch, getState) => {
  const loadingName = 'instantScanningSubFolder';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  const {fsRoots} = getState().storageManager;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/nav`,
    data: {
      path: subFolderKey,
      predict_instant_scan_sub_folder: true,
    }
  })
  .always(() => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
  })
  .done(res => {
    const isValid = ajv.validate(fileSystemResponseSchema, res);
    if (isValid && res.data.content.contentID > 0) {
      const preparedContent = prepareFile(res.data.content, fsRoots);
      dispatch(startInstantScanAction(history, preparedContent));
    } else {
      dispatch(showError('We had trouble getting content data to start instant scan: Invalid response'));
    }
  })
  .fail(error => {
    dispatch(showError(`We had trouble getting content data to start instant scan.  ${getErrorMessage(error)}`));
  });
};

const checkReadFileStatus = (fileNames, actionId, successCallback) => (dispatch, getState) => {
  const {isReadingFile} = getState().storageManager;
  if (!isReadingFile) {
    return;
  }
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/read_file/${actionId}`
  })
  .done(res => {
    if (!isReadingFile) {
      return;
    }
    const {ActionStatus, ActionResponse} = res;
    if (ActionStatus === 'DONE') {
      dispatch({type: STORAGE_MNG_READ_FILE_STOP});

      let fileLink;
      if (fileNames.length > 1) {
        fileLink = `${config.apiUrl}/v1/file_system/download/tmp_file/${actionId}?file_name=download_folder`;
      } else {
        fileLink = `${config.apiUrl}/v1/file_system/download/tmp_file/${actionId}?file_name=${fileNames[0]}`;
      }

      if (successCallback) {
        return successCallback(fileLink);
      }
      window.open(fileLink);
    } else if (['NEW', 'WAITING_EXECUTOR_ACTION', 'ACTIVE'].includes(ActionStatus)) {
      setTimeout((dispatch, actionId, fileNames) => {
        dispatch(checkReadFileStatus(fileNames, actionId, successCallback));
      }, (config.instantScanStatusFrequencyInSeconds || 5) * 1000, dispatch, actionId, fileNames);
    } else if (ActionStatus === 'DISABLED_BY_FAILURE') {
      dispatch({type: STORAGE_MNG_READ_FILE_STOP});
      dispatch(showError(ActionResponse || `Action Status: ${ActionStatus}`));
    } else {
      dispatch({type: STORAGE_MNG_READ_FILE_STOP});
      dispatch(showError('The selected file could not be read.  ' + (ActionResponse || `Action Status: ${ActionStatus}`)));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_READ_FILE_STOP});
    dispatch(showError(`The selected file could not be read.  ${getErrorMessage(error)}`));
  });
};

export const readFile = () => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, isDirectory} = checkedItems[0];
  dispatch({type: STORAGE_MNG_READ_FILE_START});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__READ_FILE,
      fsRootID,
      parameters: {
        ContentIDs: checkedItems.map(i => String(i.contentID)),
      }
    })
  })
  .done(res => {
    dispatch(checkReadFileStatus(checkedItems.map(i => i.name), res.ActionID));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_READ_FILE_STOP});
    dispatch(showError(`Could not begin reading ${isDirectory ? 'folder' : `file${checkedItems.length > 1 ? 's' : ''}`}.  ${getErrorMessage(error)}`));
  });
};

export const closeActionsView = () => ({
  type: STORAGE_MNG_ACTIONS_CLOSE
});

const getFileSettingsAllowReading = () => dispatch => {
  const loadingName = 'fileSettingsAllowReading';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/file_settings/allow_reading`
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_FILE_SETTINGS_ALLOW_READING, data: res});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get security settings for reading files.  ${getErrorMessage(error)}`));
  });
};

export const getActionsUserPermissionsWithoutRoots = () => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/user_permissions`
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_ACTIONS_USER_PERMISSIONS_WITHOUT_ROOTS, permissions: Array.isArray(res) ? res : []});
  })
  .fail(() => {
    dispatch({type: STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING, loading: false});
  });
};

export const getActionsUserPermissionsWithRoots = () => dispatch => {
  dispatch({type: STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/user_permissions`,
    data: {
      with_root: true,
      all_existing: true,
    }
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_ACTIONS_USER_PERMISSIONS, permissionsWithRoots: res});

    dispatch(getFileSettingsAllowReading());
    dispatch(getActionsUserPermissionsWithoutRoots());
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get user permissions.  ${getErrorMessage(error)}`));
  });
};

export const getAvailablePredictiveStates = () => dispatch => {
  dispatch({type: STORAGE_MNG_PREDICTIVE_STATES_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/predictive/states`
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_PREDICTIVE_STATES, data: res});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_PREDICTIVE_STATES_IS_LOADING, loading: false});
    dispatch(showError(`Could not get all available predictive states of content.  ${getErrorMessage(error)}`));
  });
};

export const setCurrentView = (history, view) => (dispatch, getState) => {
  const {storageManager} = getState();
  const {currentView, usedFilters, displayCategory, currentFolder} = storageManager;
  const oldUsePredictive = isUsePredictive(currentView);
  const newUsePredictive = isUsePredictive(view);

  localStorage.setItem('storage_view', view);
  dispatch({type: STORAGE_MNG_SET_CURRENT_VIEW, view});

  if (history) {
    dispatch(changeCheckedItems([]));
    dispatch(changeOpenedFolders([]));
    dispatch(changeOpenedFoldersInModal([]));

    if (oldUsePredictive !== newUsePredictive) {
      if (displayCategory === DISPLAY_CATEGORY_FOLDER) {
        dispatch(changeCurrentFolder(history, currentFolder));
      } else if (![DISPLAY_CATEGORY_ACTION_CONTENTS, DISPLAY_CATEGORY_INIT_CONFIRM_DELETION].includes(displayCategory)) {
        dispatch(applyAdvancedSearch(history, usedFilters));
      }
    }
  }
};

export const setAttachedVolumes = (checked) => (dispatch) => {
  localStorage.setItem('storage_show_attached_volumes', checked);
  dispatch({type: STORAGE_MNG_SET_ATTACHED_VOLUMES, checked});
}

export const setHideTempFiles = (hideTempFiles) => dispatch => {
  localStorage.setItem('storage_hide_temp_files', hideTempFiles);
  dispatch({type: STORAGE_MNG_SET_HIDE_TEMP_FILES, hideTempFiles});
};

export const getLockAndServiceStatusesAjax = () => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/server_manager/service_statuses`
  })
};

export const getLockStatusesForRoots = history => (dispatch, getState) => {
  const {lockAndServicesStatusesIsLoading, availableFsRootsIsLoading} = getState().storageManager;
  if (lockAndServicesStatusesIsLoading || availableFsRootsIsLoading) {
    return;
  }

  dispatch({type: STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING, loading: true});
  getLockAndServiceStatusesAjax()
  .done(res => {
    const {availableFsRoots, currentView, lockAndServicesStatuses, unknownFsRoots, currentFolder, history} = getState().storageManager;
    var fsRootID = currentFolder && currentFolder.fsRootID;
    var contentID = currentFolder && currentFolder.contentID;
    if (res) {
      if (fsRootID) {
        if (fsRootID && res.FsRootLockStatuses && res.FsRootLockStatuses.filter(r => r.FSRootID==fsRootID && r.IsAlive>0).length == 0) {
          // File System Root is NOT available anymore!!!
          dispatch(showError(`There is NO active drive ${fsRootID} anymore. Redirecting to storage roots in 10 seconds...`));
          setTimeout(function() {
            window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
            //dispatch(refreshCurrentNavigation(history));
          }, 10000);
        }
      } else if (res.FsRootLockStatuses) {
        availableFsRoots.forEach(ar => {
          fsRootID = ar.value;
          if (fsRootID && res.FsRootLockStatuses.filter(r => r.FSRootID==fsRootID && r.IsAlive>0).length == 0) {
            // File System Root is NOT available anymore!!!
            dispatch(showError(`There is NO active drive ${fsRootID} anymore. Redirecting to storage roots in 10 seconds...`));
            setTimeout(function() {
              window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
              //dispatch(refreshCurrentNavigation(history));
            }, 10000);
          }
        });
      }
      const prepareRes = obj => {
        Object.keys(obj).forEach(k => {
          if (!Array.isArray(obj[k]) && typeof obj[k] === 'object') {
            if (k === 'OtherRunners') {
              obj[k] = prepareRes(obj[k]);
            }
            if (Array.isArray(obj[k].fsRootIDs)) {
              obj[k].fsRootIDs = obj[k].fsRootIDs.sort();
            }
            if (typeof obj[k].fsRootIDsString === 'string' && obj[k].fsRootIDsString.indexOf(',') !== -1) {
              obj[k].fsRootIDsString = obj[k].fsRootIDsString.split(',').sort().join(',');
            }
          }
        });
        return obj;
      };
      res = prepareRes(res);
    }

    if (JSON.stringify(lockAndServicesStatuses) !== JSON.stringify(res)) {
      const newUnknownFsRoots = !availableFsRoots.length ? null :
        (res.FsRootLockStatuses || []).filter(r => !availableFsRoots.find(root => r.FSRootID === root.value)).map(r => r.FSRootID).sort();

      dispatch({type: STORAGE_MNG_LOCK_AND_SERVICES_STATUSES, data: res, newUnknownFsRoots});

      if (JSON.stringify(newUnknownFsRoots || []) !== JSON.stringify(unknownFsRoots || [])) {
        dispatch(getActiveFsRoots());
      }
    } else {
      dispatch({type: STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING, loading: false});
    }

    if ((!res || !res.PredictiveStateEnabled) && currentView !== VIEW_ADVANCED_SIMPLE) {
      dispatch(setCurrentView(history, VIEW_ADVANCED_SIMPLE));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING, loading: false});
    dispatch(showError(`Could not get lock statuses for active volumes.  ${getErrorMessage(error)}`));
  });
};

export const checkNeedToMarkWoAsDownloadedAndMovedInMemfis = destinationFolder => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID, key, memfisWoID} = checkedItems[0];
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/move/check_need_to_mark_wo_as_ingest_completed`,
    data: {
      source: JSON.stringify({fsRootID, contentID, key, memfisWoID}),
      destination: JSON.stringify({
        fsRootID: destinationFolder.fsRootID,
        contentID: destinationFolder.contentID,
        key: destinationFolder.key,
        memfisWoID: destinationFolder.memfisWoID
      })
    }
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    return res;
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Please mark work order Downloaded and Moved in Memfis: ${getErrorMessage(error)}`));
  });
};

export const showNewFolderModal = (isShow) => ({
  type: STORAGE_MNG_NEW_DIRECTORY_SHOW_MODAL,
  isShow,
});

export const showUploadToAsperaModal = isShow => ({
  type: STORAGE_MNG_UPLOAD_TO_ASPERA_SHOW_MODAL,
  isShow
});

export const makeDirectoryAjax = (currentFolder, newFolderName) => {
  const {fsRootID, contentID} = currentFolder;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__MK_DIRECTORY,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        DIRECTORY_NAME: newFolderName
      }
    })
  });
};

export const makeActionAjax = (actionID) => $.ajax({
  method: 'GET',
  url: `${config.apiUrl}/v1/file_system/actions/${actionID}`,
});

export const makeDirectory = (history/*can be nullable*/, newFolderName, {parentFolderInTree, callback} = {}) => (dispatch, getState) => {
  const {explorerComponent, storageManager} = getState();
  let checkedFolderInTree;
  if(!parentFolderInTree) {
    checkedFolderInTree = explorerComponent.folders.find(folder => folder.isChecked);
  }
  const parentNotCurrentFolder = parentFolderInTree || checkedFolderInTree;
  const parentFolder = parentNotCurrentFolder || storageManager.currentFolder;
  /*const {fsRootID, contentID, key} = parentFolder;*/
  if (parentFolderInTree) {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'mkDir', loading: true});
  }
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return makeDirectoryAjax(parentFolder, newFolderName)
  .done(({ActionID}) => {
    /*if (parentNotCurrentFolder) {*/
      setTimeout(function request() {
        makeActionAjax(ActionID).done((response) => {
          const {actionStatus} = response.action;
          if(actionStatus === 'DONE') {
            dispatch(showNewFolderModal(false));
            dispatch({type: STORAGE_MNG_SET_LOADING, name: 'mkDir', loading: false});
            dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
            if (parentFolderInTree) {
              dispatch(getFolderChildren(parentFolderInTree, null, null, {newFolderName, newFolderCallback: callback}));
            } else if(checkedFolderInTree) {
              dispatch(folderManagerGetSubfolderAndExpand(checkedFolderInTree, newFolderName));
            } else if (history) {
              dispatch(changeCurrentFolder(history, parentFolder, null, [newFolderName, ...getState().storageManager.checkedItems.map(i => i.contentID)]));
            } else {
              dispatch(showSuccess('New directory has been successfully created.  Refresh folder if necessary.'));
            }
          } else if(actionStatus === 'FAILED' || actionStatus === 'EXPIRED' || actionStatus === 'REJECTED' || actionStatus === 'INCOMPLETE' || actionStatus === 'DISABLED_BY_FAILURE') {
            dispatch({type: STORAGE_MNG_SET_LOADING, name: 'mkDir', loading: false});
            dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
            dispatch(showError(`Could not create directory.  ${getErrorMessage(actionStatus)}`));
          } else {
            setTimeout(request, 3000);
          }
        });
      }, 3000);
    /*} else {
      dispatch({
        type: STORAGE_MNG_TEMP_DIRS_ADDED,
        newDir: {
          fsRootID,
          contentID: -Date.now(),
          parentID: contentID,
          key: `${key}\\${newFolderName}`,
          name: newFolderName,
          isDirectory: true
        }
      });
      setTimeout(() => {
        dispatch(showNewFolderModal(false));
        dispatch({type: STORAGE_MNG_SET_LOADING, name: 'mkDir', loading: false});
        dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
        dispatch(showSuccess('New directory has been successfully created.  Refresh folder if necessary.'));
        // dispatch(refreshCurrentNavigation(history));
      }, 5000);
    }*/
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'mkDir', loading: false});
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not create directory.  ${getErrorMessage(error)}`));
  });
};

export const refreshCurrentNavigation = history => (dispatch, getState) => {
  const {currentFolder} = getState().storageManager;
  if (!currentFolder) {
    dispatch(getRoots(history));
  } else {
    dispatch(changeCurrentFolder(history, currentFolder));
  }
};

export const refreshSelectedContentWithoutChildren = (selectedItem, successCallback) => (dispatch, getState) => {
  const {checkedItems, usedFilters, currentView, tempDirectories, fsRoots, history} = getState().storageManager;
  const {fsRootID, contentID, parentID, key, isDirectory, isSymlink} = (selectedItem || checkedItems[0]);
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'refreshSelectedContent', loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/root/${fsRootID}/${isSymlink ? 'symlink' : 'content'}/${contentID}`,
    data: {
      with_children: false,
      [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView),
      [USE_MAX_MODIFIED_ON_FILTER_NAME]: usedFilters[USE_MAX_MODIFIED_ON_FILTER_NAME],
      file_name: key
    }
  })
  .done(res => {
    const isValid = ajv.validate(refreshContentResponseSchema, res);

    if (isValid) {
      const {content} = res.data;
      if (parentID === content.parentID) {
        dispatch({type: STORAGE_MNG_REFRESH_SEL_CONTENT_IN_CUR_FOLDER, fsRootID, contentID, content: prepareFile(content, fsRoots)});
      } else {
        dispatch({type: STORAGE_MNG_REFRESH_SEL_CONTENT_IN_CUR_FOLDER, fsRootID, contentID, content: null});
        if (isDirectory) {
          const deletedDirKey = Object.keys(tempDirectories.deleted).find(k => k === `r${fsRootID}-p${parentID}-${key}`);
          if (deletedDirKey) {
            dispatch({type: STORAGE_MNG_REMOVE_FROM_TEMP_DIRS, tempDirectoriesToRemoved: {added: [], deleted: [deletedDirKey]}});
          }
        }
      }
    } else {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: 'refreshSelectedContent', loading: false});
      dispatch(showError('We had trouble getting content data: Invalid response'));
    }
  })
  .then(() => getState().storageManager.checkedItems)
  .then(refreshedCheckedItems => dispatch(getAvailableActionsBySources(refreshedCheckedItems)))
  .then(() => {successCallback && successCallback();})
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'refreshSelectedContent', loading: false});
    if (getErrorMessage(error).trim().startsWith("Bad root!")) {
      dispatch(showError(`There is NO active drive ${fsRootID}. Redirecting to storage roots in 10 seconds. Error: ${getErrorMessage(error)}`));
      setTimeout(function() {
        window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
        //dispatch(refreshCurrentNavigation(history));
      }, 10000);
    } else {
      dispatch(showError(`Could not get content data.  ${getErrorMessage(error)}`));
    }
  });
};

export const refreshCheckedContent = () => (dispatch, getState) => {
  const {checkedItems, displayCategory} = getState().storageManager;
  if (checkedItems.length === 1) {
    if (displayCategory === DISPLAY_CATEGORY_FOLDER || displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS) {
      dispatch(refreshSelectedContentWithoutChildren());
    } else if (displayCategory === DISPLAY_CATEGORY_ROOTS) {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: 'refreshSelectedContent', loading: true});
      $.ajax({
        method: 'GET',
        url: `${config.apiUrl}/v1/file_system/roots`
      })
      .done(res => {
        dispatch({
          type: STORAGE_MNG_REFRESH_SEL_ROOT,
          fsRoots: (res || []).map(prepareRoot),
          availableFsRoots: prepareFsRoots(res)
        });
      })
      .fail(error => {
        dispatch({type: STORAGE_MNG_SET_LOADING, name: 'refreshSelectedContent', loading: false});
        dispatch(showError(`Could not get roots.  ${getErrorMessage(error)}`));
      });
    }
  }
};

export const reconcileSymlinkItem = () => (dispatch, getState) => {
  const { checkedItems } = getState().storageManager;

  const isSymlink = checkedItems.length === 1 && checkedItems[0].isSymlink;
  const isNotLive = checkedItems[0].relativeStatus !== SYMLINK_RELATIVE_STATUS__LIVE;

  if (isSymlink && isNotLive) {
    dispatch({ type: STORAGE_MNG_MODAL_IS_LOADING, loading: true });
    const { fsRootID, key } = checkedItems[0];
    return $.ajax({
      method: "POST",
      url: `${config.apiUrl}/v1/file_system/action_request`,
      contentType: "application/json;charset=utf-8",
      dataType: "json",
      data: JSON.stringify({
        actionType: FS_ACTION_TYPE__RECONCILE_SYMLINK_PORTAL,
        fsRootID,
        parameters: {
          OLD_SYMBOLINK_PATH: key,
        },
      }),
    })
      .done(() => {
        dispatch({ type: STORAGE_MNG_MODAL_IS_LOADING, loading: false });
        dispatch(showSuccess(`Item ${key} has been successfully reconciled`));
      })
      .fail((error) => {
        dispatch({ type: STORAGE_MNG_MODAL_IS_LOADING, loading: false });
        dispatch(
          showError(
            `Could not reconcile item ${key}. ${getErrorMessage(error)}`
          )
        );
      });
  }
  dispatch(showWarning(`Item has the status ${SYMLINK_RELATIVE_STATUS__LIVE}`));
};

export const reconcileFsItem = () => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, memfisWoID, key} = checkedItems[0];
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/reconcile_deleted_content`,
    data: {
      fs_root_id: fsRootID,
      wo_memfis_id: memfisWoID
    }
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showSuccess(`Item ${key} has been successfully reconciled`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not reconcile item ${key}.  ${getErrorMessage(error)}`));
  });
};

const getReconcileDeletedContent = (fsRootID, contentID, memfisWoID) => dispatch => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/reconcile/deleted_content`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID,
      wo_memfis_id: memfisWoID
    }
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    return res;
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not get the reconcile deleted content to manually reconcile FS content.  ${getErrorMessage(error)}`));
  });
};

const getReconcileContent = (fsRootID, contentID, memfisWoID) => dispatch => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/reconcile/content_to_reconcile`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID,
      wo_memfis_id: memfisWoID
    }
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    return res;
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not get content to reconcile.  ${getErrorMessage(error)}`));
  });
};

const showBarcodedFilesToManuallyReconcile = (fsItem, originalAsset, copiedAsset) => ({
  type: STORAGE_MNG_SHOW_MANUALLY_RECONCILE_MODAL,
  data: {fsItem, originalAsset, copiedAsset}
});

export const closeManuallyReconcileModal = () => ({
  type: STORAGE_MNG_SHOW_MANUALLY_RECONCILE_MODAL
});

export const showSelectFolderModal = isShow => ({
  type: STORAGE_MNG_SHOW_SELECT_FOLDER_MODAL, isShow
});

export const closeSelectFolderModal = () => ({
  type: STORAGE_MNG_SHOW_SELECT_FOLDER_MODAL
});

export const switchSettingsModal = (isOpen) => ({
  type: STORAGE_MNG_SWITCH_SETTINGS_MODAL,
  isOpen,
});

export const loadSettings = () => (dispatch) => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/mam_properties`,
  }).done((settings) => {

    const isValid = ajv.validate({
      "type": "object",
      "additionalProperties": {
        "type": "object",
        "properties": {
          "label": {
            "type": "string"
          },
          "values": {
            "type": "object",
            "additionalProperties": {
              "type": "object",
              "properties": {
                "description": {"type": "string"},
                "editable": {"type": "boolean"},
                "label": {"type": "string"},
                "maxValue": {"type": "number"},
                "minValue": {"type": "number"},
                "type": {
                  "enum": ["number"]
                },
                "value": {"type": "number"}
              }
            }
          }
        }
      }
    }, settings);
    if (isValid) {
      dispatch({
        type: STORAGE_MNG_LOAD_SETTINGS,
        settings,
      });
    } else {
      console.error('loadSettings request: data is not valid.', settings);
    }
  }).fail((error) => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
  });

  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/file_settings/allowed_extensions`,
  })
  .done((extensionSettings) => {
    const isValid = ajv.validate({
      "type": "object",
      "properties": {
        "allow_LKFS": {
          "type": "object",
          "patternProperties": {
            "^\\.\\w+$": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "PropertyValue": {
                    "type": ["string", "null"],
                  },
                  "fileExtension": {
                    "type": "string"
                  },
                  "fileTypeID": {
                    "type": "integer",
                  },
                  "propertyName": {
                    "type": ["string", "null"]
                  }
                }
              }
            }
          },
          "additionalProperties":false
        },
        "allow_LKFS_extended": {
          "type": "object",
          "patternProperties": {
            "^\\.\\w+$": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "PropertyValue": {
                    "type": ["string", "null"],
                  },
                  "fileExtension": {
                    "type": "string"
                  },
                  "fileTypeID": {
                    "type": "integer",
                  },
                  "propertyName": {
                    "type": ["string", "null"]
                  }
                }
              }
            }
          },
          "additionalProperties":false
        },
        "allow_reading": {
          "type": "object",

          "patternProperties": {
            "^(\\.\\w+$)|(\\s*)": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "PropertyValue": {
                    "type": ["string", "null"],
                  },
                  "fileExtension": {
                    "type": "string"
                  },
                  "fileTypeID": {
                    "type": "integer",
                  },
                  "propertyName": {
                    "type": ["string", "null"]
                  }
                }
              }
            }
          },
          "additionalProperties":false
        },
        "allow_CAPTIONS": {
          "type": "object",

          "patternProperties": {
            "^(\\.\\w+$)|(\\s*)": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "PropertyValue": {
                    "type": ["string", "null"],
                  },
                  "fileExtension": {
                    "type": "string"
                  },
                  "fileTypeID": {
                    "type": "integer",
                  },
                  "propertyName": {
                    "type": ["string", "null"]
                  }
                }
              }
            }
          },
          "additionalProperties":false
        },
        "allow_Metafier": {
          "type": "object",

          "patternProperties": {
            "^(\\.\\w+$)|(\\s*)": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "PropertyValue": {
                    "type": ["string", "null"],
                  },
                  "fileExtension": {
                    "type": "string"
                  },
                  "fileTypeID": {
                    "type": "integer",
                  },
                  "propertyName": {
                    "type": ["string", "null"]
                  }
                }
              }
            }
          },
          "additionalProperties":false
        },
      }
    }, extensionSettings)
    if (isValid) {
      dispatch({
        type: STORAGE_MNG_LOAD_SETTINGS,
        extensionSettings: {
          allowLKFS: extensionSettings.allow_LKFS,
          allowLKFSExtended: extensionSettings.allow_LKFS_extended,
          allowReading: extensionSettings.allow_reading,
          allowCaptions: extensionSettings.allow_CAPTIONS,
          metafierExtensions: extensionSettings.allow_Metafier,
          edgePassExtensions: extensionSettings.allow_EdgePass,
          screenToQodExtensions: extensionSettings.allow_ScreenToQod,
        },
      });
    } else {
      console.error('loadSettings request: data is not valid.', extensionSettings);
    }
  })
  .fail((error) => {

  });
};

export const checkMyActions = () => (dispatch, getState) => {
  const {actionsRequestorsMap, availableFsRoots, currentFolder, history} = getState().storageManager;
  var fsRootID = currentFolder && currentFolder.fsRootID;
  var contentID = currentFolder && currentFolder.contentID;
  const meRequestor = Object.entries(actionsRequestorsMap || {}).find(([k, v]) => v === 'me');

  if (!meRequestor || !['/faspex', '/issues', '/storage', '/download'].includes(window.location.pathname)) {
    return;
  }

  const actionsUrlFilters = {
    [ACTION_FILTER_REQUESTOR_ID]: [meRequestor[0]],
    [ACTION_FILTER_CREATED_ON]: {start: moment().subtract(2, 'days').toDate()},
    "field":"actionID",
    "status": ["ACTIVE"],
    "direction":"desc"
  };

  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions`,
    data: {
      filters: JSON.stringify(actionsUrlFilters),
      start: 0,
      limit: 5
    }
  })
  .done(res => {
    const {actions: actionsPagingData, filters} = res;
    var fs_roots = filters && filters.fs_roots;
    if (fs_roots && availableFsRoots) {
      if (fsRootID) {
        if (fsRootID && fs_roots.filter(r => r.value==fsRootID).length == 0) {
          // File System Root is NOT available anymore!!!
          dispatch(showError(`There is NO active drive ${fsRootID} anymore. Redirecting to storage roots in 10 seconds...`));
          setTimeout(function() {
            window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
            //dispatch(refreshCurrentNavigation(history));
          }, 10000);
        }
      } else {
        availableFsRoots.forEach(ar => {
          fsRootID = ar.value;
          if (fsRootID && fs_roots.filter(r => r.value==fsRootID).length == 0) {
            // File System Root is NOT available anymore!!!
            dispatch(showError(`There is NO active drive ${fsRootID} anymore. Redirecting to storage roots in 10 seconds...`));
            setTimeout(function() {
              window.location.href = '/storage'; //delete getState().storageManager.currentFolder;
              //dispatch(refreshCurrentNavigation(history));
            }, 10000);
          }
        });
      }
    }
    dispatch({type: STORAGE_MNG_MY_ACTIONS_ACTIVE_CONTENTS, hasActiveMyActions: actionsPagingData.data && actionsPagingData.data.length > 0, allActiveUsers: res.allActiveUsers});
  })
  .fail(error => {
    dispatch(showError(`Could not get my active actions.  ${getErrorMessage(error)}`));
  });
}

export const switchMyActionsModal = (isOpen) => ({
  type: STORAGE_MNG_SWITCH_MY_ACTIONS_MODAL,
  isOpen,
});

export const loadMyActions = () => (dispatch, getState) => {
  dispatch({type: STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING, loading: true});
  const {actionsRequestorsMap} = getState().storageManager;
  const meRequestor = Object.entries(actionsRequestorsMap || {}).find(([k, v]) => v === 'me');

  const actionsUrlFilters = {
    [ACTION_FILTER_REQUESTOR_ID]: [meRequestor[0]],
    [ACTION_FILTER_CREATED_ON]: {start: moment().subtract(2, 'days').toDate()},
    "field":"actionID",
    "direction":"desc",
    "show_child": true,
    "only_root": true
  };

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions`,
    data: {
      filters: JSON.stringify(actionsUrlFilters),
      start: 0,
      limit: 15
    }
  })
  .done(res => {
    const {actions: actionsPagingData} = res;
    dispatch({
      type: STORAGE_MNG_MY_ACTIONS_SHOW,
      data: (actionsPagingData || {}).data || []
    });
    dispatch({type: STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING, loading: false});
    dispatch(showError(`Could not get my actions.  ${getErrorMessage(error)}`));
  });
}

export const closeMyActionsView = () => ({
  type: STORAGE_MNG_MY_ACTIONS_CLOSE
});

export const loadActionType = () => dispatch => {
  const name = 'loadActionType';
  dispatch({type: STORAGE_MNG_SET_LOADING, name, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/file_settings/action_type`,
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name, loading: false});
    dispatch({type: STORAGE_MNG_SET_ACTION_TYPES, types: res});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name, loading: false});
    dispatch(showInfo(`Could not get action type settings.  ${getErrorMessage(error)}`));
  });
};

export const saveSettings = (editedSettings) => (dispatch) => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  const url = `${config.apiUrl}/v1/file_system/mam_properties/update`
  $.ajax({
    method: 'POST',
    url,
    data: JSON.stringify(editedSettings),
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
  }).done((result) => {
    dispatch({
      type: STORAGE_MNG_SAVE_SETTINGS,
      editedSettings,
    });
  }).fail((error) => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
  });
};

export const startManuallyReconcileFsItem = () => dispatch => {
  dispatch(showSelectFolderModal('ManuallyReconcile'));
};

export const getAndShowBarcodedFilesToManuallyReconcile = copiedAssetContentID => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, memfisWoID, contentID} = checkedItems[0];
  dispatch(closeSelectFolderModal());
  dispatch(getReconcileDeletedContent(fsRootID, contentID, memfisWoID))
    .then(originalAsset => {
      dispatch(getReconcileContent(fsRootID, copiedAssetContentID, memfisWoID)).then(copiedAsset => {
        dispatch(showBarcodedFilesToManuallyReconcile(checkedItems[0], originalAsset, copiedAsset));
      });
    });
};

export const manuallyReconcileFsItem = values => dispatch => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/reconcile/manual_reconcile`,
    data: values
  })
  .done(() => {
    dispatch(closeManuallyReconcileModal());
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showSuccess(`Unidentified content has been successfully reconciled`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not reconcile unidentified content.  ${getErrorMessage(error)}`));
  });
};

export const findDeletedAsset = history => (dispatch, getState) => {
  const {checkedItems, fsRoots, usedFilters, currentView} = getState().storageManager;
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  const {fsRootID, memfisWoID} = checkedItems[0];
  const fsRoot = fsRoots.find(i => i.fsRootID === fsRootID);
  const validUrlFilters = {
    [USE_MAX_MODIFIED_ON_FILTER_NAME]: usedFilters[USE_MAX_MODIFIED_ON_FILTER_NAME],
    [FILENAME_AS_WELL_FILTER_NAME]: true,
    [DELETED_AS_WELL_FILTER_NAME]: true,
    [LAST_SEGMENT_ONLY_FILTER_NAME]: true,
    [SYMLINKS_TOO_FILTER_NAME]: true,
    [WO_MEMFIS_FILTER_NAME]: `*${memfisWoID}*`,
    [SEARCH_IN_FILTER_NAME]: [{fs_root_id: fsRootID, content_id: fsRoot.contentID, file_name: fsRoot.key}]
  };
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/search`,
    data: {
      filters: JSON.stringify({...validUrlFilters, [USE_PREDICTIVE_FILTER_NAME]: isUsePredictive(currentView)})
    }
  })
  .done(res => {
    const isValid = ajv.validate(advancedSearchValidationSchema, res);
    if (isValid) {
      dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
      dispatch({type: STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD});
      history.replace({search: `${qs.stringify(validUrlFilters)}`});
      const data = res.data.data.map(i => ({
        ...i,
        afterSearchDeletedAsset: true,
        isSymlink: false,
      }));
      const symlinks = res.symbolinks.data.map(i => ({
        ...i,
        afterSearchDeletedAsset: true,
        isSymlink: true,
      }));
      const result = {
        ...res,
        data: {
          ...res.data,
          data: data.concat(symlinks),
          total: res.data.total + res.symbolinks.total,
        },
      };
      dispatch(applySearchResults(
        result,
        validUrlFilters,
        convertFiltersToString(validUrlFilters),
        true
      ));
    } else {
      dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
      dispatch(showError(`Response is not valid`));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not find deleted asset by "*${memfisWoID}*".  ${getErrorMessage(error)}`));
  });
};

const showLoaderForUploadFile = () => ({type: STORAGE_MNG_SET_LOADING, name: 'uploadingFile', loading: true});
const hideLoaderForUploadFile = () => ({type: STORAGE_MNG_SET_LOADING, name: 'uploadingFile', loading: false});

const checkUploadFileStatus = (history, actionId) => (dispatch, getState) => {
  const {loaders} = getState().storageManager;
  if (!loaders.uploadingFile) {
    return;
  }
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/upload_file/${actionId}`
  })
  .done(res => {
    if (!loaders.uploadingFile) {
      return;
    }
    const {ActionStatus, ActionResponse} = res;
    if (ActionStatus === 'DONE') {
      dispatch(hideLoaderForUploadFile());
      dispatch(refreshCurrentNavigation(history));
    } else if (['NEW', 'WAITING_EXECUTOR_ACTION', 'ACTIVE'].includes(ActionStatus)) {
      setTimeout((dispatch, actionId, history) => {
        dispatch(checkUploadFileStatus(history, actionId));
      }, (config.instantScanStatusFrequencyInSeconds || 5) * 1000, dispatch, actionId, history);
    } else {
      dispatch(hideLoaderForUploadFile());
      dispatch(showError('We were unable to upload the file.  ' + (ActionResponse || `Action Status: ${ActionStatus}`)));
    }
  })
  .fail(error => {
    dispatch(hideLoaderForUploadFile());
    dispatch(showError(`We were unable to upload the file.  ${getErrorMessage(error)}`));
  });
};

export const uploadFile = (history, fileName, file) => (dispatch, getState) => {
  const {currentFolder} = getState().storageManager;
  const {fsRootID, contentID, key} = currentFolder;
  const data = new FormData();
  data.append('file', file, fileName);
  data.append('fs_root_id', fsRootID);
  data.append('content_id', contentID);
  data.append('destination_path', key);
  dispatch(showLoaderForUploadFile());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/upload_file`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(res => {
    dispatch(checkUploadFileStatus(history, res.ActionID));
  })
  .fail(error => {
    dispatch(hideLoaderForUploadFile());
    dispatch(showError(`Could not upload file.  ${getErrorMessage(error)}`));
  });
};

const showLoaderForBuildingZip = () => ({type: STORAGE_MNG_SET_LOADING, name: 'buildingZip', loading: true});

const hideLoaderForBuildingZip = () => ({type: STORAGE_MNG_SET_LOADING, name: 'buildingZip', loading: false});

export const generateAndUploadZip = (history, folderName, filesToZip) => (dispatch, getState) => {
  const {zipFileSetting} = getState().storageManager;
  dispatch(showLoaderForBuildingZip());
  try {
    const zip = new JSZip();
    for (let file of filesToZip) {
      const fileName = file.path.replace(new RegExp('^' + folderName), '').replace(/^(\/|\\)+/, '');
      zip.file(fileName, file);
    }
    zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 9
      }
    })
      .then(function callback(blob) {
        dispatch(hideLoaderForBuildingZip());
        const {size} = blob;
        if (size <= Number(zipFileSetting.PropertyValue)) {
          dispatch(uploadFile(history, `${folderName}.zip`, blob));
        } else {
          dispatch(showError('Could not upload .zip:  Maximum file size exceeded.'));
        }
      }, function (e) {
        console.log(e);
        dispatch(showError('Could not generate .zip'));
        dispatch(hideLoaderForBuildingZip());
      });
  } catch (e) {
    console.log(e);
    dispatch(showError('Could not build and upload .zip'));
    dispatch(hideLoaderForBuildingZip());
  }
};

const showAplusSettingsLoader = () => ({type: STORAGE_MNG_SET_LOADING, name: 'aplusSettingsLoading', loading: true});

const hideAplusSettingsLoader = () => ({type: STORAGE_MNG_SET_LOADING, name: 'aplusSettingsLoading', loading: false});

export const closeAplusSettingsModal = () => ({type: STORAGE_MNG_SHOW_APLUS_SETTINGS});

const checkGettingAplusSettingsStatus = (actionID, fsRootID) => (dispatch, getState) => {
  const {loaders} = getState().storageManager;
  if (!loaders.aplusSettingsLoading) {
    return;
  }
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/get_aplus_settings/${actionID}`
  })
  .done(res => {
    if (!loaders.aplusSettingsLoading) {
      return;
    }
    const {ActionStatus, ActionResponse, AplusSettings} = res;
    if (ActionStatus === 'DONE') {
      dispatch(hideAplusSettingsLoader());
      try {
        dispatch({type: STORAGE_MNG_SHOW_APLUS_SETTINGS, data: {settings: JSON.parse(AplusSettings), fsRootID}});
      } catch(e) {
        console.log('Could not parse auto-shutdown settings', AplusSettings, e);
        dispatch(showError('Could not parse aplus settings'));
      }
    } else if (['NEW', 'WAITING_EXECUTOR_ACTION', 'ACTIVE'].includes(ActionStatus)) {
      setTimeout((dispatch, actionID, fsRootID) => {
        dispatch(checkGettingAplusSettingsStatus(actionID, fsRootID));
      }, (config.instantScanStatusFrequencyInSeconds || 5) * 1000, dispatch, actionID, fsRootID);
    } else {
      dispatch(hideAplusSettingsLoader());
      dispatch(showError('We had trouble getting auto-shutdown settings.  ' + (ActionResponse || `Action Status: ${ActionStatus}`)));
    }
  })
  .fail(error => {
    dispatch(hideAplusSettingsLoader());
    dispatch(showError(`Could not get auto-shutdown settings.  ${getErrorMessage(error)}`));
  });
};

export const getAplusSettings = fsRootID => dispatch => {
  dispatch(showAplusSettingsLoader());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__GET_APLUS_SETTINGS,
      fsRootID
    })
  })
  .done(res => {
    dispatch(checkGettingAplusSettingsStatus(res.ActionID, fsRootID));
  })
  .fail(error => {
    dispatch(hideAplusSettingsLoader());
    dispatch(showError(`Could not get auto-shutdown settings.  ${getErrorMessage(error)}`));
  });
};

const setAplusSettings = fsRootID => dispatch => {
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__SET_APLUS_SETTINGS,
      fsRootID
    })
  })
  .done(res => {
    dispatch(hideAplusSettingsLoader());
    dispatch(closeAplusSettingsModal());
    dispatch(showSuccess('Auto-shutdown settings have been successfully updated.'));
  })
  .fail(error => {
    dispatch(hideAplusSettingsLoader());
    dispatch(showError(`Auto-shutdown settings have been saved, but could not be set.  ${getErrorMessage(error)}`));
  });
};

export const saveAplusSettings = (fsRootID, settings) => dispatch => {
  dispatch(showAplusSettingsLoader());
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/save_last_aplus_settings`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      fsRootID,
      aplusSettings: settings
    })
  })
  .done(() => {
    dispatch(setAplusSettings(fsRootID));
  })
  .fail(error => {
    dispatch(hideAplusSettingsLoader());
    dispatch(showError(`Could not save auto-shutdown settings.  ${getErrorMessage(error)}`));
  });
};

export const showAvailableDrivesModal = data => ({type: STORAGE_MNG_SHOW_AVAILABLE_DRIVES_MODAL, data});
export const closeAvailableDrivesModal = () => ({type: STORAGE_MNG_SHOW_AVAILABLE_DRIVES_MODAL});

export const connectDrive = (drive, fsRoot) => dispatch => {
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'connectDrive', loading: true});
  const {FSRootID, DriveLabel, DriveLabelAlias} = drive;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__CONNECT_DRIVE,
      fsRootID: FSRootID,
      parameters: {
        DRIVE_LABEL: DriveLabel
      }
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_CONNECT_DRIVE, drive});
    dispatch(showSuccess(`External drive ${DriveLabelAlias} successfully connected to ${fsRoot.label}`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'connectDrive', loading: false});
    dispatch(showError(`Could not connect external drive ${DriveLabelAlias} to ${fsRoot.label}.  ${getErrorMessage(error)}`));
  });
};

export const disconnectDrive = (drive, fsRoot) => dispatch => {
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'disconnectDrive', loading: true});
  const {FSRootID, DriveLabel, DriveLabelAlias} = drive;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__DISCONNECT_DRIVE,
      fsRootID: FSRootID,
      parameters: {
        DRIVE_LABEL: DriveLabel
      }
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_DISCONNECT_DRIVE, drive});
    dispatch(showSuccess(`External drive ${DriveLabelAlias} successfully disconnected from ${fsRoot.label}`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'disconnectDrive', loading: false});
    dispatch(showError(`Could not disconnect external drive ${DriveLabelAlias} from ${fsRoot.label}.  ${getErrorMessage(error)}`));
  });
};

export const showEncryptModal = () => ({type: STORAGE_MNG_SHOW_ENCRYPT_MODAL, isShow: true});
export const closeEncryptModal = () => ({type: STORAGE_MNG_SHOW_ENCRYPT_MODAL, isShow: false});

export const singleEncryptOrDecrypt = (selectedFolder, password) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {isEncrypted, fsRootID, contentID, key} = checkedItems[0];
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'singleEncryptOrDecrypt', loading: true});
  const actionName = isEncrypted ? 'decrypt' : 'encrypt';
  const actionType = isEncrypted ? FS_ACTION_TYPE__DECRYPT_CONTENT : FS_ACTION_TYPE__ENCRYPT_CONTENT;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        SOURCE: key,
        DESTINATION: selectedFolder.key,
        PASSWORD: password
      }
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_SINGLE_ENCRYPT_OR_DECRYPT, fsRootID, contentID, isEncrypted: !isEncrypted});
    dispatch(showSuccess(`Content successfully ${actionName}ed`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'singleEncryptOrDecrypt', loading: false});
    dispatch(showError(`Could not ${actionName} content.  ${getErrorMessage(error)}`));
  });
};

export const getEncryptOrDecryptPassword = item => dispatch => {
  const {isEncrypted, fsRootID, contentID} = item;
  const actionName = isEncrypted ? 'decrypt' : 'encrypt';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'getEncryptOrDecryptPassword', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/${actionName}/password`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID
    }
  })
  .done(password => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'getEncryptOrDecryptPassword', loading: false});
    return password;
  })
  .fail(error => {
    const message = getErrorMessage(error);
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'getEncryptOrDecryptPassword', loading: false});
    if (message === 'OK') {
      return null;
    }
    dispatch(showInfo(`Could not get password to ${actionName} content.  ${message}`));
  });
};

export const getClientListByWoMemfisId = woMemfisId => dispatch => {
  dispatch({type: STORAGE_MNG_CLIENT_NAME_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/client_list`,
    data: {
      wo_memfis: woMemfisId
    }
  })
  .done(res => {
    const clientList = (res || []).filter(i => !!i).sort((a, b) => a.localeCompare(b)).map(i => ({value: i, label: i}));
    dispatch({type: STORAGE_MNG_CLIENT_LIST_SET, woMemfisId, clientList});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_CLIENT_NAME_LOADING, loading: false});
    dispatch(showInfo(`Could not get client list.  ${getErrorMessage(error)}`));
  });
};

export const setClientNameForWoMemfisId = (woMemfisId, clientName) => dispatch => {
  dispatch({type: STORAGE_MNG_CLIENT_NAME_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/set_client`,
    data: {
      wo_memfis: woMemfisId,
      client_name: clientName
    }
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_CLIENT_NAME_SET, woMemfisId, clientName});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_CLIENT_NAME_LOADING, loading: false});
    dispatch(showInfo(`Could not set client name.  ${getErrorMessage(error)}`));
  });
};

export const getCopyData = (checkedItem, isProxy, isJustForData, allowedActions) => dispatch => {
  const {fsRootID, contentID, key} = checkedItem;
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'getCopyData', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/copy/data`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID,
      file_name: key
    }
  })
  .done(res => {
    const {warnMsg, proxyCopyAvailable} = res;
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'getCopyData', loading: false});
    if (typeof proxyCopyAvailable === 'boolean') {
      if (isJustForData) {
        dispatch({type: STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL, data: res});
      } else if (isProxy) {
        dispatch({type: STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL, data: res, isShow: true});
      } else {
        dispatch({type: STORAGE_MNG_COPY_SHOW_MODAL, data: res, isShow: true, allowedActions: allowedActions});
      }
    } else if (warnMsg) {
      dispatch(showWarning(warnMsg));
    } else {
      dispatch(showInfo('We had trouble getting data to copy.'));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'getCopyData', loading: false});
    dispatch(showError(`Could not get data to copy.  ${getErrorMessage(error)}`));
  });
};

export const closeCopyModal = () => ({type: STORAGE_MNG_COPY_SHOW_MODAL, isShow: false});

export const copyFsItem = (proxyCopySelected, destFsRootID, destPath, newFileName, isCopyToQOD, schedulerData, destOfficeFlag, encCheckboxChecked, encPassword) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID, key, name, officeFlag, memfisWoID} = checkedItems[0];
  //const email = getState().user.email || '';
  const actionType = proxyCopySelected ? (/*['edms\\mdamore','edms\\rmorozov-a'].indexOf(email.toLowerCase())>=0*/ true ? FS_ACTION_TYPE__GENERAL_PROXY_COPY : FS_ACTION_TYPE__PROXY_COPY) : ( Number.isInteger(destOfficeFlag) && officeFlag !== destOfficeFlag ? FS_ACTION_TYPE__INTEROFFICE_COPY_PORTAL : FS_ACTION_TYPE__SIMPLE_COPY)
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'copyFsItem', loading: true});
  const actionParams = memfisWoID ? {
        ContentIDs: [String(contentID)],
        SOURCE: key,
        DESTINATION: destPath,
        FILE_NAME: newFileName,
        WO_MEMFIS: memfisWoID,
        DECRYPT_REQUIRED: encCheckboxChecked,
        PASSWORD: encCheckboxChecked === true ? encPassword : null
    } : {
        ContentIDs: [String(contentID)],
        SOURCE: key,
        DESTINATION: destPath,
        FILE_NAME: newFileName,
        DECRYPT_REQUIRED: encCheckboxChecked,
        PASSWORD: encCheckboxChecked === true ? encPassword : null
  };
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: actionType,
      fsRootID,
      fsRootID2: destFsRootID,
      parameters: actionParams,
      ...schedulerData
    })
  })
  .done((response) => {
    if (isCopyToQOD) {
      dispatch(showCopyToQodModal(false));
    } else {
      dispatch(closeCopyModal());
    }
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'copyFsItem', loading: false});

    const {ActionID} = response || {};
    if (isCopyToQOD) {
      dispatch(showSuccessPopup({
        actionId: ActionID,
        actionType: 'QOD proxy'
      }));
    } else {
      dispatch(showSuccessPopup({
        actionId: ActionID,
        actionType: 'Copy'
      }));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'copyFsItem', loading: false});
    dispatch(showError(`Could not copy ${name}.  ${getErrorMessage(error)}`));
  });
};

/*#region Permissions */
export const sendUpdatedPermissions = (item, symbolic) => (dispatch) => {
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name: 'setContentPermissions',
    loading: true
  });
  const numeric = convertSymbolicToNumeric(symbolic);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__SET_CONTENT_PERMISSIONS_PORTAL,
      fsRootID: item.fsRootID,
      parameters: {
        ContentIDs: [String(item.contentID)],
        PERMISSIONS: numeric,
      }
    })
  })
    .done(() => {
      dispatch({
        type: STORAGE_MNG_SET_CONTENT_PERMISSIONS_SUCCESS,
        permissionsSymbolic: symbolic,
      });
      dispatch(showSuccess('Permissions were set successfully'));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name: 'setContentPermissions',
        loading: false
      });
      dispatch(showError(`Could not set content permissions.  ${getErrorMessage(error)}`));
    });
};

export const sendUpdatedGroup = (item, newGroupName) => (dispatch) => {
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name: 'setContentGroup',
    loading: true
  });

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__SET_CONTENT_GROUP_PORTAL,
      fsRootID: item.fsRootID,
      parameters: {
        ContentIDs: [String(item.contentID)],
        GROUP: newGroupName,
      }
    })
  })
    .done(() => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name: 'setContentGroup',
        loading: false,
      });
      dispatch(showSuccess('Group was set successfully'));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name: 'setContentGroup',
        loading: false
      });
      dispatch(showError(`Could not set group.  ${getErrorMessage(error)}`));
    });
};
/*#endregion */

/*#region Runner Actions */
export const executeScriptRunnerAction = (actionType, actionName, labels, actionData) => dispatch => {
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeScriptRunnerAction', loading: true});
  const {isOffice, FsRootID, RunnerID} = actionData;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request${actionName === 'stop' ? '' : '?non_filtered=true'}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType,
      fsRootID: isOffice ? undefined : FsRootID,
      parameters: {
        RUNNER_ID: RunnerID
      }
    })
  })
  .done(statusBit => {
    if (typeof statusBit === 'number') {
      dispatch({type: STORAGE_MNG_SCRIPT_RUNNER_ACTION_STATUS_BIT, statusBit, actionData});
    } else {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeScriptRunnerAction', loading: false});
    }
    dispatch(showSuccess(`${actionName === 'stop' ? 'Stopped' : 'It started'} successfully!`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeScriptRunnerAction', loading: false});
    dispatch(showError(`Could not ${actionName} ${labels}.  ${getErrorMessage(error)}`));
  });
};

export const executeFileSystemRunnerAction = (actionType, actionName, fsRootID, fsRootLabel) => dispatch => {
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeFsRunnerAction', loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request${actionName === 'stop' ? '' : '?non_filtered=true'}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType,
      fsRootID
    })
  })
  .done(statusBit => {
    if (typeof statusBit === 'number') {
      dispatch({type: STORAGE_MNG_FS_RUNNER_ACTION_STATUS_BIT, statusBit, fsRootID});
    } else {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeFsRunnerAction', loading: false});
    }
    dispatch(showSuccess(`${actionName === 'stop' ? 'Stopped' : 'It started'} successfully!`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeFsRunnerAction', loading: false});
    dispatch(showError(`Could not ${actionName} ${fsRootLabel}.  ${getErrorMessage(error)}`));
  });
};

export const executeMachineRunnerAction = (actionType, actionName, fsRootID, machineName) => dispatch => {
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeMachineRunnerAction', loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request${actionName === 'stop' ? '' : '?non_filtered=true'}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType,
      fsRootID,
      parameters: {
        MACHINE_NAME: machineName
      }
    })
  })
  .done(statusBit => {
    if (typeof statusBit === 'number') {
      dispatch({type: STORAGE_MNG_MACHINE_RUNNER_ACTION_STATUS_BIT, statusBit, machineName});
    } else {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeMachineRunnerAction', loading: false});
    }
    dispatch(showSuccess(`${actionName === 'stop' ? 'Stopped' : 'It started'} successfully!`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'executeMachineRunnerAction', loading: false});
    dispatch(showError(`Could not ${actionName} ${machineName}.  ${getErrorMessage(error)}`));
  });
};

export const executeAllOfficesAction = (actionType, runnerID) => dispatch => {
  const name = 'executeAllOfficesAction';
  const isStartRequest = actionType === FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER;
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true,
  });
  const url = `${config.apiUrl}/v1/file_system/action_request${isStartRequest ? '?non_filtered=true' : ''}`;
  return $.ajax({
      method: 'POST',
      url,
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        actionType,
        parameters: {
          RUNNER_ID: String(runnerID),
        }
      }),
    })
    .done(statusBits => {
      if (typeof statusBits === 'number') {
        dispatch({
          type: STORAGE_MNG_ALL_OFFICES_ACTION_STATUS_BIT,
          statusBits,
        });
      } else {
        dispatch({
          type: STORAGE_MNG_SET_LOADING,
          name,
          loading: false
        });
      }
      dispatch(showSuccess(`${isStartRequest ? 'Start' : 'Stop'} request sent.`));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showError(`Error when sending ${isStartRequest ? 'start' : 'stop'} request.  ${getErrorMessage(error)}`));
    });
};

export const executeOfficeAction = (actionType, officeFlag) => dispatch => {
  const name = 'executeOfficeAction';
  const isStartRequest = actionType === FS_ACTION_TYPE__START_OFFICE_SERVICE_RUNNERS;
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/file_system/action_request`,
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        actionType,
        parameters: {
          OFFICE_FLAG: String(officeFlag),
        }
      })
    })
    .done(statusBits => {
      if (typeof statusBits === 'number') {
        dispatch({type: STORAGE_MNG_OFFICE_ACTION_STATUS_BIT, statusBits, officeFlag});
      } else {
        dispatch({
          type: STORAGE_MNG_SET_LOADING,
          name,
          loading: false
        });
      }
      dispatch(showSuccess(`${isStartRequest ? 'Start' : 'Stop'} request sent.`));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showError(`Error when sending ${isStartRequest ? 'start' : 'stop'} request.  ${getErrorMessage(error)}`));
    });
}

/*#endregion */

export const closeModalAddFileToWO = () => ({type: STORAGE_MNG_ADD_FILE_TO_WO_SWITCH_MODAL, show: false});

export const showModalAddFileToWO = () => ({type: STORAGE_MNG_ADD_FILE_TO_WO_SWITCH_MODAL, show: true});

export const copyForAddFileToWO = (data) => (dispatch) => {
  const loadingName = 'copy';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(data),
  }).done((res) => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_SET_EMPTY_DISNEY_INFO});
    const {ActionID} = res || {};
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: "Copy"
    }));
  }).fail((error) => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_SET_EMPTY_DISNEY_INFO});
    dispatch(showError(`Could not copy action.  ${getErrorMessage(error)}`));
  })
};

export const addFileToWO = (data, isDirectoryAsRootChecked, isCreateSymlinkChecked, replacementTypeValue, proxyCheckedWorkOrders, requestProxyId, creatorActionId, proxyMuxRequired) => (dispatch, getState) => {
  const {addFileToWoModalState: {currentDestination, currentCopyDestination, isAssignNewWo, newWOMemfis,
    isCreateQODProxyChecked, replacementData: {isReplacement}, AMBCurrent, runLKFSanalysis, ingestFiles}, checkedItems,
    copyModalData, addToWoIngestData
  } = getState().storageManager;
  const {availableWOmemfises} = getState().storageManager.copyToQodModalState;
  const loadingName = 'addFileToWO';

  if (ingestFiles) {
    data.ingestFiles = ingestFiles.filter(item => item.selected).map(item => item.content_id);
  }
  if (addToWoIngestData) {
    data.ingestData = addToWoIngestData;
    dispatch(setAddToWoIngestOperationTypes(null));
  }

  const markedWoMemfises = availableWOmemfises
    .filter(item => Array.isArray(proxyCheckedWorkOrders) && proxyCheckedWorkOrders.includes(item.WOID))
    .map(item => item.RequestID);

  data.parameters.CREATE_SYMLINK = isCreateSymlinkChecked;
  data.parameters.IS_REPLACEMENT = isReplacement;
  data.parameters.CREATE_PROXY = isCreateQODProxyChecked;

  if (currentDestination.contentID) {
    data.parameters.DEST_CONTENT_ID = currentDestination.contentID;
  }
  if (isReplacement) {
    data.parameters.REPLACEMENT_TYPE = replacementTypeValue;
  }
  if (isAssignNewWo) {
    data.parameters.WO_MEMFIS_2 = newWOMemfis;
  }
  if (currentCopyDestination.key) {
    data.fsRootID2 = currentCopyDestination.fsRootID;
    data.parameters.COPY_DESTINATION = currentCopyDestination.key;
    data.parameters.COPY_DEST_CONTENT_ID = currentCopyDestination.contentID || null;
  }
  if (isDirectoryAsRootChecked) {
    data.parameters.CREATE_WO_SYMLINK_ROOT_DIRECTORY = true;
  }
  if (runLKFSanalysis) {
    data.parameters.RUN_LKFS_ANALYSIS = true;
    data.parameters.AMB_SETTINGS = AMBCurrent;
  }
  if (isCreateQODProxyChecked) {
    data.parameters.RELATED_PROXY_REQUEST_IDS = markedWoMemfises.join(' ');
    data.parameters.QOD_PROXY_ID = requestProxyId;
    if (requestProxyId > 0 && checkedItems.length === 1) {
      data.parameters.PROXY_FILE_NAME = 'pid' + requestProxyId + '_' + ((copyModalData || {}).newFileName || checkedItems[0].name).replace(/[.]mov$/, proxyMuxRequired && ((copyModalData || {}).newFileName || checkedItems[0].name).indexOf("_muxed")<0 ? "_muxed.mov" : ".mov");;
    }
    data.parameters.PROXY_REQUEST_ID = (availableWOmemfises.find(({Marked}) => !!Marked) || {}).RequestID;
    if (creatorActionId) {
      data.creatorActionId = creatorActionId;
    }
  }
  const woSteps = data.woSteps;
  if (woSteps && woSteps.length > 0) {
    delete data.woSteps;
  }

  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(data),
  }).done((res) => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    const {ActionID, AssetID} = res || {};
    if ((AssetID || ActionID) && woSteps && woSteps.length > 0) {
      dispatch(sendWoSteps(AssetID, woSteps, data.parameters.WO_MEMFIS, ActionID));
    }
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: "Add to Work Order"
    }));
  }).fail((error) => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add file to WO#.  ${getErrorMessage(error)}`));
  })
};

export const setSelectedRoot = selection => ({type: STORAGE_MNG_ADD_FILE_TO_WO_SET_SELECTED_ROOT, selection})

export const chooseDestinationToAddFile = ({key, fsRootID, contentID}, isValid) => (dispatch, getState) => {
  const {addFileToWoModalState: {isInitialScreen}} = getState().storageManager;
  isInitialScreen ?
    dispatch(confirmChooseDestinationToAddFile(key, fsRootID, contentID, isValid)) :
    dispatch(confirmChooseCopyDestinationToAddFile(key, fsRootID, contentID, isValid));
};

export const makeValidateDirectoryAjax = (destination, root_id, content_id) => (dispatch, getState) => {
  const {addFileToWoModalState: {isDirectoryAsRootChecked, currentWOMemfis}} = getState().storageManager;
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/symlink/validate_destination`,
    data: {
      root_id,
      content_id,
      wo_memfis: currentWOMemfis,
      destination,
      create_wo_root_directory: isDirectoryAsRootChecked || false,
    },
  }).done((res) => {
    if (res.data) {
      dispatch(changeAddToWODestinationIsAvailable(true, res.message));
    } else {
      dispatch(changeAddToWODestinationIsAvailable(false, res.message));
    }
  }).fail((error) => {
    dispatch(showError(`Could not validate destination.  ${getErrorMessage(error)}`));
  });
}

const changeAddToWODestinationIsAvailable = (isValid, message) => {
  return ({
    type: STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_DESTINATION_AVAILABLE,
    isValid,
    message
  });
}

const confirmChooseDestinationToAddFile = (key, fsRootID, contentID, isValid) => {
  return ({
    type: STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_DESTINATION,
    folder: {
      key,
      fsRootID,
      contentID
    },
    isValid
  });
}

const confirmChooseCopyDestinationToAddFile = (key, fsRootID, contentID, isValid) => {
  return ({
    type: STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_COPY_DESTINATION,
    folder: {
      key,
      fsRootID,
      contentID
    },
    isValid
  });
}

export const selectMemfisWoToAddFile = (woMemfisId, fsRootID) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const fileName = checkedItems.length === 1 ? checkedItems[0].key : checkedItems.map(({key}) => `"${key}"`).join(',');
  const sourceRootId = checkedItems[0].fsRootID;
  dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_WO_MEMFIS, value: woMemfisId});
  const loadingName = 'addFileToWO';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  return getAssetFinalDestAsWOSymlinkRootContent(woMemfisId, sourceRootId, fsRootID, fileName)
    .done(res => {
      if (res && res.wo_steps) {
        dispatch({type: STORAGE_MNG_WO_STEPS, data: res.wo_steps});
        //dispatch(showWoStepsModal(true));
      } else {
        dispatch(setWoStepsEmpty(true));
      }
      dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
      dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_AUTO_SAVE_REPLACEMENT, ...res})
      if (res && res.frame_rates) {
        dispatch({ type: STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE, data: res.frame_rates || {} });
      }
      if (res && res.show_ingest_settings && res.show_file_selection && !res.ingest_files) {
        dispatch(showError("Could not get the files from QOD."));
      }

      if (res && res.existed_symlink_root_directory) {
        dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_AUTO_CHOOSE_DESTINATION, ...res});

        const {addFileToWoModalState: {currentDestination: {key, fsRootID}, initialDestination, currentCopyDestination}} = getState().storageManager;
        const {contentID} = checkedItems[0];
        if (initialDestination.fsRootID === currentCopyDestination.fsRootID) {
          dispatch(makeValidateDirectoryAjax(key, fsRootID, contentID));
        }
        dispatch(validateFileName(res.existed_symlink_root_directory));
      } else {
        if (res.default_symlink_root_directory) {
          dispatch(validateFileName(res.default_symlink_root_directory));
        }
      }
      if (res.default_copy_destination) {
        dispatch(validateFileName(res.default_copy_destination, 'default_copy_destination'));
      } else {
        dispatch({
          type: STORAGE_MNG_ADD_FILE_TO_WO_SET_AUTO_CHOOSE_DESTINATION,
          res: {},
          validationType: 'default_copy_destination'
        });
      }
    })
    .fail(error => {
      dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
      dispatch(showError("Could not get the asset's final destination.  " + (getErrorMessage(error) || '')));
    });
};

export const setAssignNewWo = (isChecked) => dispatch => {
  dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_SET_ASSIGN_NEW_WO, isChecked})
}

export const setNewWO = (newWOMemfis) => dispatch => {
  dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_SET_NEW_WO, newWOMemfis})
}

export const resetAddToWoDestination = () => (dispatch, getState) => {
  const {checkedItems, addFileToWoModalState: {initialDestination: {key, fsRootID}, default_symlink_root_directory_body, default_symlink_root_directory_tail}} = getState().storageManager;
  const {contentID} = checkedItems[0];



  dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_RESET_DESTINATION, key, default_symlink_root_directory_body, default_symlink_root_directory_tail});
  dispatch(makeValidateDirectoryAjax(key, fsRootID, contentID));
}

export const selectMemfisWoLKFS = (woMemfisId, fsRootID, onSuccess) => {
  return getAssetFinalDestination2(woMemfisId, fsRootID).done((res) => {
    const isValid = ajv.validate({
      "type": "object",
      "properties": {
        "data": {
          "type": "object",
          "properties": {
            "content": {
              "type": "object",
              "properties": {
                "key": {
                  "type": "string"
                },
                "fsRootID": {
                  "type": "integer"
                }
              }
            },
          }
        }
      }
    }, res);

    if (isValid) {
      onSuccess(res);
    } else {
      console.error('Returned data is not valid');
    }
  }).fail((error) => console.log(error.responseJSON.message));
};

export const changeStatusToAddFile = (newScreen, status) => ({type: STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_STATUS, newScreen, status});

export const onGotoScreenToAddFile = (newScreen, fsRoots) => ({type: STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN, newScreen, fsRoots});

export const toggleNewFolderCheckboxModalAddFileToWO = ( checkBoxState ) => (dispatch, getState) => {
  const {checkedItems, addFileToWoModalState: {currentDestination: {key, fsRootID}, currentCopyDestination}, fsRoots} = getState().storageManager;
  const {contentID} = checkedItems[0];

  const copyDestinationRoot = fsRoots.find(r => r.fsRootID === currentCopyDestination.fsRootID).fsRootName;

  dispatch(changeNewFolderCheckboxModalAddFileToWO(checkBoxState));
  dispatch(makeValidateDirectoryAjax(key || copyDestinationRoot, fsRootID, contentID));
}

export const toggleCreateProxyCheckboxModalAddFileWO = ( checkBoxState ) => (dispatch, getState) => {
  dispatch(changeCreateQODProxyChecboxModalAddFileToWO(checkBoxState));
}

export const changeNewFolderCheckboxModalAddFileToWO = ( checkBoxState ) => ({type: STORAGE_MNG_ADD_FILE_TO_WO_NEW_FOLDER_CHECKBOX_CHANGE, isChecked: checkBoxState});

const changeCreateQODProxyChecboxModalAddFileToWO = ( checkBoxState ) => {
  return {
    type: STORAGE_MNG_ADD_FILE_TO_WO_CREATE_QOD_PROXY_CHECKBOX_CHANGE,
    isChecked: checkBoxState,
  };
};

export const toggleCaptionsSendToQod = ( checkBoxState ) => (dispatch, getState) => {
  dispatch(changeCaptionsSendToQodChecboxModalAddFileToWO(checkBoxState));
}

const changeCaptionsSendToQodChecboxModalAddFileToWO = ( checkBoxState ) => {
  return {
    type: STORAGE_MNG_ADD_FILE_TO_WO_SEND_CAPTIONS_TO_QOD_CHECKBOX_CHANGE,
    isChecked: checkBoxState,
  };
};

export const setAddFileToWOSuffix = addFileToWOSuffix => ({
  type: STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX,
  addFileToWOSuffix
});

export const setAddFileToWOSuffixFile = addFileToWOSuffixFile => ({
    type: STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX_FILE,
    addFileToWOSuffixFile
});

export const loadActionPermissions = () => (dispatch) => {
  makeActionPermissionsAjax()
    .done(res => {
      dispatch({type: STORAGE_MNG_LOAD_ACTION_PERMISSIONS, res});
    })
    .fail(error => {
      dispatch(showError(`Could not get action permissions.  ${getErrorMessage(error)}`));
    });
}

const makeActionPermissionsAjax = () => $.ajax({
  method: 'GET',
  url: `${config.apiUrl}/v1/file_system/action_type_properties`,
});

export const executeDemuxAction = (fsRootID, ContentIDs) => dispatch => {
  const name = 'executeDemuxAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/file_system/action_request`,
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        actionType: FS_ACTION_TYPE__DEMUX,
        fsRootID,
        parameters: {
          ContentIDs,
        }
      })
    })
    .done(res => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showSuccess('DEMUX action has been successfully completed.'));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showError(`Error when sending DEMUX action request.  ${getErrorMessage(error)}`));
    });
}

export const executePSEanalysisAction = (pseType, companionFile, woMemfis, isSendToQOD) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];
  const name = 'executePSEanalysisAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  let parameters = {
    SOURCE_CONTENT_ID: contentID,
    PSE_TYPE: pseType,
    WO_MEMFIS: woMemfis,
    SEND_TO_QOD: isSendToQOD
  };
  if (companionFile > 0) {
    parameters['PSE_COMPANION_FILE'] = companionFile;
  }

  return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/file_system/action_request`,
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        actionType: FS_ACTION_TYPE__PSE_ANALYSIS_PORTAL,
        fsRootID,
        parameters: parameters
      })
    })
    .done(res => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      //dispatch(showSuccess('PSE Analysis action has been successfully sent.'));
      dispatch(showSuccessPopup({
        actionId: (res || {}).ActionID,
        actionType: 'PSE Analysis'
      }));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showError(`Error when sending PSE Analysis action request.  ${getErrorMessage(error)}`));
    });
}

export const clearAddToWoModal = () => ({type: STORAGE_MNG_ADD_FILE_TO_WO_CLEAR});

export const showCreateSymlinkModal = (isShow) => ({
  type: STORAGE_MNG_CREATE_SYMLINK_SHOW_MODAL,
  isShow
});

export const executeCreateSymlinkAction = (DESTINATION) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];
  const name = 'executeCreateSymlinkAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/file_system/action_request`,
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        actionType: FS_ACTION_TYPE__CREATE_SYMLINK_PORTAL,
        fsRootID,
        parameters: {
          ContentIDs: [`${contentID}`],
          DESTINATION,
        }
      })
    })
    .done(res => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showCreateSymlinkModal(false));
      dispatch(showSuccess('Create Symlink action has been successfully completed.'));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showCreateSymlinkModal(false));
      dispatch(showError(`Error when sending Create Symlink action request.  ${getErrorMessage(error)}`));
    });
}

export const showMediaInfoModal = (isShow) => ({
  type: STORAGE_MNG_MEDIA_INFO_SHOW_MODAL,
  isShow
});

export const showUnlinkModal = (isShow) => ({
  type: STORAGE_MNG_UNLINK_SHOW_MODAL,
  isShow
});

export const loadMediaInfo = (item, isForced) => dispatch => {
  const {fsRootID, contentID} = item;
  const name = 'loadMediaInfoAction';

  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/mediainfo?fs_root_id=${fsRootID}&content_id=${contentID}&force=${isForced}`,
  }).done(res => {
    dispatch({type: STORAGE_MNG_SET_MEDIA_INFO, mediaInfo: res});
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
  }).fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending Media Info request.  ${getErrorMessage(error)}`));
  });
}

export const clearMediaInfo = () => dispatch => {
  dispatch({type: STORAGE_MNG_SET_MEDIA_INFO, mediaInfo: {}});
}

export const getQodRequestViaFile = item => dispatch => {
  const {fsRootID, contentID} = item;

  const name = 'sendingMediaInfoToQOD';

  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/qod/mediainfo`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID
    }
  }).fail(error => {
    dispatch(showError(`Could not get the QOD request data.  ${getErrorMessage(error)}`));
  }).always(() => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
  });
}

export const getQodRequestByID = id => dispatch => {
  const name = 'sendingMediaInfoToQOD';

  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/qod/mediainfo`,
    data: {
      qod_request_id: id
    }
  }).fail(error => {
    dispatch(showError(`Could not get the QOD request data.  ${getErrorMessage(error)}`));
  }).always(() => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
  });
}

export const sendMediaInfoToQOD = (item, qodRequestId) => dispatch => {
  const {fsRootID, contentID} = item;

  const name = 'sendingMediaInfoToQOD';

  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/qod/mediainfo`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID,
      qod_request_id: qodRequestId
    }
  }).always(() => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
  }).done(() => {
    dispatch(showSuccess('MediaInfo has been successfully sent to QOD.'));
  }).fail(error => {
    dispatch(showError(`Error when sending Media Info to QOD.  ${getErrorMessage(error)}`));
  });
}

export const getFaspexAccounts = () => dispatch => {
  const name = 'executeGetFaspexAccounts';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
      method: 'GET',
      url: `${config.apiUrl}/v1/aspera_faspex/user/accounts`
    });
}

export const executeUploadToAsperaAction = (fsRootID, ContentIDs, PACKAGE_TITLE, RECIPIENT, FASPEX_ACCOUNT_ID) => dispatch => {
  const name = 'executeUploadToAsperaAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/file_system/action_request`,
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        actionType: FS_ACTION_TYPE__UPLOAD_TO_ASPERA,
        fsRootID,
        parameters: {
          ContentIDs,
          PACKAGE_TITLE,
          RECIPIENT,
          FASPEX_ACCOUNT_ID
        }
      })
    })
    .done(res => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showUploadToAsperaModal(false));
      dispatch(showSuccess('Upload to Aspera action has been successfully completed.'));
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showUploadToAsperaModal(false));
      dispatch(showError(`Error when sending Upload to Aspera action request.  ${getErrorMessage(error)}`));
    });
}

export const showCopyToQodModal = (isShow) => ({
  type: STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL,
  isShow
});

export const getCopyToQodInfo = item => (dispatch, getState) => {
  const name = 'getCopyToQodInfo';
  //const email = getState().user.email || '';
  const data = /*['edms\\mdamore','edms\\rmorozov-a'].indexOf(email.toLowerCase())>=0*/ true
    ? {
        fs_root_id: item.fsRootID,
        content_id: item.contentID,
        is_general_proxy_copy: true
      }
    : {
        fs_root_id: item.fsRootID,
        content_id: item.contentID
      };
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/content_info`,
    data: data
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch({type: STORAGE_MNG_SET_QOD_INFO, data: res});
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Unable to get information for QOD Copy action.  ${getErrorMessage(error)}`));
  });
};

export const doShareQodProxy = (checkedWorkOrders) => (dispatch, getState) => {
  const {copyToQodModalState, checkedItems} = getState().storageManager;
  const {availableWOmemfises} = copyToQodModalState;
  const copyToQodInfoList = getCopyToQodInfoList(copyToQodModalState, checkedItems);

  const requestID = (copyToQodInfoList.find(item => item.requestID) || {}).requestID;
  const woMemfisArr = copyToQodInfoList.map(item => item.woMemfis).filter(Boolean);

  const markedWoMemfises = availableWOmemfises
    .filter(item => checkedWorkOrders.includes(item.WOID) && !woMemfisArr.includes(item.WOMemfis))
    .map(item => item.RequestID).filter(Boolean);

  if (markedWoMemfises.length === 0) {
    dispatch(showWarning('Need select at least one work order for share proxy', 'Warning', 2000));
    return;
  }

  const name = 'doShareQodProxy'
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/qod_proxy/${requestID}/share_proxy`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      request_list_id: markedWoMemfises
    })
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showCopyToQodModal(false));
    dispatch(showSuccess('Current work order proxy successfully linked with selected work orders.'));
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending "Set Proxy from Another Proxy" request.  ${getErrorMessage(error)}`));
  });
}

export const getAvailableWoMemfises = (woMemfisFromAddToWo) => (dispatch, getState) => {
  const {copyToQodModalState, checkedItems} = getState().storageManager;
  const copyToQodInfoList = getCopyToQodInfoList(copyToQodModalState, checkedItems);

  const requestID = (copyToQodInfoList.find(item => item.requestID) || {}).requestID;
  const woMemfisArr = copyToQodInfoList.map(item => item.woMemfis).filter(Boolean);
  if (!woMemfisArr.length) {
    woMemfisArr.push(woMemfisFromAddToWo);
  }

  if (woMemfisFromAddToWo || requestID) {
    const name = 'getAvailableWoMemfises';
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: true
    });

    return $.ajax({
      method: 'GET',
      url: `${config.apiUrl}/v1/file_system/qod_proxy/work_orders`,
      data: {
        request_id: requestID,
        wo_memfis: woMemfisFromAddToWo,
        root_id: (checkedItems[0] || {}).fsRootID,
        content_id: (checkedItems[0] || {}).contentID,
      }
    })
    .done(res => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });

      const availableQodWorkOrderForSelectedFile = res && res.length > 0 ? res.find(item => item.WOType !== 'ARTWORK' && woMemfisArr.includes(item.WOMemfis)) : null;
      const ownProxyName = availableQodWorkOrderForSelectedFile ? availableQodWorkOrderForSelectedFile.ProxyName : null;

      dispatch({
        type: STORAGE_MNG_SET_QOD_AVAILABLE_WOMEMFISES,
        availableWOmemfises: res || [],
      });
      dispatch({
        type: STORAGE_MNG_SET_QOD_PROXIES,
        ownProxyName,
      });
    })
    .fail(error => {
      dispatch({
        type: STORAGE_MNG_SET_LOADING,
        name,
        loading: false
      });
      dispatch(showError(`Error when sending Get Available WO# request.  ${getErrorMessage(error)}`));
    });
  }
  return new Promise((resolve, reject) => {
    reject('Not found QOD request for checked item');
  });
}

export const saveMarkedWOAndAddFileToWO = (checkedWorkOrders, isDirectUploadProxy, isMpegDashProxy) => async (dispatch, getState) => {
  const { addFileToWoModalState, checkedItems } =
    getState().storageManager;
  const {
    currentWOMemfis,
    currentDestination,
    addFileToWOSuffix,
    addFileToWOSuffixFile,
    isDirectoryAsRootChecked,
    isCreateSymlinkChecked,
    replacementTypeValue,
  } = addFileToWoModalState;

  const submitData = {
    actionType: FS_ACTION_TYPE__ADD_TO_MEMFIS_WO,
    fsRootID: checkedItems[0].fsRootID,
    parameters: {
      ContentIDs: checkedItems.map(({ contentID }) => String(contentID)),
      SOURCE:
        checkedItems.length === 1
          ? checkedItems[0].key
          : checkedItems.map(({ key }) => `"${key}"`).join(","),
      WO_MEMFIS: currentWOMemfis,
      DESTINATION: currentDestination.key,
      ADD_WO_MEM_SUFFIX: addFileToWOSuffix,
      ADD_WO_MEM_SUFFIX_FILE: addFileToWOSuffixFile,
    },
  };

  dispatch(createPendingRequestProxy(checkedWorkOrders, null, isDirectUploadProxy, isMpegDashProxy))
    .then((res) => {
      if (res && res.request_proxy_id > 0) {
        dispatch(
          addFileToWO(
            submitData,
            isDirectoryAsRootChecked || false,
            isCreateSymlinkChecked,
            replacementTypeValue,
            checkedWorkOrders,
            res.request_proxy_id,
            res.creator_action_id,
            res.proxy_mux_required
          )
        );
      } else {
        dispatch(showError('Failed create QOD proxy, we cannot start link to Work Order'));
      }
    })
    .catch((error) => showError(getErrorMessage(error)))
    .finally(() => {
      dispatch(showCopyToQodModal(false));
      dispatch(closeModalAddFileToWO());
    });
};

export const createPendingRequestProxy = (checkedWorkOrders, checkedArtworkRequests, isDirectUploadProxy, isMpegDashProxy) => (dispatch, getState) => {
  const {isCreateQODProxyChecked, currentWOMemfis} = getState().storageManager.addFileToWoModalState;
  const {copyToQodModalState, checkedItems} = getState().storageManager;
  const {availableWOmemfises} = copyToQodModalState;
  const copyToQodInfoList = getCopyToQodInfoList(copyToQodModalState, checkedItems);

  let qodRequestId = (copyToQodInfoList.find(item => item.requestID) || {}).requestID;
  const woMemfisArr = copyToQodInfoList.map(item => item.woMemfis).filter(Boolean);

  let markedWoMemfises = availableWOmemfises
    .filter(item => checkedWorkOrders.includes(item.WOID) && !woMemfisArr.includes(item.WOMemfis))
    .map(item => item.RequestID).filter(Boolean);

  // for future - do not delete (alex)
  /*if (markedWoMemfises.length === 0) {
    return new Promise((resolve, reject) => {
      reject('Need select at least one work order for create proxy');
    });
  }*/

  if (isCreateQODProxyChecked) {
    // temp solution
    const choosedWorkOrder = availableWOmemfises.find(item => item.WOMemfis === currentWOMemfis);
    qodRequestId = choosedWorkOrder ? choosedWorkOrder.RequestID : null;
  }

  if (checkedArtworkRequests && checkedArtworkRequests.length > 0) {
    qodRequestId = checkedArtworkRequests[0];
    markedWoMemfises = checkedArtworkRequests.splice(1);
  }

  if (qodRequestId) {
    const name = 'createPendingRequestProxy'
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: true
    });

    const {checkedItems} = getState().storageManager;
    const {fsRootID, contentID} = (checkedItems[0] || {});

    let proxyType = null;
    if (isMpegDashProxy) {
      proxyType = 'dash';
    } else if (isDirectUploadProxy) {
      proxyType = 'direct';
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: `${config.apiUrl}/v1/file_system/qod_proxy/related_proxies?request_id=${qodRequestId}&fs_root_id=${fsRootID}&content_id=${contentID}`,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
          proxyType,
          relatedRequestIds: isCreateQODProxyChecked && markedWoMemfises.length === 0 ? [] : markedWoMemfises
        })
      })
      .done(res => {
        dispatch({
          type: STORAGE_MNG_SET_LOADING,
          name,
          loading: false
        });
        dispatch(showCopyToQodModal(false));
        dispatch(showSuccess('"Save Selected Work Orders" request has been successfully completed.'));
        resolve(res);
      })
      .fail(error => {
        dispatch({
          type: STORAGE_MNG_SET_LOADING,
          name,
          loading: false
        });
        dispatch(showCopyToQodModal(false));
        reject(`Error when sending "Save Selected Work Orders" request.  ${getErrorMessage(error)}`);
      });
    });
  }
  return new Promise((resolve, reject) => {
    reject('Not found QOD request for checked item');
  });
}

const validateFileName = (file_name, validationType) => (dispatch, getState) => {
  const {fsRoots} = getState().storageManager;
  const name = 'validateFileName'
  const root_id = fsRoots.find(i => file_name.startsWith(i.fsRootName)).fsRootID;

  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/validate_file_name`,
    data: {
      root_id,
      file_name
    }
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch({
      type: STORAGE_MNG_ADD_FILE_TO_WO_SET_AUTO_CHOOSE_DESTINATION,
      res,
      validationType
    })
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending "Validate File Name" request.  ${getErrorMessage(error)}`));
  });
}

export const showChecksumReportModal = () => ({
  type: STORAGE_MNG_CHECKSUM_REPORT_TOGGLE_MODAL, isShow: true
});

export const closeChecksumReportModal = () => ({
  type: STORAGE_MNG_CHECKSUM_REPORT_TOGGLE_MODAL, isShow: false
});

export const createChecksumReport = (items, destination, reportFileName, checksumType, isRecalculate, attachmentQodData) => dispatch => {
  const loadingName = 'createChecksumReport';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});

  let parameters = {
    ContentIDs: items.map(item => String(item.contentID)),
    DESTINATION: destination.key,
    DEST_CONTENT_ID: destination.contentID,
    REPORT_FILE_NAME: reportFileName,
    CHECKSUM_TYPE: checksumType,
    RECALCULATE: isRecalculate
  };
  if (attachmentQodData) {
    parameters.ACTION_DATA_JSON = JSON.stringify(attachmentQodData);
  }

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__CHECKSUM_REPORT,
      fsRootID: items[0].fsRootID,
      parameters: parameters
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showSuccess(`Checksum report action successfully created`));
    dispatch(closeChecksumReportModal());
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not create checksum report.  ${getErrorMessage(error)}`));
  });
};

export const getAssociatedFsRootsForWO = () => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {memfisWoID} = checkedItems[0];
  const loadingName = 'getAssociationsForWO';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/associated_fs_roots?wo_memfis=${memfisWoID}`
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get associated FS roots.  ${getErrorMessage(error)}`));
  });
};

export const deleteSymlink = (item) => dispatch => {
  const loadingName = 'deleteSymlink';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__DELETE_SYMLINK_PORTAL,
      fsRootID: item.fsRootID,
      parameters: {
        OLD_SYMBOLINK_PATH: item.key
      }
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showSuccess(`'Delete Symlink' action successfully created`));
    dispatch({
      type: STORAGE_MNG_CHANGE_SYMLINK_RELATIVE_STATUS,
      item,
      relativeStatus: SYMLINK_RELATIVE_STATUS__SOURCE_DELETED
    });
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not delete symlink.  ${getErrorMessage(error)}`));
  });
};

export const showActionProgress = (action) => ({type: STORAGE_MNG_TOGGLE_ACTION_PROGRESS, data: action});
export const closeActionProgress = () => ({type: STORAGE_MNG_TOGGLE_ACTION_PROGRESS, data: null});

export const getActionProgress = (actionID) => dispatch => {
  dispatch({type: STORAGE_MNG_LOADING_ACTION_PROGRESS, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions/${actionID}/details`
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_LOADING_ACTION_PROGRESS, loading: false});
    if (res) {
      dispatch({type: STORAGE_MNG_SET_ACTION_PROGRESS, actionID, progress: res ? res.progress : 0, details: res});
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_LOADING_ACTION_PROGRESS, loading: false});
  });
};

export const showActionSubscriberView = (action) => ({type: STORAGE_MNG_TOGGLE_ACTION_SUBSCRIBE_POPUP, data: action});
export const closeActionSubscriberView = () => ({type: STORAGE_MNG_TOGGLE_ACTION_SUBSCRIBE_POPUP, data: null});

export const getActiveSubscriberList = (actionID) => (dispatch) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/action_subscribers`,
    data: {
      actionID : actionID
    }
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_ACTIONS_SUBSCRIBERS_SHOW,
      actionsSubscriberMap : res
    });
  })
  .fail(error => {
    dispatch(showError(`We had trouble getting the list of active subscribers.  ${getErrorMessage(error)}`));
  });
};

export const addSubscribers = (actionID, userID) => dispatch => {
  dispatch({type: STORAGE_MNG_SET_LOADING, name: 'addSubsciber', loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/add_action_subscriber`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionID: actionID,
      userID: userID
    })
  })
  .done(() => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'addSubsciber', loading: false});
    dispatch(showSuccess(`Subscriber emails successfully added for action # ${actionID}`));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: 'addSubsciber', loading: false});
    dispatch(showError(`Could not add subscribers for action # ${actionID}.  ${getErrorMessage(error)}`));
  });
};

export const checkSpecSymbolsOrGetRenameData = (actionType, contentItems, woMemfis) => dispatch => {
  const loadingName = 'checkSpecSymbolsOrGetRenameData';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/rename_data`,
    data: {
      root_id: contentItems[0].fsRootID,
      action_type: actionType,
      content_ids: `[${contentItems.map(({contentID}) => contentID).join(',')}]`,
      wo_memfis: woMemfis,
    }
  })
  .always(res => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false, encDetailExists: res.enc_detail_exists});
  })
  .fail(error => {
    dispatch(showError(`We had trouble checking special symbols (getting rename data).  ${getErrorMessage(error)}`));
  });
};

export const renameSpecSymbols = (actionType, contentItems, successCallback) => (dispatch, getState) => {
  const {displayCategory, currentFolder} = getState().storageManager;
  const loadingName = 'renameSpecSymbols';
  dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__RENAME_SPEC_SYMBOLS_PORTAL,
      fsRootID: contentItems[0].fsRootID,
      parameters: {
        ContentIDs: contentItems.map(({contentID}) => String(contentID)),
        FS_ACTION_TYPE: actionType
      },
    })
  })
  .done(({ActionID}) => {
    setTimeout(function request() {
      makeActionAjax(ActionID).done((response) => {
        const {actionStatus} = response.action;
        if (actionStatus === 'DONE') {
          dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
          if (displayCategory === DISPLAY_CATEGORY_FOLDER) {
            dispatch(changeCurrentFolder(null, currentFolder, null, contentItems.map(({contentID}) => contentID), successCallback));
          } else if (displayCategory === DISPLAY_CATEGORY_SEARCH_RESULTS) {
            if (contentItems.length === 1) {
              dispatch(refreshSelectedContentWithoutChildren(contentItems[0], successCallback));
            } else {
              const items = JSON.parse(JSON.stringify(contentItems));
              const itemsNum = items.length;
              let i = 0;
              const cb = () => {
                if (i < (itemsNum - 1)) {
                  dispatch(refreshSelectedContentWithoutChildren(contentItems[++i], cb));
                } else if (successCallback) {
                  successCallback();
                }
              };
              dispatch(refreshSelectedContentWithoutChildren(contentItems[i], cb));
            }
          } else if (successCallback) {
            successCallback();
          }
        } else if(['FAILED', 'EXPIRED', 'REJECTED', 'INCOMPLETE', 'DISABLED_BY_FAILURE'].includes(actionStatus)) {
          dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
          dispatch(showError(`We had trouble renaming special symbols.  Action status: ${actionStatus}`));
        } else {
          setTimeout(request, 3000);
        }
      })
      .fail(error => {
        dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
        dispatch(showError(`We had trouble renaming special symbols.  ${getErrorMessage(error)}`));
      });
    }, 3000);
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`We had trouble renaming special symbols.  ${getErrorMessage(error)}`));
  });
};

export const setDeliveryRenameAvailableForAddToWO = (woMemfis, value) => ({
  type: STORAGE_MNG_ADD_FILE_TO_WO_SET_DELIVERY_RENAME_AVAILABLE, woMemfis, value
});

export const checkDeliveryRenameForAddToWO = (woMemfis, contentItems) => dispatch => {
  return dispatch(checkSpecSymbolsOrGetRenameData(FS_ACTION_TYPE__ADD_TO_MEMFIS_WO, contentItems, woMemfis))
    .then(
      res => {
        const {delivery_rename_available} = res;
        dispatch(setDeliveryRenameAvailableForAddToWO(woMemfis, delivery_rename_available));
      },
        () => {
        dispatch(setDeliveryRenameAvailableForAddToWO(woMemfis, null));
      },
    );
};

export const openDeliveryRenamePopup = (actionType, contentItems, woMemfis) => ({
  type: STORAGE_MNG_TOGGLE_DELIVERY_RENAME_POPUP, isShow: true, actionType, contentItems, woMemfis
});

export const closeDeliveryRenamePopup = () => dispatch => {
  dispatch({type: STORAGE_MNG_TOGGLE_DELIVERY_RENAME_POPUP, isShow: false});
  dispatch(clearAllDeliveryMng());
};

export const changeDirectoryInput = value => dispatch => {
  dispatch({type: DIRECTORY_TREE_CHANGE_SEARCH_INPUT, value});
};

export const setSchedulerPauseTime = (actionID, scheduledPauseTime, scheduledFor, isDailyRepeat, origActionStatus, origScheduledPauseTime, origScheduledFor, origIsDailyRepeat, type) => (dispatch) => {
  const url = `${config.apiUrl}/v1/file_system/scheduler_action`;

  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: url,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      action_id: actionID,
      scheduled_pause_time: scheduledPauseTime,
      scheduled_for: scheduledFor,
      is_daily_repeat: isDailyRepeat,
      orig_action_status: origActionStatus,
      orig_scheduled_pause_time: origScheduledPauseTime,
      orig_scheduled_for: origScheduledFor,
      orig_is_daily_repeat: origIsDailyRepeat,
      type: type
    })
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    if (res) {
      dispatch(showSuccessPopup({
        actionId: actionID,
        actionType: 'Scheduled Pause Time'
      }));
    }
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    if (error.responseJSON.errors && error.responseJSON.errors[0].code === 106) {
      dispatch(showError('Data is outdated and will be updated'));
    } else {
      dispatch(showError(`Scheduled pause not changed`));
    }
  });
};

export const applyChangeDirectory = (path, inputValue) => (dispatch, getState) => {
  const {fsRoots} = getState().storageManager;
  dispatch({type: DIRECTORY_TREE_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/nav`,
    data: {
      path: path,
    }
  })
      .always(() => {
        dispatch({type: DIRECTORY_TREE_IS_LOADING, loading: false});
      })
      .done(res => {
        const isValid = ajv.validate(fileSystemResponseSchema, res);
        if (isValid) {
          const {content} = res.data;
          const preparedContent = content.contentID > 0 ? prepareFile(content, fsRoots) : prepareRoot(content);
          return $.ajax({
            method: 'GET',
            url: `${config.apiUrl}/v1/file_system/nav`,
            data: {
              path: preparedContent.key
            }
          })
          .done(response => {
            const {contentID, predictiveStateID} = preparedContent;
            const {children, symbolinks} = (response.data || {});
            const parents = [...preparedContent.parents || [], contentID];
            const parentsPredictiveStates = [...preparedContent.parentsPredictiveStates || [], predictiveStateID || 0];
            const allParents = [...preparedContent.allParents || [], {contentID, predictiveStateID}];
            dispatch({
              type: DIRECTORY_TREE_FETCH_FOLDER,
              folder: preparedContent,
              children: [
                ...(children || []).map((i) => {i.isSymlink = false; return i;}),
                ...(symbolinks || []).map((i) => {i.isSymlink = true; return i;})
              ].map((i) => prepareFile(i, fsRoots)).map(item => ({...item, parents, parentsPredictiveStates, allParents})),
              allParents,
          });
          })
        } else {
          dispatch({type: DIRECTORY_TREE_IS_LOADING, loading: false});
          dispatch(showError('We had trouble getting content data: Invalid response'));
        }
      })
      .fail(() => {
        const inputValueArr = inputValue.split('\\');
        dispatch({type: DIRECTORY_TREE_IS_LOADING, loading: false});
        if(inputValueArr[0] === path){
          dispatch(showError(`Could not find the path "${preparePathForOsWithoutRoot(inputValue, fsRoots)}".`));
        }else{
          let pathArr = path.split('\\');
          let correctPath = pathArr.slice(0, -1).join('\\');
          let incorrectPath = inputValue.replace(correctPath, '').replace('\\', '');
          dispatch(showError(`Could not find the folder "${incorrectPath}" under the path "${preparePathForOsWithoutRoot(correctPath, fsRoots)}".`));
        }
      });
};

export const setUseFinalDestinationDataReserved = () => dispatch => {
  dispatch({type: USE_STORAGE_MNG_FINAL_DESTINATION_RESERVED});
}

export const setAddToWoIngestOperationTypes = ingestData => dispatch => {
  dispatch({type: STORAGE_MNG_ADD_TO_WO_SAVING, data: ingestData});
};

export const changeIngestFiles = row => dispatch => {
  dispatch({type: STORAGE_MNG_ADD_TO_WO_INGEST_FILES, row});
};

export const toggleAttachToWoModal = isShow => ({type: STORAGE_MNG_TOGGLE_ATTACH_TO_WO_MODAL, isShow});

export const getAttachmentFilters = woMemfis => dispatch => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/qod/attachment/filters`,
    data: {
      wo_memfis: woMemfis
    }
  })
  .always(() => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch(showError(`Could not load filters for attachments.  ${getErrorMessage(error)}`));
  });
};

export const creteAttachToWoAction = (contentItem, woMemfis, label, category, pseTestStatus) => dispatch => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  const attachmentParams = {
    label,
    category,
    pse_test_status: pseTestStatus
  };
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__ATTACH_FILE_QOD,
      fsRootID: contentItem.fsRootID,
      parameters: {
        ContentIDs: [String(contentItem.contentID)],
        WO_MEMFIS: woMemfis,
        ACTION_DATA_JSON: JSON.stringify(attachmentParams)
      }
    })
  })
  .done(res => {
    dispatch(toggleAttachToWoModal(false));
    dispatch(showSuccessPopup({
      actionId: (res || {}).ActionID,
      actionType: 'Attach to WO'
    }));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Could not attach to WO.  ${getErrorMessage(error)}`));
  });
};

export const loadDisneyFiles  = (contentId, rootId, wo_memfis) => dispatch => {
  dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: true});
  const data = {
      content_id: contentId,
      root_id: rootId,
      wo_memfis: wo_memfis
    };
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/get_qod_file`,
    data: data
  })
  .done(res => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch({type: STORAGE_MNG_ADD_FILE_TO_WO_SET_DISNEY_INFO, data: res});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_MODAL_IS_LOADING, loading: false});
    dispatch(showError(`Unable to get information for QOD Copy action.  ${getErrorMessage(error)}`));
  });
}

export const showCaptionsModal = isShow => ({
  type: STORAGE_MNG_CAPTIONS_SHOW_MODAL,
  isShow
});

export const convertCaptions = (isSendToQOD, newFileName) => (dispatch, getState) => {
  const {checkedItems} = getState().storageManager;
  const {fsRootID, key, contentID} = checkedItems[0];
  const fileName = parseName(key);
  const ext = parseFileExtension(fileName);

  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__CAPTIONS,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        DESTINATION: newFileName,
        FILE_NAME: fileName,
        SOURCE: key,
        INPUT_TYPE: ext.toUpperCase(),
        OUTPUT_TYPE: 'VTT',
        SEND_TO_QOD: isSendToQOD
      }
    })
  })
  .done((res) => {
    dispatch(showCaptionsModal(false));
    const {ActionID} = res || {};
    dispatch(showSuccessPopup({
      actionId: ActionID,
      actionType: 'Convert Captions'
    }));

  })
  .fail(error => {
    dispatch(showError(`Could not convert captions.  ${getErrorMessage(error)}`));
  });
};

export const checkPseFileType = () => (dispatch, getState) => {
  dispatch({type: STORAGE_MNG_PSE_IS_LOADING, loading: true});
  const {checkedItems} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/check_pse_file_type`,
    data: {
      content_id: contentID,
      fs_root_id: fsRootID
    }
  })
  .done(res => {
    const { is_hdr, wo_memfis } = res;
    dispatch({ type: STORAGE_MNG_PSE_HDR_FILE_TYPE, data: { is_hdr, wo_memfis } });
    dispatch({type: STORAGE_MNG_PSE_IS_LOADING, loading: false});
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_PSE_IS_LOADING, loading: false});
    dispatch(showError(`Could not get pse file type.  ${getErrorMessage(error)}`));
  });
}

export const executeMetafierAction = (selectedMetafierType, file) => (dispatch) => {
  const {fsRootID, contentID} = file;
  const name = 'executeMetafierAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  let fileName = parseName(file.key);
  const ext = parseFileExtension(fileName);
  if (selectedMetafierType === "EXTRACT_METADATA") {
    fileName = fileName.replace('.' + ext, '.xml');
  } else {
    fileName = fileName.replace('.' + ext, '_H0.' + ext);
  }

  let parameters = {
    ContentIDs: [String(contentID)],
    SOURCEL: file.contentID,
    DESTINATION: file.fullPath,
    DESTINATION_SUB_PATH: 'XML',
    DEST_CONTENT_ID: file.parentID,
    FILE_NAME: fileName,
    OPERATION: selectedMetafierType,
    WO_MEMFIS: file.memfisWoID
  };

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__METAFIER_DOLBY_CREATE_XML,
      fsRootID,
      parameters: parameters
    })
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showSuccessPopup({
      actionId: (res || {}).ActionID,
      actionType: 'Metafier'
    }));
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending Metafier action request.  ${getErrorMessage(error)}`));
  });
}

export const executeEdgePassAction = (woMemfis, isSendToQOD) => (dispatch, getState) => {
  const {checkedItems, destinationPath} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];
  const name = 'executeEdgePassAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  //let parameters = {
  //  ContentIDs: [String(contentID)],
  //  SOURCEL: file.contentID,
  //  DESTINATION: file.fullPath,
  //  DESTINATION_SUB_PATH: 'XML',
  //  DEST_CONTENT_ID: file.parentID,
  //  FILE_NAME: fileName,
  //  WO_MEMFIS: file.memfisWoID
  //};

  let parameters = {
    SOURCE_CONTENT_ID: contentID,
    WO_MEMFIS: woMemfis,
    SEND_TO_QOD: isSendToQOD,
    DESTINATION: destinationPath ? destinationPath : null
  };
  //if (companionFile > 0) {
  //  parameters['PSE_COMPANION_FILE'] = companionFile;
  //}

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__EDGE_PASS_PORTAL,
      fsRootID,
      parameters: parameters
    })
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    //dispatch(showSuccess('Edge Pass action has been successfully sent.'); //('PSE Analysis action has been successfully sent.'));
    dispatch(showSuccessPopup({
      actionId: (res || {}).ActionID,
      actionType: 'Edge Pass'
    }));
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending Edge Pass action request.  ${getErrorMessage(error)}`));
  });
}

export const executeScreenToQodAction = (woMemfis, qodIssueID, issueTimeCodeStart, issueTimeCodeEnd, allowReplaceImage) => (dispatch, getState) => {
  const {checkedItems, destinationPath} = getState().storageManager;
  const {fsRootID, contentID} = checkedItems[0];
  const name = 'executeScreenToQodAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });

  let parameters = {
    ContentIDs: [String(contentID)],
    SOURCE_CONTENT_ID: contentID,
    WO_MEMFIS: woMemfis,
    QOD_ISSUE_ID: qodIssueID,
    QOD_ISSUE_TIMECODE_START: issueTimeCodeStart,
    QOD_ISSUE_TIMECODE_END: issueTimeCodeEnd,
    IS_REPLACEMENT: allowReplaceImage ? true : false,
    SEND_TO_QOD: true,
    DESTINATION: destinationPath ? destinationPath : null
  };

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__SCREEN_TO_QOD_PORTAL,
      fsRootID,
      parameters: parameters
    })
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    //dispatch(showSuccess('Screen To Qod action has been successfully sent.'); //('PSE Analysis action has been successfully sent.'));
    dispatch(showSuccessPopup({
      actionId: (res || {}).ActionID,
      actionType: 'Create Screenshot'
    }));
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending Screen To Qod action request.  ${getErrorMessage(error)}`));
  });
}

export const getFileFrameRate = (fsRootID, contentID, woMemfis, dispatch) => {
  let name = 'getFrameRateValue';
  dispatch({type: STORAGE_MNG_SET_LOADING, name, loading: true});

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/get_frame_rate`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID,
      wo_memfis: woMemfis
    }
  })
  .done(res => {
    dispatch({ type: STORAGE_MNG_SET_LOADING, name, loading: false });
    dispatch({ type: STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE, data: res });
  })
  .fail(error => {
    dispatch(showError(`Error when request Frame Rate.  ${getErrorMessage(error)}`));
    dispatch({ type: STORAGE_MNG_SET_LOADING, name, loading: false });
  });
}

export const setFrameRate = (newFrameRate, isUserFrameRate, woMemfis) => (dispatch) => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/set_frame_rate`,
    data: {
      "frame_rate": newFrameRate,
      "is_user": isUserFrameRate,
      "wo_memfis": woMemfis
    }
  }).done((res) => {
    // done
  }).fail((error) => {
    dispatch(showError(`Could not set Frame Rate.  ${getErrorMessage(error)}`));
  });
};

export const showWoStepsModal = isShow => ({
  type: STORAGE_MNG_WO_STEPS_SHOW_MODAL,
  isShow
});

export const showWoStepsAddToWoModal = isShow => ({
  type: STORAGE_MNG_WO_STEPS_ADD_TO_WO_SHOW_MODAL,
  isShow
});

export const setWoStepsAddToWoNext = isNext => ({
  type: STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_NEXT,
  isNext
});

export const setWoStepsAddToWoBack = isBack => ({
  type: STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_BACK,
  isBack
});

export const setWoStepsEmpty = isEmpty => ({
  type: STORAGE_MNG_WO_STEPS_EMPTY,
  isEmpty
});

export const activateWoStep = (stepId, checked) => ({
  type: STORAGE_MNG_ACTIVATE_WO_STEP, stepId, checked
});

export const sendWoSteps = (assetId, steps, memfisWoID, actionID)  => (dispatch) => {
    dispatch({type: STORAGE_MNG_WO_STEPS_IS_SAVING, loading: true});
    return $.ajax({
      method: 'POST',
      url: `${config.apiUrl}/v1/file_system/set_wo_steps`,
      data: {
        wo_memfis_id: memfisWoID,
        steps: steps,
        assetId: assetId,
        actionID: actionID
      }
    })
    .done((res) => {
      dispatch({type: STORAGE_MNG_WO_STEPS_IS_SAVING, loading: false});
      dispatch(clearWoStepsData());
    })
    .fail(error => {
      dispatch({type: STORAGE_MNG_WO_STEPS_IS_SAVING, loading: false});
      dispatch(showError(`Could not store WO Steps to mark as complete -- none will be marked.  ${getErrorMessage(error)}`));
    });
  };

export const clearWoStepsData = () => ({ type: STORAGE_MNG_CLEAR_WO_STEPS });

export const toggleIssueScreenshotToQodModal = isShow => ({type: STORAGE_MNG_TOGGLE_ISSUE_SCREENSHOT_QOD_SHOW_MODAL, isShow});

export const getIssueListForWorkOrder = (woMemfis) => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/wo_issue_list`,
    data: {
      wo_memfis: woMemfis
    }
  });
}

export const getIssueFramesJson = (fsRootID, contentID, woMemfis, qodIssueID, issueTimeCodeStart, issueTimeCodeEnd) => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/wo_issue_screens`,
    data: {
      fs_root_id: fsRootID,
      content_id: contentID,
      wo_memfis: woMemfis,
      qod_issue_id: qodIssueID,
      issue_timecode_start: issueTimeCodeStart,
      issue_timecode_end: issueTimeCodeEnd
    }
  });
}

export const sendFirstScreenshotsToQOD = (fsRootID, contentID, woMemfis, issueList) => dispatch => {
  const name = 'executeScreenToQodAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/wo_issue_screens`,
    data: JSON.stringify({
      fs_root_id: fsRootID,
      content_id: contentID,
      wo_memfis: woMemfis,
      issue_list: issueList
    })
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showSuccessPopup({
      actionId: (res || {}).ActionID,
      actionType: 'Create Screenshot'
    }));
    dispatch(toggleIssueScreenshotToQodModal(false));
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending Screen To Qod action request.  ${getErrorMessage(error)}`));
  });
}

export const readIssueScreenshot = (fsRootID, contentID, fileName, successCallback) => (dispatch, getState) => {
  dispatch({type: STORAGE_MNG_READ_FILE_START});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__READ_FILE,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        IS_CACHE_FORCED: true
      }
    })
  })
  .done(res => {
    dispatch(checkReadFileStatus([fileName], res.ActionID, successCallback));
  })
  .fail(error => {
    dispatch({type: STORAGE_MNG_READ_FILE_STOP});
    dispatch(showError(`Could not begin reading file.  ${getErrorMessage(error)}`));
  });
};

export const sendCanvasToQod = (woMemfis, qodIssueID, issueScreenBase64) => (dispatch) => {
  const name = 'executeScreenToQodAction';
  dispatch({
    type: STORAGE_MNG_SET_LOADING,
    name,
    loading: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/wo_issue_screen`,
    data: {
      wo_memfis: woMemfis,
      qod_issue_id: qodIssueID,
      file_name: "issue_screen.png",
      screen_base64: issueScreenBase64
    }
  })
  .done(res => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showSuccessPopup({
      actionId: (res || {}).ActionID,
      actionType: 'Create Screenshot'
    }));
    dispatch(toggleIssueScreenshotToQodModal(false));
  })
  .fail(error => {
    dispatch({
      type: STORAGE_MNG_SET_LOADING,
      name,
      loading: false
    });
    dispatch(showError(`Error when sending Screen To Qod action request.  ${getErrorMessage(error)}`));
  });
}

export const buildIssueScreenshotLink = (fsRootID, contentID, woMemfis, qodIssueID, issueTimeCodeStart, issueTimeCodeEnd, frameNumber) => dispatch => {
  const imageUrl = `${config.apiUrl}/v1/file_system/wo_issue_screens?fs_root_id=${fsRootID}&content_id=${contentID}&wo_memfis=${woMemfis}&qod_issue_id=${qodIssueID}&issue_timecode_start=${issueTimeCodeStart}&issue_timecode_end=${issueTimeCodeEnd}&frame_number=${frameNumber}`;
  return new Promise((resolve, reject) => {
    resolve(imageUrl);
  });
}

export const getStatsForIssueScreenshots = (woMemfis, qodIssueID) => dispatch => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/wo_issue_screen_stat`,
    data: {
      wo_memfis: woMemfis,
      qod_issue_id: qodIssueID
    }
  });
}